import React, { useState, useEffect } from 'react';
import { GoAButton, GoAButtonGroup, GoACheckbox, GoADropdown, GoADropdownItem, GoAFormItem, GoAInputText, GoAModal, GoATextArea } from '@abgov/react-components';
import PageLoader from '../../components/PageLoader';
import PageHeader from '../../components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { CommonLookup } from '../../model/Common';
import { Part } from '../../model/Part';
import { PartService } from '../../services/PartService';
import { useRecoilValue } from 'recoil';
import { partsState } from '../../states/PartService.state';
import { CalloutVariant, ForObjectType } from '../../model/enums/Constants';
import { toNumber } from 'lodash';
import { getDataFromSessionStorage } from '../../utils/SessionStorageUtils';
import { loadCommonLookups } from '../../states/Common.state';
import { InputAdornment, TextField } from '@mui/material';

const CatalogItemDetail = () => {
  const [statusOptions, setStatusOptions] = useState<{ id: number; value: string }[]>();
  const [itemTypeOptions, setItemTypeOptions] = useState<{ id: number; value: string }[]>();
  const [useOptions, setUseOptions] = useState<{ id: number; value: string }[]>();
  const [unitOptions, setUnitOptions] = useState<{ id: number; value: string }[]>();

  const [loading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formChanged, setFormChanged] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [modalHeading] = React.useState("");
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);

  const { id } = useParams();
  const { catalogId, catalogName, catalogType } = useParams();
  const navigate = useNavigate();
  const parts = useRecoilValue<Part[]>(partsState);


  interface FormState {
    formPart: Part;
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = getDataFromSessionStorage(ForObjectType.PART);
        if (!response) {
          loadCommonLookups();
        }

        setStatusOptions(response.find((x: CommonLookup) => x.name === 'status')?.elements);
        setUseOptions(response.find((x: CommonLookup) => x.name === 'use')?.elements);
        setItemTypeOptions(response.find((x: CommonLookup) => x.name === 'ItemType')?.elements);
        setUnitOptions(response.find((x: CommonLookup) => x.name === 'unit')?.elements);

      } catch (error) {
        console.error("Error fetching catalog:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);
  const [formState, setFormState] = useState<FormState>({ formPart: new Part() });

  React.useEffect(() => {
    const fetchFormPart = async () => {
      setIsLoading(true);
      try {
        if (id === "new") {
          let newPart = new Part({
            ...formState.formPart,
            catalogId: catalogId
          });

          setFormState({
            formPart: newPart
          });
          return;
        } else if (parts) {
          const foundPart = parts.find(item => item.id === id);
          if (foundPart) {
            setFormState({ formPart: foundPart });
            return; // Exit the function early if the part is found in the local state
          }
        }

        if (id) {
          const result = await PartService.getById(id);
          setFormState({ formPart: result.data });
        }
      } catch (error) {
        console.error("Error fetching form part:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFormPart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, parts]);

  const createUpdateClick = async () => {

    if (!validateForm('save')) {
      console.log("Form contains errors. Cannot submit.");
      return;
    }
    setIsLoading(true);
    try {
      let result;

      if (formState.formPart?.id) {
        result = await PartService.update(formState.formPart!);
      } else {

        result = await PartService.create(formState.formPart!);
      }

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Part saved successfully!");
        setShowNotificationMessage(true);
        setFormChanged(false);
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result.error);
        setShowNotificationMessage(true);
      }
    } catch (error) {
      console.error("Error updating or creating catalog:", error);
      alert("An error occurred while updating or creating the catalog");
    } finally {
      setIsLoading(false);
    }
  }

  function setValue(value: Partial<Part>) {
    setFormState(prevState => {
      const updatedFormPart = new Part({
        ...prevState.formPart,
        ...value,
        price: { ...prevState.formPart.price, ...value }
      });

      console.log(updatedFormPart); // Log the updated formPart

      return { ...prevState, formPart: updatedFormPart };
    });
  }

  //Event handler for input change
  const handleFieldChange = (name: string, value: any) => {
    setValue({ [name]: value }); // Update formPart
    validateForm(name, value); // Trigger validation
    setFormChanged(true);
  };

  const handleAltNumberNadSupplierFieldChange = (index: number, fieldName: string, value: string) => {
    setFormChanged(true);
    setFormState(prevFormState => {
      const updatedAltPartNumber = [...prevFormState.formPart.altPartNumber!];
      updatedAltPartNumber[index] = {
        ...updatedAltPartNumber[index],
        [fieldName]: value
      };
      return {
        ...prevFormState,
        formPart: {
          ...prevFormState.formPart,
          altPartNumber: updatedAltPartNumber
        }
      };
    });
    validateForm(fieldName, value);
  };


  function addAlternateNumberAndSupplier(): void {
    const updatedFormPart = {
      ...formState,
      formPart: {
        ...formState.formPart!,
        altPartNumber: [...(formState.formPart?.altPartNumber ?? []), { partNumber: "", supplier: "" }]
      }
    };

    setFormState(updatedFormPart);
    setFormChanged(true);
  }

  function deleteItem(index: number) {
    setFormChanged(true);
    // Check if altPartNumber is null
    if (!formState.formPart?.altPartNumber) return;

    // Create a copy of the current altPartNumber array
    const updatedAltPartNumber = [...formState.formPart.altPartNumber];

    // Remove the item at the specified index
    updatedAltPartNumber.splice(index, 1);

    // Update the state with the modified altPartNumber array
    setFormState({
      ...formState,
      formPart: {
        ...formState.formPart,
        altPartNumber: updatedAltPartNumber
      }
    });
  }

  function validateForm(name: string, value: string | string[] = '') {
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if ((name === 'save' && !formState.formPart?.name) || (name === "name" && !value)) {
      newErrors['name'] = 'Name is required';
    }

    if ((name === 'save' && formState.formPart?.name && formState.formPart?.name.length < 2) || (name === "name" && value.length < 2)) {
      newErrors['name'] = 'The length of Name must be at least 2 characters';
    }

    if ((name === 'save' && !formState.formPart?.code) || (name === "code" && !value)) {
      newErrors['code'] = 'Main part number is required';
    }

    if ((name === 'save' && formState.formPart?.code && formState.formPart?.code.length < 3) || (name === "code" && value.length < 3)) {
      newErrors['code'] = 'The length of Code must be at least 3 characters';
    }

    if ((name === 'save' && !formState.formPart?.supplier) || (name === "supplier" && !value)) {
      newErrors['supplier'] = 'Main Supplier is required';
    }

    if ((name === 'save' && !formState.formPart?.objectType) || (name === "objectType" && !value)) {
      newErrors['objectType'] = 'Item type is required';
    }

    if ((name === 'save' && !formState.formPart?.status) || (name === "status" && !value)) {
      newErrors['status'] = 'Status is required';
    }

    if ((name === 'save' && !formState.formPart?.unit) || (name === "unit" && !value)) {
      newErrors['unit'] = 'Unit of measure is required';
    }

    if (
      (name === 'save' && (!formState.formPart?.price.amount || formState.formPart?.price.amount <= 0)) ||
      (name === 'amount' && (!value || toNumber(value) <= 0))
    ) {
      newErrors['amount'] = value ? 'Cost must be a positive value' : 'Cost is required';
    }

    if ((name === 'save' && !formState.formPart?.forEquipment) || (name === "Use" && !value)) {
      newErrors['Use'] = 'Use is required';
    }

    if ((name === 'save')) {
      // Check for errors in alternative part numbers and suppliers
      formState?.formPart?.altPartNumber?.forEach((item: any, index: number) => {
        if (!item.partNumber) {
          newErrors[`partNumber-${index}`] = `Alternative part number is required`;
        }
        if (!item.supplier) {
          newErrors[`supplier-${index}`] = `Alternative supplier is required`;
        }
      });
    }
    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  const handleFocus = (event: any) => {
    event.target.select();
  };
 
  return (
    <>
      <PageLoader visible={loading} />

      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => navigate(`/catalogueList`)}>Catalogues</GoAButton>
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => navigate(`/catalogItemsList/${catalogName}/${catalogType}/${catalogId}`)}>Items</GoAButton>
            <GoAButton type="tertiary" onClick={() => { }}><div className='view-nav'>{id === 'new' ? 'Create Item' : 'Update Item'}</div></GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title={id === 'new' ? 'Create item' : 'Update item'} />
          </div>
        </div>

        {catalogName ?
          <div className="left width100 input-form-item">
            <div className="left width-auto form-row ">
              <label className='form-label'>Catalogue:</label>
              <GoAButton size="compact" trailingIcon="open" type="tertiary" onClick={() => navigate(`/catalogDetail/${catalogId}`)}>
                {catalogName}
              </GoAButton>
            </div>
          </div> : ''}

        <div className='form-detail-container'>
          <div className='input-form-item'>
            <GoAFormItem label='Name' error={errors['name']}>
              <GoAInputText
                name="name"
                value={formState?.formPart?.name || ''}
                width="100%"
                maxLength={255}
                onChange={handleFieldChange} // Use handleFieldChange as event handler
              />
            </GoAFormItem>
          </div>


          <div className="form-row input-form-item">
            <div className="col-50 item-space">
              <GoAFormItem label='Main part number' error={errors['code']}>
                <GoAInputText
                  name="code"
                  value={formState?.formPart?.code || ''}
                  width="100%"
                  maxLength={50}
                  onChange={
                    handleFieldChange}
                />
              </GoAFormItem>
            </div>

            <div className="col-50">
              <GoAFormItem label="Main supplier" error={errors['supplier']}>
                <GoAInputText
                  name="supplier"
                  value={formState?.formPart?.supplier || ''}
                  width="100%"
                  maxLength={100}
                  onChange={
                    handleFieldChange}
                />
              </GoAFormItem>
            </div>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label="Description" id="description" requirement="optional">
              <GoATextArea
                value={formState?.formPart?.description || ''}
                name="description"
                rows={3}
                width="100%"
                countBy="character"
                maxCount={4000}
                onChange={handleFieldChange}
              />
            </GoAFormItem>
          </div>
          {!formState.formPart?.id && (
            <div>
              <div className='input-form-item'>
                <GoAFormItem label='Item type' error={errors['objectType']}>
                  <GoADropdown name="objectType" width="100%" placeholder='Select' filterable={true}
                    value={formState?.formPart?.objectType?.toString() || "Consumable"}
                    onChange={handleFieldChange}
                  >
                    {itemTypeOptions?.map((item: any) => (
                      <GoADropdownItem label={item.value} value={item.id} key={item.id}></GoADropdownItem>
                    ))}
                  </GoADropdown>
                </GoAFormItem>
              </div>
            </div>)}

          <div className='input-form-item'>
            <GoAFormItem label='Status' error={errors['status']}>
              <GoADropdown name="status" width="100%" placeholder='Select' filterable={true}
                value={formState?.formPart?.status?.toString()}
                onChange={handleFieldChange} // Use handleFieldChange as event handler
              >
                {statusOptions?.map((item: any) => (
                  <GoADropdownItem label={item.value} value={item.id} key={item.id}></GoADropdownItem>
                ))}
              </GoADropdown>
            </GoAFormItem>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label='Unit of measure' error={errors['unit']}>
              <GoADropdown name="unit" width="100%" placeholder='Select' filterable={true}
                value={formState?.formPart?.unit?.toString()}
                onChange={handleFieldChange} // Use handleFieldChange as event handler
              >
                {unitOptions?.map((item: any) => (
                  <GoADropdownItem label={item.value} value={item.id} key={item.id}></GoADropdownItem>
                ))}
              </GoADropdown>
            </GoAFormItem>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label='Cost' error={errors['amount']}>
              <div className="input-container">
                <TextField
                  type="number"
                  onChange={(e) => {
                    handleFieldChange(e.target.name, e.target.value)}
                  }
                  value={formState?.formPart?.price?.amount}
                  name="amount"
                  onFocus={event => handleFocus(event)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                    inputProps: {
                      step: 0.01
                    },
                    sx: {height: '40px'}
                  }}
                  className="amount-input catalog-item"
                  
                />
              </div>
            </GoAFormItem>
          </div>
          <div className='input-form-item'>
            <GoACheckbox name="allowAutoCost" text="Allow auto cost calculation" onChange={(name, checked, value) => {
              handleFieldChange(name, checked);
            }} checked={formState?.formPart?.price?.allowAutoCost || false}></GoACheckbox>
          </div>
          <div className='input-form-item'>
            <GoAButton type="secondary" onClick={addAlternateNumberAndSupplier}>
              Add alternate number & supplier
            </GoAButton>
          </div>

          {formState?.formPart?.altPartNumber?.map((item: any, index: number) => (
            <div className="form-row input-form-item ">
              <div className='alt-number-supp'>
                <div className="col-50 item-space">
                  <GoAFormItem label='Alternative part number' error={errors[`partNumber-${index}`]}>
                    <GoAInputText
                      name={`partNumber`}
                      value={item.partNumber}
                      width="100%"
                      maxLength={50}
                      onChange={
                        (name, value,) => handleAltNumberNadSupplierFieldChange(index, name, value)}
                    />
                  </GoAFormItem>
                </div>

                <div className="col-50">
                  <GoAFormItem label="Alternative supplier" error={errors[`supplier-${index}`]}>
                    <GoAInputText
                      name={`supplier`}
                      value={item.supplier}
                      width="100%"
                      maxLength={50}
                      onChange={
                        (name, value,) => handleAltNumberNadSupplierFieldChange(index, name, value)}
                    />
                  </GoAFormItem>
                </div>
              </div>
              <div className='delete-link'><GoAButton type="tertiary" onClick={() => deleteItem(index)} >
                Delete
              </GoAButton></div>
            </div>
          ))}
          <div className='input-form-item'>
            <GoAFormItem label='Use' error={errors['Use']}>
              <GoADropdown name="forEquipment" width="100%" placeholder='Select' filterable={true}
                value={formState?.formPart?.forEquipment}
                onChange={handleFieldChange} // Use handleFieldChange as event handler
              >
                {useOptions?.map((item: any) => (
                  <GoADropdownItem label={item.value} value={item.value} key={item.id}></GoADropdownItem>
                ))}
              </GoADropdown>
            </GoAFormItem>
          </div>
          <div className='input-form-item'>
            <GoACheckbox name="provStocked" text="Provincially stocked" onChange={(name, checked) => {
              handleFieldChange(name, checked);
            }} checked={formState?.formPart?.provStocked || false}></GoACheckbox>
          </div>
          <div className='input-form-item right'>
            <GoAButton type="secondary" onClick={() => navigate(`/catalogItemsList/${catalogName}/${catalogType}/${catalogId}`)} >
              Cancel
            </GoAButton>
            <div className='button-spacing'></div> {/* Add space between buttons */}
            <GoAButton type="primary" onClick={createUpdateClick} disabled={Object.keys(errors).length > 0 || !formChanged}>
              {id === 'new' ? 'Create' : 'Update'}
            </GoAButton>
          </div>
        </div>
      </div>

      <GoAModal
        heading={modalHeading}
        calloutVariant={calloutVariant}
        open={showNotificationMessage}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton onClick={() => {
              setShowNotificationMessage(false);
              if (calloutVariant === CalloutVariant.SUCCESS) {
                navigate(`/catalogItemsList/${catalogName}/${catalogType}/${catalogId}/`);
              }
            }}>

              Okay
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: notificationMessage.replace(/\n/g, '<br />') }} />
      </GoAModal>


      {/* 
        <GoAModal
          heading="You have unsaved changes"
          open={showModal}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => onCancel()}>Cancel</GoAButton>
              <GoAButton onClick={() => onDiscardChanges()}>
                Discard changes
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <p>Are you sure you want to discard changes?</p>
        </GoAModal> */}
    </>
  );
};

export default CatalogItemDetail;
