
import React, { useState } from 'react';
import { GoAButton, GoAButtonGroup, GoAFormItem, GoAInputText, GoAModal, GoATable, GoATextArea } from '@abgov/react-components';
import PageLoader from '../../components/PageLoader';
import PageHeader from '../../components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';

import { CommonLookup } from '../../model/Common';
import { CalloutVariant, ForObjectType} from '../../model/enums/Constants';
import { Adjustment, AdjustmentItem } from '../../model/Adjustments';
import { InventoryService } from '../../services/InventoryService';
import { UserComment } from '../../model/Base';
import CatalogItemDetailView from '../catalog/CatalogItemView';
import { getDataFromSessionStorage } from '../../utils/SessionStorageUtils';
import { loadCommonLookups } from '../../states/Common.state';
import { hasResourceRole } from '../../utils/tokens';
import { permissions } from '../../common/constants';
import { useAuth } from 'react-oidc-context';

const AdjustmentDetail = () => {
  const [loading, setIsLoading] = useState(false);
  const [errors] = useState<Record<string, string>>({}); // Define errors as Record<string, string>
  const [adjustmentReasonOptions, setAdjustmentReasonOptions] = useState<any>([]);
  const [ajustmentStatusOptions, setAdjustmentStatusOptions] = useState<any>([]);
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [formChanged, setFormChanged] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null); // State to track expanded row
  const { id } = useParams();
  const { adjustmentNo } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const responseAdj = getDataFromSessionStorage(ForObjectType.ADJUSTMENTS);
        const responseInv = getDataFromSessionStorage(ForObjectType.INVENTORY_ITEM);
        if (!responseAdj||!responseInv) {
          loadCommonLookups();
        }
         
        const adjustmentReason = responseAdj.find((x: CommonLookup) => x.name === 'AdjustmentReason');
        const adjustmentStatusOptions = responseInv.find((x: CommonLookup) => x.name === 'status');

        if (adjustmentReason) {
          setAdjustmentReasonOptions(adjustmentReason.elements);
        }

        if (adjustmentStatusOptions) {
          setAdjustmentStatusOptions(adjustmentStatusOptions.elements);
        }

      } catch (error) {
        console.error("Error fetching AdjustmentReason lookup:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const [formState, setFormState] = useState<Adjustment>();

  React.useEffect(() => {
    const fetchFormData = async () => {
      if (!id) return;
      setIsLoading(true);
      try {
        const response = await InventoryService.getAdjustmentById(id);
        if (response) {
          setFormState(response.data);
        }
      } catch (error) {
        console.error("Error fetching form part:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFormData();
  }, [id]);

  const canEditComment = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_ADJUSTMENT_WRITE,
    auth.user?.access_token ?? ""
  );

  function handleCommentChange(name: string, value: string | string[]): void {
    var updatedComments;
    setFormChanged(true);
    if (formState) {
      const commentText = Array.isArray(value) ? value.join('') : value;
      const newComment = new UserComment(undefined, commentText, "SA", (new Date()).toISOString());
      const textArray: string[] = newComment.text.split("\n");
      const newCommentsArray: UserComment[] = textArray
        .filter(text => text.trim().length > 0)
        .map((text: string) => ({
          id: undefined,
          postDate: newComment.postDate,
          text: text,
          username: newComment.username
        }));
      if (name === "memo")
        updatedComments = [...newCommentsArray, ...(formState.comments || [])];
      else
        updatedComments = [...newCommentsArray];

      setFormState({ ...formState, comments: updatedComments });
    }
  }

  const handleCancel = () => {
    if (formChanged)
      setShowCancelModal(true);
    else
      navigate(`/adjustmentList`)
  };

  const createUpdateClick = async () => {
    setIsLoading(true);
    try {
      let result;
      if(!formState?.id) {
        result = await InventoryService.create(formState!);
      }
      else{
        result = await InventoryService.update(formState!);
      }

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Adjustment saved successfully!");
        setShowNotificationMessage(true);
        setFormChanged(false);
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result.error);
        setShowNotificationMessage(true);
      }
    } catch (error) {
      alert("An error occurred while creating the adjustment");
    } finally {
      setIsLoading(false);
    }
  }

  const toggleRowExpand = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => navigate(`/adjustmentList`)}>Consumable adjustments</GoAButton>
            <GoAButton type="tertiary" onClick={() => { }}><div className='view-nav'>{id === 'new' ? 'Create consumable adjustment' : 'View consumable adjustment'}</div></GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title={id === 'new' ? 'Create consumable adjustment' : 'View consumable adjustment'} />
          </div>
        </div>
        <div className="left width100 input-form-item">
          <div className="left width-auto form-row ">
            <label className='form-label'>{adjustmentNo}</label>
          </div>
        </div>

        <div className='form-detail-container'>
        <div className='input-form-item'>
            
              <GoAFormItem label='Location' error={errors['location']}>
                <GoAInputText
                  name="location"
                  disabled={true}
                  value={formState?.locationName || ''}
                  width="100%"
                  maxLength={50}
                  onChange={() => console.log('')}
                />
              </GoAFormItem>
          </div>

          <div className='input-form-item'>
            <GoAFormItem label="Fire #" error={errors['fireNo']}>
              <GoAInputText
                disabled={true}
                name="fireNo"
                value={formState?.fireNo || ''}
                width="100%"
                maxLength={100}
                onChange={() => console.log('')}
              />
            </GoAFormItem>
          </div>
         
          <div>
            <div className='input-form-item'>
              <GoAFormItem label='Reason for adjustments' error={errors['adjustmentReason']}>
                {adjustmentReasonOptions.find((r: any) => r.id === formState?.reason)?.value}
              </GoAFormItem>
            </div>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label="Comments" id="description" requirement="optional">
              <GoATextArea
                value={(formState?.comments ? formState.comments?.map(comment => comment.text).join('\n') : '') ?? ''}
                name="comment"
                disabled={!canEditComment}
                rows={3}
                width="100%"
                countBy="character"
                maxCount={4000}
                onKeyPress={handleCommentChange}
                onChange={handleCommentChange}
              />

            </GoAFormItem>
          </div>
        </div>
        <div className="left width100 input-form-item">
          <div className="left width-auto form-row ">
            <label className='form-label'>Items</label>
          </div>
        </div>
        <div className='input-form-item'>
          <GoATable width="100%">
            <thead>
              <tr>
                <th>IMIS ID</th>
                <th>Part#</th>
                <th>Item name</th>
                <th>Status</th>
                <th>Qty on hand</th>
                <th>Increase/decrease</th>
                <th>New qty</th>
                <th>Owner</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {formState?.items?.map((record: AdjustmentItem, index: any) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{record.imisId}</td>
                    <td>{record.code}</td>
                    <td>{record.name}</td>
                    <td>{ajustmentStatusOptions?.find((a: any) => a.id === record.status)?.value}</td>
                    <td>{record.qtyCurrent}</td>
                    <td>{record.qtyAdjustment}</td>
                    <td>{record.qtyAdjusted}</td>
                    <td>Alberta</td>
                    <td>
                      <GoAButton
                        size="compact"
                        type="tertiary"
                        disabled={!record.catalogItemId}
                        onClick={() => toggleRowExpand(index)}
                      >
                       <goa-icon type={(expandedRowIndex===index) ? "chevron-down" : "chevron-forward"}></goa-icon>

                      </GoAButton>

                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={8}><CatalogItemDetailView id={record.catalogItemId} editable={false} /></td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </GoATable>
        </div>
        <div className='form-buttons-container'>
          <div className='input-form-item right'>
            <GoAButton type="secondary" onClick={handleCancel} >
              Cancel
            </GoAButton>
            <div className='button-spacing'></div>
            <GoAButton type="primary" onClick={createUpdateClick} disabled={!formChanged}>
              Update
            </GoAButton>
          </div>
        </div>

        <GoAModal
          heading={""}
          calloutVariant={calloutVariant}
          open={showNotificationMessage}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => {
                setShowNotificationMessage(false);
                if (calloutVariant === CalloutVariant.SUCCESS) {
                  navigate(`/adjustmentList`);
                }
              }}>
                Okay
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
        </GoAModal>

        <GoAModal heading="" open={showCancelModal} maxWidth="30vw"
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowCancelModal(false); }}>No, continue editing</GoAButton>
              <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={() => { navigate(`/adjustmentList`) }}>Yes, I am sure</GoAButton>
            </GoAButtonGroup>
          }
        >
          <p>Are you sure you want to discard the changes?</p>
        </GoAModal>
      </div>
    </>
  );
};

export default AdjustmentDetail;
