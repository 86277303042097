import {
  GoAButton,
  GoAButtonGroup,
  GoAIcon,
  GoAModal,
  GoATab,
  GoATabs,
} from "@abgov/react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import WorkOrderMainTab from "./tabs/WorkOrderMainTab";
import WorkOrderPartsAndLabourTab from "./tabs/WorkOrderPartsAndLabourTab";
import { useRecoilState } from "recoil";
import { workOrderValidationState } from "../../states/WorkOrder.state";
import { VALIDATION_WORKORDER_TAB_MAIN, VALIDATION_WORKORDER_TAB_PARTSLABOURS } from "../../common/constants";

const CreateWorkOrder: React.FunctionComponent = () => {
  // Navigation
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] =
  React.useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const errors = useRecoilState<Record<string, string>>(workOrderValidationState);
 
  const findErrors = (array : any[], prefixes: any[]) => {
    return array.filter(obj  =>
      Object.keys(obj).some(key => prefixes.some(prefix => key.startsWith(prefix)))
    );
  };
  
  const mainTabHeading = <><label>Main</label>{findErrors(errors, [VALIDATION_WORKORDER_TAB_MAIN]).length > 0 && <GoAIcon type="warning" theme="outline"></GoAIcon>}</>;
  const partsAndLabourTabHeading = <><label>Parts & Labours</label>{findErrors(errors, [VALIDATION_WORKORDER_TAB_PARTSLABOURS]).length > 0 && <GoAIcon type="warning" theme="outline"></GoAIcon>}</>;

  return (
    <>
      <div className="workorder-content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => window.location.pathname = '/home'}
            >
              Home
            </GoAButton>
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => navigate("/workOrderList")}
            >
              Work orders
            </GoAButton>
            <GoAButton
              type="tertiary"
              onClick={() => navigate("/createWorkOrder")}
            >
              Create work order
            </GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto create-work-order-header">
            <PageHeader title={"Create work order"} />
          </div>
        </div>
      </div>
      <div className="workorder-tab-container">
        <GoATabs>
          <GoATab heading={mainTabHeading}>
            <WorkOrderMainTab formChanged={formChanged}  setFormChanged={setFormChanged} setShowCancelModal={setShowCancelModal} />
          </GoATab>
          <GoATab heading={partsAndLabourTabHeading}>
            <WorkOrderPartsAndLabourTab  formChanged={formChanged}  setFormChanged={setFormChanged} setShowCancelModal={setShowCancelModal} />
          </GoATab>
        </GoATabs>
      </div>
      <GoAModal heading="" open={showCancelModal} maxWidth="30vw"
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowCancelModal(false); }}>No</GoAButton>
            <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={() => {setShowCancelModal(false); navigate("/workOrderList");  }}>Yes</GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to cancel this workorder?</p>
      </GoAModal>
    </>
  );

};
export default CreateWorkOrder;
