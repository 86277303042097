// Callout variant enum
export enum CalloutVariant {
    INFORMATION = "information", // Provides information
    IMPORTANT = "important",     // Indicates important information
    EMERGENCY = "emergency",     // Indicates an emergency
    SUCCESS = "success",         // Indicates success
    EVENT = "event"              // Represents an event
}

// Lookup type enum

export enum LookupType {
  STATUS = "status",// Represents status lookup
  CATALOG_TYPE = "CatalogType",// Represents catalog type lookup
  UNITS = "unit",// Represents units lookup
  ITEM_TYPE = "ItemType",
  USE = "use",
  ADJUSTMENT_REASON = "AdjustmentReason",
  MEMO="memo",
  OWNER = "owner"
}


// ForObjectType  enum


export enum ForObjectType {
  CATALOG = "catalog",// Represents catalog lookup
  PART = "part",
  ADJUSTMENTS = "ConsumableAdjustment",
  INVENTORY_ITEM = "InventoryItem",
  WORKORDER = "workorder",
  ORDER = "order"
}
