import {
  GoAButton,
  GoAButtonGroup,
  GoACheckbox,
  GoAContainer,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAIconButton,
  GoAInput,
  GoAInputDate,
  GoAInputText,
  GoAModal,
} from "@abgov/react-components";
import React, { useCallback, useEffect, useState } from "react";
import { WorkOrder } from "../../../model/WorkOrder";
import { SelectOptions, UserComment } from "../../../model/Base";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { LocationService } from "../../../services/LocationService";

import { CalloutVariant, ForObjectType } from "../../../model/enums/Constants";
import { CommonLookup } from "../../../model/Common";
import moment from "moment";
import { WorkOrderService } from "../../../services/WorkOrderService";
import PageLoader from "../../../components/PageLoader";
import { useRecoilState } from "recoil";
import {
  labourItemsFilteredState,
  locationState,
  partsLocationSelectedState,
  partsLocationState,
  workOrderPdfState,
  workOrderState,
  workOrderValidationState,
} from "../../../states/WorkOrder.state";
//import { WildfireFinanceService } from "../../../services/WildfireFinanceService";
import { InputActionMeta } from "react-select";
import Select from "react-select";
import { IUnitItem } from "../../../model/Unit";
import { getDataFromSessionStorage } from "../../../utils/SessionStorageUtils";
import { loadCommonLookups } from "../../../states/Common.state";
import { getUserName, hasResourceRole } from "../../../utils/tokens";
import { useAuth } from "react-oidc-context";
import { permissions, VALIDATION_WORKORDER_TAB_MAIN, VALIDATION_WORKORDER_TAB_PARTSLABOURS, WORKORDER_STATUS_COMPLETED } from "../../../common/constants";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { ItemLocation } from "../../../model/Location";
import { WorkOrderPdf } from "../../../model/WorkOrderPdf";
import { formatPDFDate, toDateString } from "../../../common/date";
import { WorkOrderLabourItem } from "../../../model/WorkOrderLabour";
import { WorkOrderPartItem } from "../../../model/WorkOrderPart";
import { mapOTFactorDesc } from "../../../common/functions";

interface IWorkOrderMainTabProps {
  workOrderId?: string;
  formChanged:boolean;
  setFormChanged:React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelModal?:React.Dispatch<React.SetStateAction<boolean>>;
  isHomePage?:boolean;
}
interface IComment {
  index?: number;
  value: string;
}

const WorkOrderMainTab: React.FC<IWorkOrderMainTabProps> = (props) => {
  const { formChanged, setFormChanged,setShowCancelModal, isHomePage} = props;
  const workOrderId = props.workOrderId ?? "";

  const [locations, setLocations] = useRecoilState<SelectOptions[]>(locationState);
  const [formWorkOrder, setWorkOrder] =
    useRecoilState<WorkOrder>(workOrderState);
  const [workOrderPdfData, setWorkOrderPdfData] = useRecoilState<WorkOrderPdf>(workOrderPdfState);
  const [partsLocationOptions, setPartsLocationOptions] =
    useRecoilState<SelectOptions[]>(partsLocationState);
  const [selectedPartsLocation, setSelectedPartsLocation] =
    useRecoilState<SelectOptions>(partsLocationSelectedState);
  const [labourItemOptionsFiltered, setLabourItemOptionsFiltered] =
    useRecoilState<any[]>(labourItemsFilteredState);
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] =
    useState<{ id: number; value: string }[]>();
  // const [glAccountOptions, setGlAccountOptions] = useState<SelectOptions[]>([]);
  // const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
  //   []
  // );
  // const [fundOptions, setFundOptions] = useState<SelectOptions[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [enableUnitInfo, setEnableUnitInfo] = useState(false);
  const [comment, setComment] = useState<IComment | undefined>(undefined);
  //const minDate = moment().startOf("day").toISOString();
  const dateIn = formWorkOrder?.dateIn ?? moment().startOf("day").toISOString();
  const dateOut =
    formWorkOrder?.dateOut ?? moment().startOf("day").toISOString();
  const [unitNumbers, setUnitNumbers] = useState<SelectOptions[]>([]);
  // const [altRefNumbers, setAltRefNumbers] = useState<SelectOptions[]>([]);
  const [unitItems, setUnitItems] = useState<IUnitItem[]>([]);
  const [commentCharCount, setCommentCharCount] = useState<number>(
    Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT)
  );
  const [problemCharCount, setProblemCharCount] = useState<number>(
    Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT)
  );
  const [subletDescriptionCharCount, setSubletDescriptionCharCount] =
    useState<number>(1000);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(true);
  const [itemsOptions, setItems] = useState<ItemLocation[]>([]);
  const [itemsOptionsFiltered, setItemsFiltered] = useState<any[]>([]);


  const auth = useAuth();

  const people = [
    "MIKE KRUHLAK",
    "DAVID WILLIS",
    "RON PROKUDA",
    "DAVID MIDDLEBROUGH",
    "TODD TATE",
    "DANNY PEARN"
  ];
  const handleBeforeUnload = useCallback((event: any) => {
    if (formChanged) {
      event.preventDefault();
      event.returnValue = ''; // For modern browsers
      return ''; // For older browsers
    }
  }, [formChanged]);

  useEffect(() => {
    const beforeUnloadListener = (event: any) => {
      handleBeforeUnload(event);
    };

    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [handleBeforeUnload]);
  
  const canCreateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_CREATE,
    auth.user?.access_token ?? ""
  );
  const canChangeStatus = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_REACTIVATE,
    auth.user?.access_token ?? ""
  );
  const canUpdateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_UPDATE,
    auth.user?.access_token ?? ""
  );
  const canPartiallyUpdateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_UPDATE_MECHANIC,
    auth.user?.access_token ?? ""
  );

  const userName = getUserName(auth?.user?.access_token ?? "");

  const handleFieldChange = (name: string, value: any) => {
    setFormChanged(true);
    if (name === "status") {
      if (value === "8") {
        setWorkOrder({
          ...formWorkOrder,
          status: value,
          closedByName: userName
        });
      }
      else {
        setWorkOrder({
          ...formWorkOrder,
          status: value,
          closedByName: ""
        });
      }
    }
    else if (name === "comments") {
      var comment: UserComment = {
        id: formWorkOrder?.comments?.length ?? 0,
        text: value,
        username: userName,
        postDate: new Date().toISOString(),
      };
      const comments = [...(formWorkOrder?.comments ?? [])];
      comments.push(comment);
      setWorkOrder({ ...formWorkOrder, comments: comments });
    } else if (name === "shopSupplyPercent") {
      const shopSupplyCost =
        (formWorkOrder?.labourCost ?? 0) * (Number(value) / 100);
      const totalCost =
        (formWorkOrder?.partsCost ?? 0) +
        (formWorkOrder?.labourCost ?? 0) +
        (formWorkOrder?.subletCost ?? 0) +
        shopSupplyCost;
      setWorkOrder({
        ...formWorkOrder,
        shopSupplyPercent: Number(value),
        shopSupplyCost: shopSupplyCost,
        totalCost: totalCost,
      });
    } else if (name === "subletCost") {
      let amount = typeof value === 'string' ? parseFloat(value) : value;
      amount = isNaN(amount) ? 0.00 : parseFloat(amount.toFixed(2));
      const totalCost =
        (formWorkOrder?.partsCost ?? 0) +
        (formWorkOrder?.labourCost ?? 0) +
        Number(amount) +
        (formWorkOrder?.shopSupplyCost ?? 0);
      setWorkOrder({
        ...formWorkOrder,
        subletCost: amount,
        totalCost: totalCost,
      });
    } else {
      setValue({ [name]: value });
    }
    validateForm(name, value);
  };
  const [errors, setErrors] = useRecoilState<Record<string, string>>(workOrderValidationState); 
  const [showNotificationMessage, setShowNotificationMessage] =
    React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [modalHeading] = React.useState("");
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [showDeleteWorkOrderWarning, setShowDeleteWorkOrderWarning] = useState<boolean>(false);

  const totalCost =
    (formWorkOrder.labourCost ?? 0) +
    (formWorkOrder.partsCost ?? 0) +
    (formWorkOrder.subletCost ?? 0) +
    formWorkOrder.shopSupplyCost;

  const disableGlAccountAndFund =
    !formWorkOrder.costCentre && !formWorkOrder.wbsNumber ? true : false;

  const handleSaveWorkOrder = async () => {
    if (!validateForm("save")) {
      console.log("Form contains errors. Cannot submit.");
      return;
    }
    setIsLoading(true);

    try {
      //If part items or labour items has records with empty values, remove it before saving.
      const workOrder = { ...formWorkOrder };
      // if (workOrder.partItems && workOrder.partItems.length > 0) {
      //   workOrder.partItems = workOrder.partItems.filter(i => i.catalogItemId);
      // }
      // if (workOrder.labourItems && workOrder.labourItems.length > 0) {
      //   workOrder.labourItems = workOrder.labourItems.filter(i => i.labourItemId);
      // }

      let result;
      if (workOrder?.id) {
        result = await WorkOrderService.update(workOrder!);
      } else {
        result = await WorkOrderService.create(workOrder!);
      }

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Workorder saved successfully!");
        setShowNotificationMessage(true);
        setFormChanged(false);
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage((result?.error ?? "").replace(/\n/g, "<br>")
      );
        setShowNotificationMessage(true);
      }
    } catch (error) {
      console.error("Error updating or creating workorder:", error);
      alert("An error occurred while updating or creating the workorder");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteWorkOrder = async () => {
    try {
      const result = await WorkOrderService.deleteWO(formWorkOrder?.id ?? "");

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Workorder deleted successfully!");
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result?.error);
      }
      setShowDeleteWorkOrderWarning(false);
      setShowNotificationMessage(true);

    } catch (error) {
      if (error instanceof Error) {
      alert("An error occurred while deleting the workorder: " + error.message);
      }
      console.error("An error occurred while deleting the workorder:", error);
    } finally {
      setIsLoading(false);
    }
  };


  function setValue(value: Partial<WorkOrder>) {
    //setWorkOrder(new WorkOrder({ ...formWorkOrder, ...value }));
    setWorkOrder({ ...formWorkOrder, ...value });
  }

  const handleNonCatalogItemChecked = (name: string, checked: boolean) => {

    if (checked) {
      setEnableUnitInfo(true);
      handleFieldChange(name, checked);

    } else {
      setEnableUnitInfo(false);
      handleFieldChange(name, checked);
    }
    clearItemInfo();
  };

  const clearItemInfo = () => {
    setWorkOrder((prev) => ({
      ...prev,
      unitNo: '', // Clear the field
      itemName: '',
      make: '',
      model: '',
      altRefNo: '',
      serialNo: ''
    }));
  };


  const handleSaveComment = () => {
    if (comment) {
      if (comment.index !== undefined) {
        const updatedComments = [...(formWorkOrder.comments ?? [])];
        updatedComments[comment.index] = {
          ...updatedComments[comment.index],
          text: comment.value ?? "",
          username: userName,
          postDate: new Date().toISOString(),
        };

        setWorkOrder({
          ...formWorkOrder,
          comments: updatedComments,
        });
      } else {
        var newComment: UserComment = {
          id: comment.index,
          text: comment.value,
          username: userName,
          postDate: new Date().toISOString(),
        };
        const comments = [...(formWorkOrder?.comments ?? [])];
        comments.push(newComment);
        setWorkOrder({ ...formWorkOrder, comments: comments });
      }
      setComment(undefined);
      setIsAddingComment(true);
      setCommentCharCount(
        Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT)
      );
    }
  };

  const handleRemoveComment = (index: any) => {
    const updatedComments = [...(formWorkOrder.comments ?? [])];
    updatedComments.splice(index, 1);
    setWorkOrder({ ...formWorkOrder, comments: updatedComments });
  };

  function validateForm(name: string, value: string = "") {
    setErrors({});
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if (name === "save" && !formWorkOrder?.nonCatalogItem && !formWorkOrder?.unitNo) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "unitNo"] = "Unit # is required";
    }
    if (name === "save" && !formWorkOrder?.itemName) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "itemName"] = "Item name is required";
    }
    if (name === "save" && Number(formWorkOrder.status) === WORKORDER_STATUS_COMPLETED && !formWorkOrder?.workedByUserId) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "workedBy"] = "Worked by is required";
    }
    if (name === "save" && !formWorkOrder?.description) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "problem"] = "Problem is required";
    }
    if (name === "save" && !formWorkOrder?.locationId) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "location"] = "Location is required";
    }
    if (name === "save" && !formWorkOrder?.status) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "status"] = "Status is required";
    }
    if (name === "save" && !formWorkOrder?.dateIn) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateIn"] = "Date in is required";
    }
    if (name === "save" && formWorkOrder?.dateIn && new Date(formWorkOrder.dateIn).getFullYear() < 2000) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateIn"] = "Date in is not valid";
    }
    if (name === "save" && !formWorkOrder?.dateOut) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateOut"] = "Date out is required";
    }
    if (name === "save" && formWorkOrder?.dateOut && new Date(formWorkOrder.dateOut).getFullYear() < 2000) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateOut"] = "Date out is not valid";
    }
    if (name === "save" && formWorkOrder?.dateIn && formWorkOrder?.dateOut && (toDateString(new Date(formWorkOrder.dateOut)) < toDateString(new Date(formWorkOrder.dateIn)))) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateOut"] = "Date out can not be before date in";
    }
    if(formWorkOrder?.partItems){
      formWorkOrder.partItems.forEach((item, index) => {
        if(!item.isDeleted){
          if(!item.quantity){
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index] = "Qty is Required";
          }
          if(item.quantity && item.quantity <= 0) {
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index] = "Qty must be positive";
          }
          if(item.quantity && item.quantity > 0 && (Number(item.quantity) !== parseInt(String(item.quantity)))) {
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index] = "Qty must not be fractional";
          }
          if(!item.partNumber && !item.itemName){
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-itemname-" + index] = "Item name or part number must be provided";
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-partnumber-" + index] = "Item name or part number must be provided";
          }
        }
      });
    }

    if(formWorkOrder?.labourItems){
      formWorkOrder.labourItems.forEach((item, index) => {
        if(!item.isDeleted){
          if(!item.serviceCode && !item.description){
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "labour-item-servicecode-" + index] = "Service code or description must be provided";
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "labour-item-description-" + index] = "Service code or description must be provided";
          }
        }
      });
    }

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  const loadUnitOptions = debounce(
    async (inputValue: string, callback: (data: IUnitItem[]) => void) => {
      if (!inputValue) return;
      const data = await WorkOrderService.searchUnits(inputValue, false);
      // // Pass the transformed options to the callback function
      callback(data);
    },
    500
  );

  // const loadAltRefOptions = debounce(
  //   async (inputValue: string, callback: (data: IUnitItem[]) => void) => {
  //     if (!inputValue) return;
  //     const data = await WorkOrderService.searchUnits(inputValue, true);
  //     // // Pass the transformed options to the callback function
  //     callback(data);
  //   },
  //   500
  // );

  const onUnitNumberChange = (
    inputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change") {
      loadUnitOptions(inputValue, (data: any) => {
        console.log(data);
        setUnitItems(data.units);
        const options: SelectOptions[] = data.units.map((item: any) => {
          if (item != null) {
            return {
              value: item.unitNumber,
              label: item.unitNumber,
            };
          }
          return null;
        });
        setUnitNumbers(options);
      });
    }
  };

  // const onAltRefNumberChange = (
  //   inputValue: string,
  //   { action }: InputActionMeta
  // ) => {
  //   if (action === "input-change") {
  //     loadAltRefOptions(inputValue, (data: any) => {
  //       console.log(data);
  //       setUnitItems(data.units);
  //       const options: SelectOptions[] = data.units.map((item: any) => {
  //         if (item != null) {
  //           return {
  //             value: item.altRefNumber,
  //             label: item.altRefNumber,
  //           };
  //         }
  //         return null;
  //       });
  //       setAltRefNumbers(options);
  //     });
  //   }
  // };

  const handleUnitNumberChange = (item: string) => {
    setFormChanged(true);
    const selectedUnit = unitItems.find((l) => l.unitNumber === item);

    setWorkOrder({
      ...formWorkOrder,
      unitNo: selectedUnit?.unitNumber ?? "",
      altRefNo: selectedUnit?.altRefNumber ?? "",
      itemId: selectedUnit?.itemId ?? null,
      itemImisId: selectedUnit?.itemImisId ?? null,
      itemName: selectedUnit?.itemName ?? "",
      make: selectedUnit?.make ?? "",
      model: selectedUnit?.model ?? "",
      serialNo: selectedUnit?.serialNumber ?? "",
      totalCostHistory: selectedUnit?.totalCost ?? 0,
      totalHoursHistory: selectedUnit?.totalHours ?? 0,
    });
    const altRefNoList: SelectOptions[] = [];
    altRefNoList.push({
      label: selectedUnit?.altRefNumber ?? "",
      value: selectedUnit?.altRefNumber ?? "",
    });
    //setAltRefNumbers(altRefNoList);
  };

  // const handleAltRefNumberChange = (item: string) => {
  //   const selectedUnit = unitItems.find((l) => l.altRefNumber === item);

  //   setWorkOrder({
  //     ...formWorkOrder,
  //     unitNo: selectedUnit?.unitNumber ?? "",
  //     altRefNo: selectedUnit?.altRefNumber ?? "",
  //     itemName: selectedUnit?.itemName ?? "",
  //     make: selectedUnit?.make ?? "",
  //     model: selectedUnit?.model ?? "",
  //     serialNo: selectedUnit?.serialNumber ?? "",
  //     totalCostHistory: selectedUnit?.totalCost ?? 0,
  //     totalHoursHistory: selectedUnit?.totalHours ?? 0,
  //   });
  //   const unitNoList: SelectOptions[] = [];
  //   unitNoList.push({
  //     label: selectedUnit?.unitNumber ?? "",
  //     value: selectedUnit?.unitNumber ?? "",
  //   });
  //   setUnitNumbers(unitNoList);
  // };

  const populateWorkOrderPdfData = (pdfData: WorkOrder) => {
    pdfData.statusDesc = pdfData.status === 1 ? "Active" : "Completed";
    pdfData.dateInString = formatPDFDate(new Date(pdfData.dateIn));
    pdfData.dateOutString = formatPDFDate(new Date(pdfData.dateOut));
    pdfData.comments?.forEach(x=>x.postDateString=formatPDFDate(new Date(x.postDate)));
    const templateId =
      pdfData.status === 1
        ? process.env.REACT_APP_IMIS_WORKORDER_PDF_TEMPLATE_ACTIVE
        : process.env.REACT_APP_IMIS_WORKORDER_PDF_TEMPLATE_COMPLETED;

    if (pdfData.status === 1) {
      //Add Blank Rows for Parts and Labour sections for pdf
      let updatedLabourItems: any[] = pdfData.labourItems;
      for (let i = 0; i < 3 - pdfData.labourItems.length; i++) {
        const emptyLabourItem = new WorkOrderLabourItem();
        emptyLabourItem.hourlyRate =
          emptyLabourItem.hours =
          emptyLabourItem.otFactor =
          emptyLabourItem.labourItemCost =
          undefined;
        updatedLabourItems = updatedLabourItems.concat(emptyLabourItem);
      }
      pdfData = { ...pdfData, labourItems: updatedLabourItems };
      console.log("PDF - LabourItems: " + JSON.stringify(pdfData?.labourItems));

      let updatedPartItems: any[] = pdfData.partItems ?? [];
      for (let i = 0; i < 10 - pdfData.partItems?.length; i++) {
        const emptyPartItem = new WorkOrderPartItem();
        emptyPartItem.partItemCost =
          emptyPartItem.quantity =
          emptyPartItem.unitCost =
          undefined;
        updatedPartItems = updatedPartItems.concat(emptyPartItem);
      }
      pdfData = { ...pdfData, partItems: updatedPartItems };
      console.log("PDF - PartItems: " + JSON.stringify(pdfData?.partItems));
    }

    setWorkOrderPdfData({
      ...workOrderPdfData,
      templateId: templateId ?? "",
      filename:  pdfData.status === 1 ? "work-order-mvp-active.pdf" : "work-order-mvp-completed.pdf",
      data: pdfData,
    });
  };

  // eslint-disable-next-line no-use-before-define
  useEffect(() => {
    async function fetchData() {

      const loadStatusOptions = debounce(
        async (inputValue: string, callback: (response: any[]) => void) => {
          const response = getDataFromSessionStorage(ForObjectType.WORKORDER);
          if (!response) {
            loadCommonLookups();
          }
          callback(response);
        },
        500
      );

      // const loadGLAccounts = debounce(
      //   async (callback: (data: any[]) => void) => {
      //     const data = await WildfireFinanceService.getGLAccounts();
      //     // Pass the transformed options to the callback function
      //     callback(data);
      //   },
      //   500
      // );

      // const loadcostcentre = debounce(
      //   async (callback: (data: any[]) => void) => {
      //     const data = await WildfireFinanceService.getCostCenter();
      //     // Pass the transformed options to the callback function
      //     callback(data);
      //   },
      //   500
      // );

      // const loadFunds = debounce(async (callback: (data: any[]) => void) => {
      //   const data = await WildfireFinanceService.getFunds();
      //   // Pass the transformed options to the callback function
      //   callback(data);
      // }, 500);

      loadLocationOptions("WSC1", (result: SelectOptions[]) => {
        setLocations(result);
        const defaultLocation = result.find(i => i.label.includes(String(process.env.REACT_APP_IMIS_WORKORDER_DEFAULTLOCATION)));
        populateWorkOrderDetails(defaultLocation);
      });

      loadStatusOptions("", (result: any) => {
        setStatusOptions(
          result.find((x: CommonLookup) => x.name === "status")?.elements
        );
      });

      // loadGLAccounts((result: any) => {
      //   setGlAccountOptions(
      //     result?.map((i: any) => ({
      //       label: i.glAccountName + " - " + i.glAccount,
      //       value: i.glAccount,
      //     }))
      //   );
      // });
      // loadFunds((result: any) => {
      //   setFundOptions(
      //     result?.map((i: any) => ({
      //       label: i.fundName + " - " + i.fund,
      //       value: i.fund,
      //     }))
      //   );
      // });
      // loadcostcentre((result: any) => {
      //   setCostCenterOptions(
      //     result?.map((i: any) => ({
      //       label: i.costCenterName + " - " + i.costCenter,
      //       value: i.costCenter,
      //     }))
      //   );
      // });
    }
    async function populateWorkOrderDetails(location: any) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setErrors({});
      if (workOrderId) {
        try {
          setIsLoading(true);
          const response = await WorkOrderService.getById(workOrderId);
          if (response) {
            const unitNumberOptions: SelectOptions[] = [];
            unitNumberOptions.push({
              value: response.data.unitNo,
              label: response.data.unitNo,
            });
            setUnitNumbers(unitNumberOptions);
            const altRefNumberOptions: SelectOptions[] = [];
            altRefNumberOptions.push({
              value: response.data.altRefNo,
              label: response.data.altRefNo,
            });
            //setAltRefNumbers(altRefNumberOptions);
            response.data.labourItems.forEach(i =>{
                i.otFactorDesc = mapOTFactorDesc(Number(i.otFactor));
            })
            setWorkOrder(response.data);
            populateWorkOrderPdfData({ ...response.data });
            setProblemCharCount(
              Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT) -
              (response.data.description
                ? response.data.description.length
                : 0)
            );
            setSubletDescriptionCharCount(
              1000 -
              (response.data.subletDescription
                ? response.data.subletDescription.length
                : 0)
            );

            const partsLocationData: SelectOptions[] = [];
            partsLocationData.push({
              value: response.data.partItems?.at(0)?.locationId ?? (process.env.REACT_APP_IMIS_WORKORDER_PARTS_DEFAULTLOCATION_VALUE ?? ""),
              label: response.data.partItems?.at(0)?.locationName ?? (process.env.REACT_APP_IMIS_WORKORDER_PARTS_DEFAULTLOCATION_LABEL ?? "")
            });

            setPartsLocationOptions(partsLocationData);
            console.log(partsLocationOptions);

            setSelectedPartsLocation({
              value: response.data.partItems?.at(0)?.locationId ?? (process.env.REACT_APP_IMIS_WORKORDER_PARTS_DEFAULTLOCATION_VALUE ?? ""),
              label: response.data.partItems?.at(0)?.locationName ?? (process.env.REACT_APP_IMIS_WORKORDER_PARTS_DEFAULTLOCATION_LABEL ?? "")
            });
            console.log(selectedPartsLocation);

            const labourItemOptions: SelectOptions[] = response.data.labourItems
              ?.map((item) => {
                return {
                  value: item.labourItemId,
                  label: item.serviceCode,
                };
              })
              .filter((option) => option !== null) as SelectOptions[];
            setLabourItemOptionsFiltered(labourItemOptions);
            console.log(labourItemOptionsFiltered);
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching catalogs:", error);
          setIsLoading(false);
        }
      } else {
        const workorder = new WorkOrder();
        workorder.createdByName = getUserName(auth?.user?.access_token ?? "");
        workorder.locationId = location.value;
        workorder.locationName = location.label;
        setWorkOrder(workorder);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const loadLocationOptions = debounce(
  //   async (inputValue: string, callback: (data: any[]) => void) => {
  //     if (!inputValue) return;
  //     const data = await LocationService.getList(inputValue);
  //     // Pass the transformed options to the callback function
  //     callback(data);
  //   },
  //   500
  // );

  const loadLocationOptions = debounce(
    async (inputValue: string, callback: (data: any[]) => void) => {
      if (!inputValue) return;
      const data = await LocationService.getList(inputValue);
      callback(data);
    },
    500
  );

  const handleLocationChange = (selectedLocation: any) => {
    setFormChanged(true);
    setWorkOrder({
      ...formWorkOrder,
      locationId: selectedLocation.value,
      locationName: selectedLocation.label,
    });
  };

  const onLocationSearchChange = (
    inputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change") {
      loadLocationOptions(inputValue, (result: any) => {
        setLocations(result);
      });
    }
  };


  useEffect(() => {
    if (!formWorkOrder.locationId || !formWorkOrder.itemName) return;

    const normalizedInputValue = formWorkOrder.itemName?.toLowerCase();

    if (normalizedInputValue && normalizedInputValue.length > 1) {
      getItemsForLocation(formWorkOrder.locationId, normalizedInputValue);
    }
  }, [formWorkOrder.locationId, formWorkOrder.itemName]);

  useEffect(() => {
    if (!itemsOptions || !formWorkOrder.itemName) return;

    const normalizedInputValue = formWorkOrder.itemName.toLowerCase();

    if (itemsOptions.length > 0 && normalizedInputValue.length > 1) {
      const data = itemsOptions
        .filter(x =>
          (x.code && x.code.includes(normalizedInputValue)) ||
          (x.name && x.name.toLowerCase().includes(normalizedInputValue)) ||
          (x.imisId && x.imisId.toString().includes(normalizedInputValue))
        )
        .sort((a, b) => b.name.localeCompare(a.name));

      if (data.length > 0) {
        const options: SelectOptions[] = data
          .map(item => {
            if (item.name !== null) {
              return {
                value: item.catalogItemId,
                label: `${item.name} (${item.imisId}-${item.code})`
              };
            }
            return null;
          })
          .filter(option => option !== null) as SelectOptions[];
        setItemsFiltered(options);
      }
    }
  }, [itemsOptions, formWorkOrder.itemName]);



  const getItemsForLocation = async (locationId: string, search: string) => {
    const itemsData = await LocationService.getItemAdjustmentById(locationId, search);
    setItems(itemsData?.data.items);
  }

  const handleFocus = (event: any) => {
    event.target.select();
  };

  return (
    <div className={`workorder-main-tab-wrapper ${formWorkOrder.status === WORKORDER_STATUS_COMPLETED ? 'disabled' : ''}`}>
      <PageLoader visible={loading} />
      <div className="form-detail-container work-order-form-container">
        <div className={`create-work-order-table ${!canChangeStatus ? 'disable' : ''}`}>
          <div>
            {" "}
            <GoAFormItem label="Work order #">
              <GoAInputText
                disabled
                name="workOrderNo"
                value={formWorkOrder?.workOrderNo ?? ""}
                width="188px"
                onChange={(item) => handleFieldChange("workOrderNo", item)}
              />
            </GoAFormItem>
          </div>
          <div className={`workorder-status ${!canChangeStatus ? 'disabled' : ''}`}>
            {" "}
            <GoAFormItem label="Status indicator" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "status"]}>
              <GoADropdown
                name="status"
                value={formWorkOrder?.status?.toString() ?? ""}
                width="188px"
                onChange={(item, value) => handleFieldChange("status", value)}
              >
                {statusOptions?.map((item: any) => (
                  <GoADropdownItem
                    label={item.value}
                    value={item.id}
                    key={item.id}
                  ></GoADropdownItem>
                ))}
              </GoADropdown>
            </GoAFormItem>
          </div>
          <div className="create-work-order-checkbox">
            <GoAFormItem>
              <GoACheckbox
                name="chatchupDataEntry"
                text="Catch up data entry"
                checked={formWorkOrder?.chatchupDataEntry ?? false}
                onChange={(item, value) =>
                  handleFieldChange("chatchupDataEntry", value)
                }
              />
            </GoAFormItem>
          </div>
          <div className="create-work-order-checkbox">
            {" "}
            <GoAFormItem>
              <GoACheckbox
                name="nonCatalogItem"
                text="Non catalogued item"
                checked={formWorkOrder?.nonCatalogItem ?? false}
                onChange={(name, checked, value) =>
                  handleNonCatalogItemChecked(name, checked)
                }
              />
            </GoAFormItem>
          </div>
          <div className="create-work-order-location">
            {" "}
            <GoAFormItem label="Location" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "location"]}>
              <Select
                name="location"
                options={locations}
                placeholder="--Select--"
                className="width100 select-input"
                isDisabled={false}
                isSearchable={true}
                onInputChange={onLocationSearchChange}
                onChange={handleLocationChange}
                value={locations?.find(i => i.value === formWorkOrder?.locationId)}
              />
              {/* <GoADropdown
                name={formWorkOrder?.locationName}
                width="394px"
                multiselect={false}
                value={formWorkOrder?.locationId?.toString() ?? ""}
                onChange={(item, values) => {
                  setWorkOrder({
                    ...formWorkOrder,
                    locationId: Array.isArray(values) ? values[0] : values,
                    locationName: item,
                  });
                  // handleLocationChange(locationId : Array.isArray(values) ? values[0] : values, locationName: item);
                }}
              >
                {locations?.map((item: any) => (
                  <GoADropdownItem
                    label={item.label}
                    value={item.value}
                    key={item.value}
                  ></GoADropdownItem>
                ))}
                {/* <GoADropdownItem value="10435A01-9203-4F04-9C76-DE4E49BB4E0A" label="WSC1SE MECHANICAL SECTION"></GoADropdownItem> */}
              {/*  </GoADropdown> */}
            </GoAFormItem>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Unit information">
          <div className={`workorder-unit-info-container ${canUpdateWorkOrder ? '' : 'disable'}`}>
            <div className="unit-info-unitno">
              {" "}
              <GoAFormItem label="Unit #" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "unitNo"]}>
                {formWorkOrder?.nonCatalogItem ? (
                  <GoAInputText
                    id="unitNo"
                    name="unitNo"
                    placeholder="Please type in unit #"
                    width="400px"
                    value={formWorkOrder?.unitNo}
                    onChange={(item, value) => handleFieldChange("unitNo", value)}
                  />
                ) : (
                  <Select
                    id="unitNo"
                    name="unitNo"
                    options={unitNumbers}
                    placeholder="--Select--"
                    className="workorder-unit-no select-input"
                    isDisabled={false}
                    isSearchable={true}
                    onInputChange={onUnitNumberChange}
                    onChange={(item) => handleUnitNumberChange(item?.value ?? "")}
                    value={unitNumbers.find(
                      (i) => i.value === formWorkOrder?.unitNo
                    )}
                  />
                )}
              </GoAFormItem>
            </div>
            <div className="unit-info-itemname">
              {" "}
              <GoAFormItem label="Item name" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "itemName"]}>
                <Autocomplete
                  freeSolo // Allows free text input
                  options={itemsOptionsFiltered}
                  value={formWorkOrder?.itemName ?? ""}
                  onChange={(event, value) => {
                    // If value is null, clear the itemName
                    handleFieldChange("itemName", value ? value.label : "");
                  }}
                  inputValue={formWorkOrder?.itemName ?? ""} // Current input value
                  onInputChange={(event, newInputValue) => {
                    handleFieldChange("itemName", newInputValue); // Pass the input to handleFieldChange
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select or type item name, code or imisId"
                      style={{ width: "390px" }}
                    />
                  )}
                />

                {/* <GoAInputText
                  disabled={!enableUnitInfo}
                  name="itemName"
                  value={formWorkOrder?.itemName ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("itemName", value)
                  }
                /> */}
              </GoAFormItem>
            </div>
            <div className="unit-info-altref">
              {" "}
              <GoAFormItem label="Alternative reference#" requirement="optional">
                <GoAInputText
                  name="altRefNo"
                  value={formWorkOrder?.altRefNo ?? ""}
                  width="400px"
                  onChange={(item, value) => handleFieldChange("altRefNo", value)}
                />
                {/* <Select
                  id="altRefNo"
                  name="altRefNo"
                  options={altRefNumbers}
                  placeholder="--Select--"
                  className="workorder-unit-no select-input"
                  isDisabled={false}
                  isSearchable={true}
                  onInputChange={onAltRefNumberChange}
                  onChange={(item) =>
                    handleAltRefNumberChange(item?.value ?? "")
                  }
                  value={altRefNumbers.find(
                    (i) => i.value === formWorkOrder?.altRefNo
                  )}
                /> */}
              </GoAFormItem>
            </div>
            <div className="unit-info-make">
              {" "}
              <GoAFormItem label="Make">
                <GoAInputText
                  disabled={!enableUnitInfo}
                  name="make"
                  value={formWorkOrder?.make ?? ""}
                  width="400px"
                  onChange={(item, value) => handleFieldChange("make", value)}
                />
              </GoAFormItem>
            </div>
            <div className="unit-info-model">
              {" "}
              <GoAFormItem label="Model">
                <GoAInputText
                  disabled={!enableUnitInfo}
                  name="model"
                  value={formWorkOrder?.model ?? ""}
                  width="400px"
                  onChange={(item, value) => handleFieldChange("model", value)}
                />
              </GoAFormItem>
            </div>
            <div className="unit-info-serialno">
              {" "}
              <GoAFormItem label="Serial #">
                <GoAInputText
                  disabled={!enableUnitInfo}
                  name="serialNo"
                  value={formWorkOrder?.serialNo ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("serialNo", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="unit-info-totalcost">
              {" "}
              <GoAFormItem label="Total cost to date">
                <GoAInputText
                  disabled
                  name="totalCostHistory"
                  value={formWorkOrder?.totalCostHistory?.toString() ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("totalCostHistory", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="unit-info-totalhours">
              {" "}
              <GoAFormItem label="Total hours">
                <GoAInputText
                  disabled
                  name="totalHoursHistory"
                  value={formWorkOrder?.totalHoursHistory?.toString() ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("totalHoursHistory", value)
                  }
                />
              </GoAFormItem>
            </div>
          </div>
        </GoAContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Problem">
          <div className={`workorder-problem-container ${canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? '' : 'disable'}`}>
            <div>
              {" "}
              <GoAFormItem label="Problem" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "problem"]}>
                <textarea
                  className="form-item-textarea workorder-problem"
                  name="problem"
                  value={formWorkOrder?.description ?? ""}
                  onChange={(e) => {
                    handleFieldChange("description", e.currentTarget.value);
                    setProblemCharCount(
                      Number(
                        process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT
                      ) - e.currentTarget.value.length
                    );
                  }}
                  maxLength={Number(
                    process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT
                  )}
                  rows={5}
                ></textarea>
                <div style={{ width: "700px" }}>
                  <label className="workorder-char-count-label">{`${problemCharCount} characters remaining`}</label>
                </div>
              </GoAFormItem>
            </div>
          </div>
        </GoAContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Finance">
          <div className={`workorder-finance-container  ${canUpdateWorkOrder ? '' : 'disable'}`}>
            <div className="finance-contactname">
              {" "}
              <GoAFormItem label="Contact name" requirement="optional">
                <GoAInputText
                  name="contactName"
                  value={formWorkOrder?.contactName ?? ""}
                  width="400px"
                  maxLength={50}
                  onChange={(item, value) =>
                    handleFieldChange("contactName", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="finance-contactphone">
              {" "}
              <GoAFormItem label="Contact phone" requirement="optional">
                <GoAInput
                  name="contactPhone"
                  value={formWorkOrder?.contactPhone ?? ""}
                  width="400px"
                  type="tel"
                  maxLength={10}
                  onChange={(item, value) =>
                    handleFieldChange("contactPhone", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="finance-expenditureofficer">
              {" "}
              <GoAFormItem label="Expenditure officer" requirement="optional">
                <GoAInputText
                  name="expenditureOfficer"
                  value={formWorkOrder?.expenditureOffice ?? ""}
                  width="400px"
                  maxLength={50}
                  onChange={(item, value) =>
                    handleFieldChange("expenditureOffice", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="finance-costcenter hide">
              {" "}
              <GoAFormItem label="Cost center" requirement="optional">
                <GoADropdown
                  name="costcentre"
                  filterable
                  value={formWorkOrder?.costCentre ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("costCentre", value)
                  }
                >
                  {/* {costCenterOptions?.map((item: any) => (
                    <GoADropdownItem
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    ></GoADropdownItem>
                  ))} */}
                </GoADropdown>
              </GoAFormItem>
            </div>
            <div className="finance-wbsnumber hide">
              {" "}
              <GoAFormItem label="WBS number" requirement="optional">
                <GoADropdown
                  name="wbsNumber"
                  value={formWorkOrder?.wbsNumber ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("wbsNumber", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="finance-chargecode">
              {" "}
              <GoAFormItem label="Charge code" requirement="optional">
                <GoAInputText
                  name="chargeCode"
                  value={formWorkOrder?.chargeCode ?? ""}
                  width="400px"
                  maxLength={50}
                  onChange={(item, value) =>
                    handleFieldChange("chargeCode", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="finance-glaccount hide">
              {" "}
              <GoAFormItem label="GL account">
                <GoADropdown
                  disabled={disableGlAccountAndFund}
                  filterable
                  name="glAccount"
                  value={formWorkOrder?.glAccount ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("glAccount", value)
                  }
                >
                  {/* {glAccountOptions?.map((item: any) => (
                    <GoADropdownItem
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    ></GoADropdownItem>
                  ))} */}
                </GoADropdown>
              </GoAFormItem>
            </div>
            <div className="finance-fund hide">
              {" "}
              <GoAFormItem label="Fund">
                <GoADropdown
                  disabled={disableGlAccountAndFund}
                  filterable
                  name="fund"
                  value={formWorkOrder?.fund ?? ""}
                  width="400px"
                  onChange={(item, value) => handleFieldChange("fund", value)}
                >
                  {/* {fundOptions?.map((item: any) => (
                    <GoADropdownItem
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    ></GoADropdownItem>
                  ))} */}
                </GoADropdown>
              </GoAFormItem>
            </div>
          </div>
        </GoAContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Costs">
          <div className={`workorder-costs-container ${canUpdateWorkOrder ? '' : 'disable'}`}>
            <div className="costs-partscost">
              {" "}
              <GoAFormItem label="Parts cost">
                <GoAInput
                  disabled
                  leadingContent="$"
                  type="number"
                  name="partsCost"
                  value={formWorkOrder?.partsCost?.toFixed(2) ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("partsCost", value)
                  }
                />


              </GoAFormItem>
            </div>
            <div className="costs-shopsupplies">
              {" "}
              <div className="costs-shopsuppplies-percentage">
                <GoAFormItem>
                  <input
                    type="number"
                    onChange={(e) => {
                      if (parseFloat(e.currentTarget.value) > 100) {
                        return false;
                      }
                      handleFieldChange(
                        "shopSupplyPercent",
                        e.currentTarget.value
                      );
                    }}
                    value={formWorkOrder?.shopSupplyPercent?.toFixed(2) ?? "00"}
                    className={"input-common"}
                    width="131px"
                    name="percentageOfParts"
                  />
                  <label className="percentage-of-parts-label">
                    % of Labour:
                  </label>
                </GoAFormItem>
              </div>
              <GoAFormItem label="Shop supplies">
                <GoAInput
                  disabled
                  leadingContent="$"
                  type="number"
                  name="shopSupplies"
                  maxLength={4}
                  value={formWorkOrder?.shopSupplyCost?.toFixed(2) ?? ""}
                  width="200px"
                  onChange={(item, value) =>
                    handleFieldChange("shopSupplyCost", value)
                  }
                />
              </GoAFormItem>
            </div>
            <div className="costs-labourcost">
              {" "}
              <GoAFormItem label="Labour cost">
                <GoAInput
                  disabled
                  leadingContent="$"
                  name="labourCost"
                  type="number"
                  value={formWorkOrder.labourCost?.toFixed(2) ?? ""}
                  width="400px"
                  onChange={() => {}}
                />
              </GoAFormItem>
            </div>
            <div className="costs-sublet">
              {" "}
              <GoAFormItem label="Sublet" requirement="optional">
                <textarea
                  className="form-item-textarea workorder-subletdesc"
                  name="subletDesc"
                  value={formWorkOrder?.subletDescription?.toString() ?? ""}
                  onChange={(e) => {
                    handleFieldChange(
                      "subletDescription",
                      e.currentTarget.value
                    );
                    setSubletDescriptionCharCount(
                      1000 - e.currentTarget.value.length
                    );
                  }}
                  maxLength={Number(
                    process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT
                  )}
                  rows={5}
                ></textarea>
                <div style={{ width: "844px" }}>
                  <label className="workorder-char-count-label">{`${subletDescriptionCharCount} characters remaining`}</label>
                </div>
              </GoAFormItem>
            </div>
            <div className="costs-subletcost">
              {" "}
              <GoAFormItem label="Sublet cost">
                <div className="input-container">
                  <TextField
                    type="number"
                    onChange={(e) => {
                      handleFieldChange(e.target.name, e.target.value)}
                    }
                    value={formWorkOrder?.subletCost}
                    placeholder="0.00"
                    name="subletCost"
                    onFocus={handleFocus}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                      inputProps: {
                        step: 0.01
                      },
                      sx: {height: '40px'}
                    }}
                    style={{ width: '400px' }}
                    className="amount-input workorder-subletcost"
                  />
                </div>
              </GoAFormItem>
            </div>
            <div className="costs-total">
              {" "}
              <GoAFormItem label="Total">
                <GoAInput
                  leadingContent="$"
                  disabled
                  type="number"
                  name="total"
                  value={Number(totalCost).toFixed(2) ?? ""}
                  width="400px"
                  onChange={(item, value) => { }}
                />
              </GoAFormItem>
            </div>
          </div>
        </GoAContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        <GoAContainer accent="thick" padding="relaxed" title="Worked by">
          <div className="workorder-workby-container">
            <div className={`workby-datein ${(canUpdateWorkOrder || canPartiallyUpdateWorkOrder) && formWorkOrder.chatchupDataEntry ? '' : 'disable'}`}>
              <GoAFormItem label="Date in" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "dateIn"]}>
                <GoAInputDate
                  name="dateIn"
                  value={dateIn}
                  onChange={(item, value) => handleFieldChange("dateIn", value)}
                ></GoAInputDate>
              </GoAFormItem>
            </div>
            <div className="workby-createdby">
              <GoAFormItem label="Created by">
                <GoAInputText
                  disabled
                  name="createdBy"
                  value={formWorkOrder.createdByName ?? ""}
                  width="400px"
                  onChange={() => { }}
                />
              </GoAFormItem>
            </div>
            <div className="workby-workedby">
              <GoAFormItem label="Worked by" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "workedBy"]}>
                <Autocomplete
                  freeSolo // Allows free text input
                  options={people}
                  value={formWorkOrder?.workedByUserId ?? ""}
                  onChange={(event, value) => handleFieldChange("workedByUserId", value)}
                  inputValue={formWorkOrder.workedByUserId ?? ""} // Current input value
                  onInputChange={(event, newInputValue) => {
                    handleFieldChange("workedByUserId", newInputValue); // Pass the input to handleFieldChange
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select or type a name"
                      style={{ width: "290px" }}
                    />)}
                />
              </GoAFormItem>
            </div>
            <div className="workBy-SubGrid-1">
              <div className="workby-certified">
                <GoAFormItem>
                  <GoACheckbox
                    name="Certified"
                    text="Certified"
                    checked={formWorkOrder?.isCertified ?? false}
                    onChange={(name, checked) => {
                      if (!checked) {
                        setValue({
                          certifiedHours: 0,
                          certifiedPSI: 0,
                          isCertified: checked,
                        });
                      } else {
                        setValue({ isCertified: checked });
                      }
                    }}
                  />
                </GoAFormItem>
              </div>
              <div className="workby-athours">
                <GoAFormItem label="At hours">
                  {" "}
                  <input
                    type="number"
                    onChange={(e) => {
                      if (parseFloat(e.currentTarget.value) > 9999) {
                        return false;
                      }
                      handleFieldChange(
                        "certifiedHours",
                        e.currentTarget.value
                      );
                    }}
                    value={formWorkOrder?.certifiedHours?.toString() ?? "00"}
                    className={`input-common ${formWorkOrder.isCertified ? "enabled" : "disabled"
                      }`}
                    name="atHours"
                  />
                </GoAFormItem>
              </div>
              <div className="workby-atpsi">
                <GoAFormItem label="At PSI">
                  {" "}
                  <input
                    type="number"
                    onChange={(e) => {
                      if (parseFloat(e.currentTarget.value) > 999) {
                        return false;
                      }
                      handleFieldChange("certifiedPSI", e.currentTarget.value);
                    }}
                    value={formWorkOrder?.certifiedPSI?.toString() ?? "00"}
                    className={`input-common ${formWorkOrder.isCertified ? "enabled" : "disabled"
                      }`}
                    width="131px"
                    name="atPSI"
                  />
                  {/* <GoAInputText
                    disabled={!formWorkOrder?.isCertified}
                    name="atPSI"
                    value={formWorkOrder?.certifiedPSI?.toString() ?? "00"}
                    width="131px"
                    maxLength={2}
                    onChange={(item, value) =>
                      handleFieldChange("certifiedPSI", value)
                    }
                  /> */}
                </GoAFormItem>
              </div>
            </div>

            <div className={`workby-dateout ${canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? '' : 'disable'}`}>
              <GoAFormItem label="Date out" error={errors[VALIDATION_WORKORDER_TAB_MAIN + "dateOut"]}>
                <GoAInputDate
                  name="dateOut"
                  value={dateOut}
                  onChange={(item, value) =>
                    handleFieldChange("dateOut", value)
                  }
                ></GoAInputDate>
              </GoAFormItem>
            </div>
            <div className={`workby-closedby`}>
              <GoAFormItem label="Closed by">
                <GoAInputText
                  disabled
                  name="closedBy"
                  value={formWorkOrder?.closedByName ?? ""}
                  width="400px"
                  onChange={(item, value) =>
                    handleFieldChange("closedBy", value)
                  }
                />
              </GoAFormItem>
            </div>
          </div>
        </GoAContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Comments">
          <div className={`workorder-comments-container ${canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? '' : 'disable'}`}>
            <div>
              {" "}
              <GoAFormItem label="Comments">
                <textarea
                  className="form-item-textarea workorder-comments"
                  name="comment"
                  value={comment?.value ?? ""}
                  onChange={(e) => {
                    if(e.currentTarget.value.trim())
                    {
                    setComment({
                      value: e.currentTarget.value,
                      index: comment?.index ?? undefined,
                    });
                  }else{
                    setComment({
                      value: "",
                      index: comment?.index ?? undefined,
                    });
                  }
                    setCommentCharCount(
                      Number(
                        process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT
                      ) - e.currentTarget.value.length
                    );
                  }}
                  maxLength={Number(
                    process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT
                  )}
                  rows={5}
                ></textarea>
                <div style={{ width: "700px" }}>
                  <label className="workorder-char-count-label">{`${commentCharCount} characters remaining`}</label>
                </div>
              </GoAFormItem>
            </div>
            <div style={{ marginTop: "30px" }}>
              {" "}
              <GoAFormItem>
                <GoAButton type="secondary" onClick={handleSaveComment}>
                  {isAddingComment ? "Add comment" : "Update comment"}
                </GoAButton>
              </GoAFormItem>
            </div>
            <div style={{ marginTop: "30px" }}>
              {formWorkOrder?.comments?.map(
                (comment: UserComment, index: number) => (
                  comment.text && (<GoAContainer
                    key={comment?.id}
                    testId={comment?.id?.toString()}
                  >
                    <div className={`work-order-comment ${userName !== comment.username && !canCreateWorkOrder ? 'disabled' : ''}`}>
                      <div>
                        <b>{comment.username}</b> on{" "}
                        {new Date(comment.postDate).toLocaleDateString("en", {
                          year: "numeric",
                          day: "2-digit",
                          month: "long",
                        })}
                      </div>
                      <div className="work-order-comment-edit">
                        <GoAIconButton
                          icon="pencil"
                          variant="dark"
                          onClick={() => {
                            setIsAddingComment(false);
                            setComment({ index: index, value: comment?.text });
                            setCommentCharCount(
                              Number(
                                process.env
                                  .REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT
                              ) - comment?.text.length
                            );
                          }}
                        ></GoAIconButton>
                        <GoAIconButton
                          icon="close"
                          variant="dark"
                          onClick={() => handleRemoveComment(index)}
                        ></GoAIconButton>
                      </div>
                    </div>
                    <div>{comment.text}</div>
                  </GoAContainer>)
                )
              )}
            </div>
          </div>
        </GoAContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAButtonGroup alignment="start">
          <GoAButton type="primary" onClick={handleSaveWorkOrder}>
            Save work order
          </GoAButton>
          <GoAButton
            type="secondary"
            onClick={() => {
                if (formChanged && setShowCancelModal)
                  setShowCancelModal(true);
                else if (isHomePage)
                  navigate("/home");
                else
                navigate("/workOrderList");
            }}
          >
            Cancel
          </GoAButton>
          <div
            className={`delete-workorder-button ${!(formWorkOrder.workOrderNo && canCreateWorkOrder) ? "hide" : ""
              }`}
          >
            <GoAButton
              type="primary"
              variant="destructive"
              onClick={() => setShowDeleteWorkOrderWarning(true)}
            >
              Delete work order
            </GoAButton>
          </div>
        </GoAButtonGroup>
      </div>
      <GoAModal
        heading={modalHeading}
        calloutVariant={calloutVariant}
        open={showNotificationMessage}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton
              onClick={() => {
                setShowNotificationMessage(false);
                if (calloutVariant === CalloutVariant.SUCCESS) {
                   if (isHomePage)
                    navigate("/home");
                  else
                    navigate("/workOrderList");
                }
              }}
            >
              Okay
            </GoAButton>
          </GoAButtonGroup>
        }
      >        <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
      </GoAModal>
      <GoAModal heading="Are you sure you want to delete this workorder?" open={showDeleteWorkOrderWarning}>
        <GoAButtonGroup alignment="end">
          <GoAButton type="secondary" onClick={() => setShowDeleteWorkOrderWarning(false)}>
            Cancel
          </GoAButton>
          <GoAButton type="primary" onClick={handleDeleteWorkOrder}>
            Confirm
          </GoAButton>
        </GoAButtonGroup>
      </GoAModal>
    </div>

    
  );
};
export default WorkOrderMainTab;
