import { GoAAccordion, GoAButton } from "@abgov/react-components";
//import PageLoader from "../../components/PageLoader";
//import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//import { Order, sampleOrders } from "../../model/Order";

interface IOrderDetailProps {}
const OrderDetail: React.FC<IOrderDetailProps> = (props) => {
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { orderNumber } = useParams();

  return (
    <>
      {/* <PageLoader visible={loading} /> */}
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => (window.location.pathname = "/home")}
            >
              Home
            </GoAButton>
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => navigate(`/ordersList`)}
            >
              Orders
            </GoAButton>
            <GoAButton type="tertiary" onClick={() => {}}>
              {orderNumber}
            </GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <h3 style={{ fontWeight: "600" }}>Order: {orderNumber}</h3>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <div>
              <b>Status</b>
            </div>
            <div>Ordered</div>
          </div>
        </div>
        <div className="left width100 order-summary-container">
          <GoAAccordion heading="Order summary" headingSize="medium">
            <div className="order-summary-table">
                <div>
                  <div>Order date</div>
                  <div>July 17, 2024</div>
                </div>
                <div >
                  <div>Ordered by</div>
                  <div>Charles Brown</div>
                </div>
                <div >
                  <div>Crew number</div>
                  <div>WU-002-A</div>
                </div>
                <div >
                  <div>Order type</div>
                  <div>Fireline Equipment</div>
                </div>
                <div >
                  <div>Ordered from</div>
                  <div>Area warehouse</div>
                </div>
                <div >
                  <div>Forest area</div>
                  <div>Whitecourt</div>
                </div>
                <div >
                  <div>Required by</div>
                  <div>01/04/2025</div>
                </div>
                <div >
                  <div>Delivery method</div>
                  <div>Pickup</div>
                </div>
                <div className="order-summary-address">
                  <div>Ship To</div>
                  <div>123 Abc st, Toronto, ON, L6x 0z3</div>
                </div>
                <div >
                  <div>Fire number</div>
                  <div>FIRE999</div>
                </div>
                <div >
                  <div>Watchers</div>
                  <div>John Wick</div>
                </div>
            </div>
          </GoAAccordion>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
