import { atom } from "recoil";
import { CommonService } from "../services/CommonService";
import { ForObjectType, LookupType } from "../model/enums/Constants";
import { saveDataToSessionStorage } from "../utils/SessionStorageUtils";

export const statusOptionsState = atom<{ id: number; value: string }[]>({
	key: "statusOptionsState",
	default: undefined
});

export const itemTypeOptionsState = atom<{ id: number; value: string }[]>({
	key: "itemTypeOptionsState",
	default: undefined
});

export const useOptionsState = atom<{ id: number; value: string }[]>({
	key: "useOptionsState",
	default: undefined
});

export const unitOptionsState = atom<{ id: number; value: string }[]>({
	key: "unitOptionsState",
	default: undefined
});

export const loadCommonLookups = async () => {
	try {
		const responseWorkOrder = await CommonService.getCommonLookups(
			ForObjectType.WORKORDER,
			[LookupType.STATUS]
		);
		saveDataToSessionStorage(ForObjectType.WORKORDER, responseWorkOrder);

		const responseParts = await CommonService.getCommonLookups(
			ForObjectType.PART,
			[LookupType.STATUS, LookupType.ITEM_TYPE, LookupType.USE, LookupType.UNITS]
		);
		saveDataToSessionStorage(ForObjectType.PART, responseParts);

		const responseAdj = await CommonService.getCommonLookups(
			ForObjectType.ADJUSTMENTS,
			[LookupType.ADJUSTMENT_REASON, LookupType.MEMO, LookupType.OWNER]
		);
		saveDataToSessionStorage(ForObjectType.ADJUSTMENTS, responseAdj);

		const responseInv = await CommonService.getCommonLookups(
			ForObjectType.INVENTORY_ITEM,
			[LookupType.STATUS]
		);
		saveDataToSessionStorage(ForObjectType.INVENTORY_ITEM, responseInv);

		const responseCatalog = await CommonService.getCommonLookups(
			ForObjectType.CATALOG,
			[LookupType.STATUS, LookupType.CATALOG_TYPE]
		);
		saveDataToSessionStorage(ForObjectType.CATALOG, responseCatalog);

		const responseOrder = await CommonService.getCommonLookups(
			ForObjectType.ORDER,
			[LookupType.STATUS]
		);
		saveDataToSessionStorage(ForObjectType.ORDER, responseOrder);




	} catch (error) {
		console.error('Failed to fetch common lookups:', error);
	}
};

