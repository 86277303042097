import {
  GoABadge,
  GoABlock,
  GoAButton,
  GoAButtonGroup,
  GoACheckbox,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAModal,
  GoAPagination,
  GoASpacer,
  GoATable,
  GoATableSortHeader,
} from "@abgov/react-components";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import moment from "moment";
import { PartService } from "../../services/PartService";
import { Part } from "../../model/Part";
import { useRecoilState } from "recoil";
import { partsState } from "../../states/PartService.state";
import { CalloutVariant, ForObjectType } from "../../model/enums/Constants";
import { CommonLookup } from "../../model/Common";
import { loadCommonLookups, unitOptionsState } from "../../states/Common.state";
import SearchInput from "../../components/searchInput";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import CatalogItemDetailView from "./CatalogItemView";
import { getDataFromSessionStorage } from "../../utils/SessionStorageUtils";
import { hasResourceRole } from "../../utils/tokens";
import { permissions } from "../../common/constants";
import { useAuth } from "react-oidc-context";

interface ICatalogProps { }

const CatalogItemsList: FunctionComponent<ICatalogProps> = (
  props
) => {
  // Navigation
  const navigate = useNavigate();
  //Loader
  const [loading, setIsLoading] = useState(true);
  //Search
  const [searchValue, setSearchValue] = useState("");
  const [parts, setParts] = useRecoilState<Part[]>(partsState);
  const [unitOptions, setUnitOptions] = useRecoilState<{ id: number; value: string }[]>(unitOptionsState);

  // page number
  const [page, setPage] = useState(1);

  //count per page
  const [perPage, setPerPage] = useState(25);

  const [catalogTotalCount, setTotalCount] = useState(0);

  //Sorting
  const [sortCol, setSortCol] = useState("lastUpdatedDate");
  const [sortDir, setSortDir] = useState(0);

  const { id } = useParams();
  const { name } = useParams();
  const { catalogType } = useParams();
  const { status } = useParams();

  const [catalogStatus, setCatalogStatus] = useState("Active");
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null); // State to track expanded row

  const [showDeleteModal, setShowDeleteModal] =
    React.useState(false);

  const [deleteConfirm, setDeleteConfirm] =
    React.useState(false);


  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [showNotificationMessage, setShowNotificationMessage] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({}); // Define errors as Record<string, string>
  const auth = useAuth();

  const getParts = useCallback(async () => {
    try {
      setIsLoading(true);
      if (status) {
        const sessionDataCatalog = getDataFromSessionStorage(ForObjectType.CATALOG);
        if (!sessionDataCatalog)
          loadCommonLookups();

        const statusOptions = sessionDataCatalog.find((x: CommonLookup) => x.name === 'status').elements;
        setCatalogStatus(statusOptions?.find((s: any) => s.id === Number(status)).value);
      }

      if (id && catalogType) {

        const response = await PartService.getAll(searchValue, id, "1", page, perPage, sortCol, sortDir === 1 ? "ASC" : "DESC");
        if (response)
          setParts(p => {
            setTotalCount(response?.data?.pageInfo?.total ?? 0)
            return response.data.parts;
          });

        const sessionDataPart = getDataFromSessionStorage(ForObjectType.PART);
        if (!sessionDataPart)
          loadCommonLookups();


        const unitData = sessionDataPart.find((x: CommonLookup) => x.name === 'unit');

        if (unitData) {
          setUnitOptions(unitData.elements);
        };

        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching catalogs:', error);
      setIsLoading(false);
    }

  }, [id, catalogType, searchValue, page, perPage, sortCol, sortDir, setParts, setUnitOptions, status]);

  useEffect(() => {
    getParts();
  }, [getParts]);

  function searchParts(searchParamValue: string) {
    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setPage(1);
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }

  function setCheckItem(checked: boolean, index: number) {
    const updatedParts = parts.map((part, i) =>
      i === index ? { ...part, isChecked: checked } : part
    );
    setParts(updatedParts);
  }


  function setCheckAllCatalog(checked: boolean) {
    const partsCopy = parts?.map((record: Part) => ({
      ...record,
      isChecked: checked
    }));

    setParts(partsCopy);
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  function changePerPage(name: any, value: any) {
    if (value) {
      const newPerPage = parseInt(value, 10);
      const offset = (page - 1) * newPerPage;
      const _parts = parts?.slice(
        offset,
        offset + newPerPage
      );

      setParts(_parts);
      setPerPage(p => {
        return newPerPage;
      });
      setPage(page);
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    if (newPage) {
      setPerPage(perPage);
      setPage(newPage);
    }
  }

  const clearSearch = () => {
    setSearchValue('');
  };

  const toggleRowExpand = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const deleteItems = () => {
    setDeleteConfirm(false);
    setShowDeleteModal(true);
  }

  const confirmDeleteItems = async () => {
    if (!validateForm('delete'))
      return;

    const partsToDelete = parts.filter((part: Part) => part.isChecked);
    const results = await PartService.deleteAll(partsToDelete);
    setShowDeleteModal(false);

    if (Array.isArray(results)) {
      const allSuccessful = results.every(result => result.success);
      const firstError = results.find(result => !result.success);

      if (allSuccessful) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("All parts deleted successfully!");
        getParts();
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(`Failed to delete some parts: ${firstError}`);
      }
    } else {
      setCalloutVariant(CalloutVariant.EMERGENCY);
      setNotificationMessage(`Failed to delete parts: ${results.error}`);
    }

    setShowNotificationMessage(true);

  };

  function validateForm(name: string, value: string | string[] = '') {
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if ((name === 'delete' && !deleteConfirm) || (name === "deleteConfirm" && value !== "checked")) {
      newErrors['deleteConfirm'] = 'required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  const canCreateUpdateCatalogItem = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_ITEM_WRITE,
    auth.user?.access_token ?? ""
  );

  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => navigate(`/catalogueList`)}>Catalogues</GoAButton>
            <GoAButton type="tertiary" onClick={() => navigate(``)}>Items</GoAButton>
          </div>
        </div>

        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title={`Items for ${name}`} />
          </div>
          <div className="right width50 paddingLeft20px input-search align-right">
            {canCreateUpdateCatalogItem && <GoAButton disabled={catalogStatus === 'Inactive'} type="primary" trailingIcon="add" onClick={() => {navigate(`/catalogItemDetail/${name}/${catalogType}/${id}/${catalogStatus}/new`);window.location.reload();}}>Create item</GoAButton>}
          </div>
        </div>

        <div className="command-bar">
          <div className="left width-auto header-button form-row">
            <div className="item-space">
              {canCreateUpdateCatalogItem && <GoAButton size="compact" type="tertiary" onClick={deleteItems} disabled={parts?.filter(x => x.isChecked).length === 0}>
                Delete Item(s)
              </GoAButton>}
            </div>
            {/* <div>
              Catalogue:
              <GoAButton size="compact" trailingIcon="open" type="tertiary" onClick={() => navigate(`/catalogDetail/${id}`)}>
                {name}
              </GoAButton>

            </div> */}
          </div>
          <div className="right width50 paddingLeft20px input-search">
            <SearchInput label="" search={searchParts} clearSearch={clearSearch} placeholder="Search by name, imis id, part #"></SearchInput>
          </div>
        </div>

        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                {canCreateUpdateCatalogItem && <th className="check-item">
                  <GoACheckbox
                    name="check"
                    text=""
                    value="12"
                    checked={false}
                    onChange={(name, checked, value) => {
                      setCheckAllCatalog(checked);
                    }}
                  />
                </th>}
                <th>
                  <GoATableSortHeader name="Name">
                    Name
                  </GoATableSortHeader >
                </th>
                <th> <GoATableSortHeader name="imisId">
                  IMIS ID
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="code">
                  Part #
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="forEquipment">
                  Use
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="status">
                  Status
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="unit">
                  Unit of measure
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="altPartNumber">
                  Alt part #
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="price">
                  Cost
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="createdDate">
                  Created on
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="lastUpdatedDate">
                  Last updated
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="lastUpdatedBy">
                  Last updated by
                </GoATableSortHeader ></th>
                <th>
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {parts?.map((record: Part, index: any) => (
                <React.Fragment key={index}>
                  <tr key={record.id}>
                    {canCreateUpdateCatalogItem && <td className="check-item">
                      <GoACheckbox
                        name="isChecked"
                        text=""
                        value={record.isChecked}
                        checked={record.isChecked}
                        onChange={(name, checked, value) => {
                          setCheckItem(checked, index);
                        }}
                      /></td>}
                    <td>
                      {record.name}
                    </td>
                    <td>{record.imisId}</td>
                    <td>{record.code}</td>
                    <td>{record.forEquipment}</td>
                    <td>{record.status === 1 ? <GoABadge type="success" content="Active" /> : (record.status === 2 ? <GoABadge type="emergency" content="Inactive" /> : <GoABadge type="important" content="Obsolete" />)}</td>
                    <td>{unitOptions?.find(u => u.id === record.unit)?.value}</td>
                    <td>{record.altPartNumber?.map((altPart: any) => altPart.partNumber)?.join(', ')}</td>
                    <td>{record.price.amount}</td>
                    <td>{moment(record.auditTrail.createdDate).format("yyyy-MM-DD")}</td>
                    <td>{moment(record.auditTrail.lastUpdatedDate).format("yyyy-MM-DD") !== '0001-01-01' ? moment(record.auditTrail.lastUpdatedDate).format("yyyy-MM-DD") : ''}</td>
                    <td>{record.auditTrail.lastUpdatedBy}</td>
                    <td>
                    {(name && catalogStatus !== "Inactive" && canCreateUpdateCatalogItem) ? <div className='align-right'><GoAButton type="tertiary" trailingIcon="pencil" onClick={() => navigate(`/catalogItemDetail/${name}/${catalogType}/${id}/update/${record.id}`)}>
                    </GoAButton></div> : ''}
                    </td>
                    <td>
                      <div className="flex">
                        <GoAButton
                          size="compact"
                          type="tertiary"
                          disabled={!record.id}
                          onClick={() => toggleRowExpand(index)}
                        >
                          <goa-icon type={(expandedRowIndex === index) ? "chevron-down" : "chevron-forward"}></goa-icon>
                        </GoAButton>
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={14}><CatalogItemDetailView catalogId={id} catalogName={name} catalogType={catalogType}
                        id={record.id} editable={catalogStatus !== "Inactive" && canCreateUpdateCatalogItem} /></td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </GoATable>
          <div className={catalogTotalCount > 0 ? "visible pagination pagination-div" : "not-visible pagination"}>
            <GoABlock alignment="center">
              <GoABlock mb="m" alignment="center" gap="m">
                <span style={{ whiteSpace: "nowrap" }}>Show</span>
                {catalogTotalCount && <div className="dropdown-list">
                  <GoADropdown name="selPerPage" onChange={changePerPage} value={catalogTotalCount < 25 ? catalogTotalCount.toString() : "25"} width="8ch">
                    {catalogTotalCount < 25 && <GoADropdownItem value={catalogTotalCount.toString()}></GoADropdownItem>}
                    <GoADropdownItem value="25"></GoADropdownItem>
                    <GoADropdownItem value="50"></GoADropdownItem>
                    <GoADropdownItem value="100"></GoADropdownItem>
                  </GoADropdown>
                </div>}
                <span style={{ whiteSpace: "nowrap" }}>
                  of {catalogTotalCount} items
                </span>
              </GoABlock>
              <GoASpacer hSpacing="fill" />
              <GoAPagination
                itemCount={catalogTotalCount || 10}
                perPageCount={perPage}
                pageNumber={page}
                onChange={changePage}
              />
            </GoABlock>

            <GoAModal heading="Delete item" open={showDeleteModal} maxWidth="25vw"
              actions={
                <GoAButtonGroup alignment="end">
                  <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowDeleteModal(false); }}>Cancel</GoAButton>
                  <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={confirmDeleteItems}>Delete</GoAButton>
                </GoAButtonGroup>
              }
            >
              <div>
                Are you sure you want to delete the {parts?.filter(x => x.isChecked).length} item
                {parts?.filter(x => x.isChecked).length > 1 && 's'} (
                {parts?.filter(x => x.isChecked).map((x, index) => (
                  <strong key={index}>
                    {x.name}
                    {index < parts.filter(y => y.isChecked).length - 1 && ', '}
                  </strong>
                ))})?
              </div>

              <div className='input-form-item'>
                <GoAFormItem error={errors['deleteConfirm']}>
                  <GoACheckbox
                    name="deleteConfirm"
                    text={`Yes, I am sure.`}
                    checked={deleteConfirm}
                    onChange={(name, checked, value) => {
                      validateForm(name, value);
                      setDeleteConfirm(checked);
                    }}
                  />
                </GoAFormItem>
              </div>

            </GoAModal>

            <GoAModal
              heading={"Delete parts"}
              calloutVariant={calloutVariant}
              open={showNotificationMessage}
              actions={
                <GoAButtonGroup alignment="end">
                  <GoAButton onClick={() => {
                    setShowNotificationMessage(false);
                  }}>

                    Okay
                  </GoAButton>
                </GoAButtonGroup>
              }
            >
              <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
            </GoAModal>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogItemsList;
