export interface IWorkOrderPartItem {
  id: string | undefined
  locationId: string | null;
  locationName: string;
  catalogItemId: string | null;
  locationItemId: string | null;
  imisId: number | undefined;
  isChecked: boolean;
  quantity: number | undefined;
  partNumber: string;
  altPartNumber: string;
  itemName: string;
  isUsedPart: boolean;
  unitCost: number | undefined;
  partItemCost: number | undefined;
  isDeleted: boolean;
}

export class WorkOrderPartItem implements IWorkOrderPartItem {
  constructor(
    public id: string = '00000000-0000-0000-0000-000000000000',
    public locationId: string | null = null,
    public locationName: string = "",
    public catalogItemId: string | null = null,
    public locationItemId: string | null = null,
    public imisId: number | undefined = 0,
    public isChecked: boolean = false,
    //public quantityOnHand: number = 0,
    public quantity: number | undefined = 1,
    public partNumber: string = "",
    public altPartNumber: string = "",
    public itemName: string = "",
    public isUsedPart: boolean = false,
    public unitCost: number | undefined = 0,
    public partItemCost: number | undefined = 0,
    public isDeleted: boolean = false
  ) {}
}
