import {
  GoAButton,
  GoAButtonGroup,
  GoACheckbox,
  GoAContainer,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAInput,
  GoAModal,
  GoATable,
} from "@abgov/react-components";
import { useEffect, useState } from "react";
import { WorkOrder } from "../../../model/WorkOrder";
//import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { LocationService } from "../../../services/LocationService";
import { ItemLocation } from "../../../model/Location";
//import PageLoader from "../../../components/PageLoader";
import Select, { InputActionMeta } from "react-select";
import { useRecoilState } from "recoil";
import {altPartNumbersState, partsLocationSelectedState, partsLocationState, workOrderPartsLocationItemsState, workOrderState, workOrderValidationState } from "../../../states/WorkOrder.state";
import { IWorkOrderLabourItem, WorkOrderLabourItem } from "../../../model/WorkOrderLabour";
import { WorkOrderService } from "../../../services/WorkOrderService";
import { WorkOrderPartItem } from "../../../model/WorkOrderPart";
import { FieldChange, SelectOptions } from "../../../model/Base";
import { hasResourceRole } from "../../../utils/tokens";
import { permissions, VALIDATION_WORKORDER_TAB_MAIN, VALIDATION_WORKORDER_TAB_PARTSLABOURS, WORKORDER_STATUS_COMPLETED } from "../../../common/constants";
import { useAuth } from "react-oidc-context";
import { mapOTFactorDesc } from "../../../common/functions";
import { Autocomplete, TextField } from "@mui/material";
import { CalloutVariant } from "../../../model/enums/Constants";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader";
import { toDateString } from "../../../common/date";

interface IWorkOrderPartsAndLabourTabProps {
  workOrderId?: string;
  setFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
  formChanged:boolean;
  setShowCancelModal?:React.Dispatch<React.SetStateAction<boolean>>;
  isHomePage?:boolean;
}

const WorkOrderPartsAndLabourTab: React.FC<IWorkOrderPartsAndLabourTabProps> = (props) => {
  const {  formChanged, setFormChanged,setShowCancelModal, isHomePage } = props;
  const MAX_INPUT_LENGTH = 300;
  const [formWorkOrder, setWorkOrder] = useRecoilState<WorkOrder>(workOrderState);
  const [partsLocationOptions, setPartsLocationOptions] =
    useRecoilState<SelectOptions[]>(partsLocationState);
  const [selectedPartsLocation, setSelectedPartsLocation] =
    useRecoilState<SelectOptions>(partsLocationSelectedState);
  const [checkAllParts, setCheckAllParts] = useState<boolean>(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState<boolean>(true);
 // const [statusOptions, setStatusOptions] = useState<{ id: number; value: string }[]>();

  const [checkAllLabours, setCheckAllLabours] = useState<boolean>(false);
  const [errors, setErrors] = useRecoilState<Record<string, string>>(workOrderValidationState); 
  const [workOrderPartsLocationItems, setWorkOrderPartsLocationItems] = useRecoilState<ItemLocation[]>(workOrderPartsLocationItemsState);
  const [altPartNumbers, setAltPartNumbers] = useRecoilState<ItemLocation[]>(altPartNumbersState);
  const [labourItemOptions, setLabourItemOptions] = useState<any[]>([]);
  const [labourItemOptionsFiltered, setlabourItemOptionsFiltered] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [fieldToUpdateOnWarningConfirmation, setFieldToUpdateOnWarningConfirmation] = useState<FieldChange>();

  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [modalHeading] = useState("");
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [showDeleteWorkOrderWarning, setShowDeleteWorkOrderWarning] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    const debouncedFetchLabourData = debounce(async () => {
      try {
        const data = await WorkOrderService.searchLabours('');
        setLabourItemOptions(data?.labours);
  
        const dataFiltered =
          data?.labours
            ?.filter((item: IWorkOrderLabourItem) => {
              if (!item.serviceCode) return false;
  
              return !formWorkOrder.labourItems?.some(
                (formItem) =>
                  formItem.labourItemId === item.labourItemId && !formItem.isDeleted
              );
            })
            .map((item: IWorkOrderLabourItem) => ({
              value: item.labourItemId,
              label: item.serviceCode,
            })) || [];
  
        setlabourItemOptionsFiltered(dataFiltered);
      } catch (error) {
        console.error('Error fetching labour data:', error);
      }
    }, 300); 
  
    debouncedFetchLabourData();
  
    // Cleanup debounce on unmount
    return () => {
      debouncedFetchLabourData.cancel();
    };
  }, [formWorkOrder.labourItems]);  
  

  
const getItemName = (name: string, imisId: string, code: string) => {
  //${item.name} (${item.imisId}-${item.code})
  if(!name && !imisId && !code){
    return "";
  }
  return (`${name} [${imisId}] - ${code}`);
};

const filteredPartItemOptions : SelectOptions[] = workOrderPartsLocationItems ? workOrderPartsLocationItems 
  .filter(item => item.name !== null && !formWorkOrder.partItems?.some(formItem => formItem.catalogItemId === item.catalogItemId && !formItem.isDeleted))
  .map(item => ({
    value: item.catalogItemId,
    label: getItemName(item.name, item.imisId?.toString()??"", item.code)
  })) : [];

  const filteredAltPartNumberOptions : SelectOptions[] = altPartNumbers ? altPartNumbers 
  .filter(item => item.altPartNumber && !formWorkOrder.partItems?.some(formItem => formItem.catalogItemId === item.catalogItemId && !formItem.isDeleted))
  .map(item => ({
    value: item.catalogItemId??"",
    label: item.altPartNumber??""
  })) : [];

 
  const handleFieldChange = async (name: string, value: any, index: number = -1) => {
    setFormChanged(true);
    if (index >= 0 && formWorkOrder?.partItems && formWorkOrder?.partItems[index]) {
      const updatedPartItems = [...(formWorkOrder?.partItems ?? [])];
      if(name === 'itemName'){
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          itemName: value,
          locationId: selectedPartsLocation.value,
          locationName: selectedPartsLocation.label,
          catalogItemId: null,
          locationItemId: null
        }
      }
      if(name === 'partNumber'){
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          partNumber: value,
          locationId: selectedPartsLocation.value,
          locationName: selectedPartsLocation.label,
          catalogItemId: updatedPartItems[index].catalogItemId,
          locationItemId: updatedPartItems[index].locationItemId,
        }
      }
      if(name === 'altPartNumber'){
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          altPartNumber: value,
          locationId: selectedPartsLocation.value,
          locationName: selectedPartsLocation.label,
          catalogItemId: updatedPartItems[index].catalogItemId,
          locationItemId: updatedPartItems[index].locationItemId,
        }
      }
      if (name === 'qty') {
        const unitPrice = updatedPartItems[index].unitCost;
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          quantity: value,
          partItemCost: (value) * (unitPrice ?? 0)
        }
      }
      if (name === 'unitCost') {
        const quantity = updatedPartItems[index].quantity;
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          unitCost: value,
          partItemCost: (value) * (quantity ?? 0)
        }
      }
      if (name === 'isUsedPart') {
        var itemUnitCost;
        if (!value) {
          const itemsData = await LocationService.getById(selectedPartsLocation.value)
          itemUnitCost = itemsData?.data?.items?.find((l: ItemLocation) => l.catalogItemId === updatedPartItems[index].catalogItemId)?.unitPrice
        }
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          isUsedPart: value,
          unitCost: value ? 0 : itemUnitCost,
          partItemCost: value ? 0 : (updatedPartItems[index]?.quantity ?? 0) * (itemUnitCost ?? 0)
        }
      }

      const finalPartsCost = updatedPartItems?.filter(i => !i.isDeleted)?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0)
      }, 0);
      const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
      const totalCost = (finalPartsCost ?? 0) + (formWorkOrder.labourCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;

      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedPartItems,
        partsCost: finalPartsCost ?? 0,
        shopSupplyCost: shopSupplyCost,
        totalCost: totalCost
      });

     setErrors({});
    }
  };

  const loadLocationOptions = debounce(
    async (inputValue: string, callback: (data: any[]) => void) => {
      if (!inputValue) return;
      const data = await LocationService.getList(inputValue);
      callback(data);
    },
    500
  );

  const populateLocationItems = async(locationId: string, search: string = "") => {
    const itemsData = await LocationService.getById(locationId, search);
    if(itemsData.data && itemsData.data.items){
     setWorkOrderPartsLocationItems(itemsData.data.items);

     const uniqueAltPartNumbers = itemsData.data.items.filter((value, index, self) =>
      value.altPartNumber && (index === self.findIndex((t) => t.altPartNumber === value.altPartNumber))
     );
     setAltPartNumbers(uniqueAltPartNumbers);
    }
  }

useEffect(() => {
  if (firstTimeLoad) {
    loadLocationOptions("WSC1", (result) => {
    setPartsLocationOptions(result);
    let locationId = process.env.REACT_APP_IMIS_WORKORDER_PARTS_DEFAULTLOCATION_VALUE?.toLowerCase();
    if(formWorkOrder.partItems && formWorkOrder.partItems[0]?.locationId){
      locationId = formWorkOrder.partItems[0].locationId.toLowerCase();
    }
    setSelectedPartsLocation(result.find(i => i.value.toLowerCase() === locationId));
    populateLocationItems(result.find(i => i.value.toLowerCase()  === locationId)?.value.toLowerCase(), "");
    setFirstTimeLoad(false);
  });
}
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [setSelectedPartsLocation,firstTimeLoad]);


  function setCheckAllPartItems(checked: boolean) {
    setCheckAllParts(true);
    const updatedItems = formWorkOrder?.partItems?.map((record: WorkOrderPartItem) => ({
      ...record,
      isChecked: checked
    }));

    if (formWorkOrder) {
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedItems,
      });
    }
  }

  function setCheckAllLabourItems(checked: boolean) {
    setCheckAllLabours(true);
    const updatedItems = formWorkOrder?.labourItems?.map((record: WorkOrderLabourItem) => ({
      ...record,
      isChecked: checked
    }));

    if (formWorkOrder) {
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedItems,
      });
    }
  }

  function handleAddPartItemClick(): void {
    if (formWorkOrder) {
      const newItem = new WorkOrderPartItem();

      // Spread the existing items and add the new item
      const updatedItems = [...(formWorkOrder.partItems || []), newItem];

      // Update the form state with the new items array
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedItems,
      });
    }
  }

  function handleAddLabourItemClick(): void {
    
    if (formWorkOrder) {

      const newItem = new WorkOrderLabourItem();
      // Spread the existing items and add the new item
      const updatedItems = [...(formWorkOrder.labourItems || []), newItem];

      // Update the form state with the new items array
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedItems,
      });
    }
  }

  const handlePartItemChange = (field: string, item: any, index: number) => {
      setFormChanged(true);
      const selectedItem = (field === "itemName" ? (workOrderPartsLocationItems?.find(
        (l: ItemLocation) => l.catalogItemId === item?.value
      )) : (altPartNumbers?.find(
        (l: ItemLocation) => l.catalogItemId === item?.value
      )));
      const updatedFormState = [...(formWorkOrder?.partItems ?? [])];
      const quantity = 1;
      updatedFormState[index] = {
        ...updatedFormState[index],
        partItemCost:quantity*(selectedItem?.unitPrice ?? 0),
        catalogItemId: item?.value,
        locationItemId: selectedItem?.id??null,
        imisId: selectedItem?.imisId??0,
        quantity: quantity,
        partNumber: selectedItem?.code ?? "",
        altPartNumber:  (field === "itemName" ? selectedItem?.altPartNumber : (field === "altPartNumber" ? item?.label : (item??""))),
        itemName: (field === "itemName" ? item?.label : (field === "altPartNumber" ? getItemName(selectedItem?.name??"", selectedItem?.imisId?.toString()??"", selectedItem?.code??"") : (item??""))),
        unitCost: selectedItem?.unitPrice ?? 0,
        locationId: selectedItem?.locationId ?? (selectedPartsLocation.value ?? undefined),
        locationName: selectedPartsLocation.label,
        isUsedPart: false,
      };
  
      const finalPartsCost = updatedFormState.filter(i => !i.isDeleted)?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0)
      }, 0);
      
      const totalCost = (finalPartsCost ?? 0) + (formWorkOrder.labourCost ?? 0) + (formWorkOrder.subletCost ?? 0) + formWorkOrder.shopSupplyCost;
  
      // Update the state with the modified array
      if (formWorkOrder) {
        setWorkOrder({
          ...formWorkOrder,
          partItems: updatedFormState,
          partsCost: finalPartsCost ?? 0,
          totalCost: totalCost
        });
      }
  };

  const finalPartsCost = formWorkOrder?.partItems.filter(i => !i.isDeleted)?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
    return (accumulator ?? 0) + (currentValue ?? 0)
  }, 0);

  const finalLabourCost = formWorkOrder?.labourItems?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
    return (accumulator ?? 0) + (currentValue ?? 0)
  }, 0);

  const onLocationSearchChange = (
    inputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change") {
      loadLocationOptions(inputValue, (result: any) => {
        setPartsLocationOptions(result);
      });
    }
  };

  const handleLocationChange = (selectedPartsLocation: any) => {
    setSelectedPartsLocation(selectedPartsLocation);
    //Load all the part items under that location
    populateLocationItems(selectedPartsLocation.value);
    const updatedPartItems = [...formWorkOrder.partItems ?? []];
    updatedPartItems?.forEach((value, index) => {
      updatedPartItems[index] = {
        ...updatedPartItems[index],
        isDeleted: true
      }
    });

    setWorkOrder({
      ...formWorkOrder,
      partItems: updatedPartItems
    });
  };



 
  function deletePartItem() {
    // Update form change state
    // setFormChanged(true);
    setCheckAllParts(false);

    if (formWorkOrder) {
      // Filter out the items that are checked
      const activeItems = formWorkOrder.partItems?.filter(item => !item.isChecked);
      const finalPartsCost = activeItems?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0)
      }, 0);
      const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
      const totalCost = (finalPartsCost ?? 0) + (formWorkOrder.labourCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;

      //mark checked items as deleted
      const updatedItems = [...formWorkOrder.partItems ?? []];

      updatedItems.forEach((item, index, arr) => {
        arr[index] = {
          ...arr[index],
          isDeleted: item.isChecked
        }
      });

      // Update the state with the modified items array
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedItems,
        partsCost: finalPartsCost ?? 0,
        shopSupplyCost: shopSupplyCost,
        totalCost: totalCost
      });
    }
  }

  function deleteLabourItem() {
    setFormChanged(true);
    setCheckAllLabours(false);

    if (formWorkOrder) {
      // Filter out the items that are checked
      const activeItems = formWorkOrder.labourItems?.filter(item => !item.isChecked);

      const finalLabourCost = activeItems?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0);
      }, 0);

      const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
      const totalCost = (finalLabourCost ?? 0) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;

      //mark checked items as deleted
      const updatedItems = [...formWorkOrder.labourItems ?? []];

      updatedItems.forEach((item, index, arr) => {
        arr[index] = {
          ...arr[index],
          isDeleted: item.isChecked
        }
      });

      // Update the state with the modified items array
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedItems,
        labourCost: finalLabourCost ?? 0,
        shopSupplyCost:shopSupplyCost??0,
        totalCost: totalCost
      });

       
    }
  }


  function setCheckPartItem(checked: boolean, index: number): void {
    //setFormChanged(true);
    if (formWorkOrder) {
      const updatedPartItems = [...formWorkOrder.partItems ?? []];
      updatedPartItems[index] = {
        ...updatedPartItems[index],
        isChecked: checked
      }

      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedPartItems
      });
    }
  }

  function setCheckLabourItem(checked: boolean, index: number): void {
    //setFormChanged(true);
    if (formWorkOrder) {
      const updatedLabourItems = [...formWorkOrder.labourItems ?? []];
      updatedLabourItems[index] = {
        ...updatedLabourItems[index],
        isChecked: checked
      }

      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedLabourItems
      });
    }
  }

  
  const handleFieldChangeLabour = (name: string, item: any, index: number) => {
       const updatedFormState = [...formWorkOrder?.labourItems];
       if (name === 'ServiceCode' && item) {
        updatedFormState[index] = {
          ...updatedFormState[index],
          serviceCode: item,
          labourItemId: '00000000-0000-0000-0000-000000000000'
        };
  
        setWorkOrder({
          ...formWorkOrder,
          labourItems: updatedFormState
        });
       }

       if (name === 'description') {
         updatedFormState[index] = {
           ...updatedFormState[index],
           description: item,
         };
   
         setWorkOrder({
           ...formWorkOrder,
           labourItems: updatedFormState
         });
       }
   
       if (name === 'hours') {
         updatedFormState[index] = {
           ...updatedFormState[index],
           hours: Number(item),
           labourItemCost: Number(item) * (updatedFormState[index]?.hourlyRate ?? 38) * (updatedFormState[index]?.otFactor ?? 1),
         };
   
         const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
           return (accumulator ?? 0) + (currentValue ?? 0)
         }, 0) ?? 0;
   
         const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
         const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;
   
         // Update the state with the modified array
         setWorkOrder({
           ...formWorkOrder,
           labourItems: updatedFormState,
           labourCost: finalLabourCost,
           shopSupplyCost:shopSupplyCost,
           totalCost: totalCost
         });
       }
   
       if (name === 'otFactor') {
         updatedFormState[index] = {
           ...updatedFormState[index],
           otFactor: item,
           otFactorDesc: mapOTFactorDesc(item),
           labourItemCost: (updatedFormState[index]?.hours ?? 0) * (updatedFormState[index]?.hourlyRate ?? 38) * item
         };
   
         const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
           return (accumulator ?? 0) + (currentValue ?? 0)
         }, 0) ?? 0;
   
         const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
         const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;
   
         // Update the state with the modified array
         setWorkOrder({
           ...formWorkOrder,
           labourItems: updatedFormState,
           labourCost: finalLabourCost,
           shopSupplyCost:shopSupplyCost,
           totalCost: totalCost
         });
       }
   
       if (name === 'hourlyRate') {
         updatedFormState[index] = {
           ...updatedFormState[index],
           hourlyRate: item,
           labourItemCost: (updatedFormState[index]?.hours ?? 0) * item * (updatedFormState[index]?.otFactor ?? 1)
         };
   
         
         const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
           return (accumulator ?? 0) + (currentValue ?? 0)
         }, 0) ?? 0;
   
         const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
         const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;
   
         // Update the state with the modified array
         setWorkOrder({
           ...formWorkOrder,
           labourItems: updatedFormState,
           labourCost: finalLabourCost,
           shopSupplyCost:shopSupplyCost,
           totalCost: totalCost
         });
       }
     }

  const handleLabourItemChange = (item: any, index: number) => {
    const updatedFormState = [...formWorkOrder?.labourItems];
      if (formWorkOrder.labourItems?.find(i => i.labourItemId !== item?.value)) {
        const selectedLabour = labourItemOptions.find(l => l.labourItemId === item?.value);
        updatedFormState[index] = {
          ...updatedFormState[index],
          labourItemId: selectedLabour?.labourItemId ?? '00000000-0000-0000-0000-000000000000',
          serviceCode: item?.label??item,
          description: selectedLabour?.description ?? '',
          hours: selectedLabour?.hours ?? 0,
          labourItemCost: (selectedLabour?.hours ?? 0) * (selectedLabour?.hourlyRate ?? 38) * (selectedLabour?.otFactor ?? 1)
        };

        const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
          return (accumulator ?? 0) + (currentValue ?? 0)
        }, 0) ?? 0;

        const shopSupplyCost = (finalLabourCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
        const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;

        // Update the state with the modified array
        setWorkOrder({
          ...formWorkOrder,
          labourItems: updatedFormState,
          labourCost: finalLabourCost,
          shopSupplyCost:shopSupplyCost,
          totalCost: totalCost
        });
      }
  }

  const showWarning = (field: string, newValue: any, previousValue: any, index: number = -1) => {
    setModalOpen(true);
     if(field === "qty"){
      setWarningMessage(`The quantity of the part you entered for this repair is ${newValue}, which exceeds a soft limit of 10 units. Click Yes to accept the value entered or Click No to make a correction.`);
      setFieldToUpdateOnWarningConfirmation(new FieldChange(field, newValue, previousValue, index));
     }
  };

  function validateForm(name: string, value: string = "") {
    setErrors({});
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if (name === "save" && !formWorkOrder?.nonCatalogItem && !formWorkOrder?.unitNo) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "unitNo"] = "Unit # is required";
    }
    if (name === "save" && !formWorkOrder?.itemName) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "itemName"] = "Item name is required";
    }
    if (name === "save" && Number(formWorkOrder.status) === WORKORDER_STATUS_COMPLETED && !formWorkOrder?.workedByUserId) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "workedBy"] = "Worked by is required";
    }
    if (name === "save" && !formWorkOrder?.description) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "problem"] = "Problem is required";
    }
    if (name === "save" && !formWorkOrder?.locationId) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "location"] = "Location is required";
    }
    if (name === "save" && !formWorkOrder?.status) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "status"] = "Status is required";
    }
    if (name === "save" && Number(formWorkOrder?.status) === WORKORDER_STATUS_COMPLETED && !formWorkOrder?.dateIn) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateIn"] = "Date in is required on completing workorder";
    }
    if (name === "save" && Number(formWorkOrder?.status) === WORKORDER_STATUS_COMPLETED && !formWorkOrder?.dateOut) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateOut"] = "Date out is required on completing workorder";
    }
    if (name === "save" && formWorkOrder?.dateIn && formWorkOrder?.dateOut && (toDateString(new Date(formWorkOrder.dateOut)) < toDateString(new Date(formWorkOrder.dateIn)))) {
      newErrors[VALIDATION_WORKORDER_TAB_MAIN + "dateOut"] = "Date out can not be before date in";
    }
    if(formWorkOrder?.partItems){
      formWorkOrder.partItems.forEach((item, index) => {
        if(!item.isDeleted){
          if(!item.quantity){
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index] = "Qty is Required";
          }
          if(item.quantity && item.quantity <= 0) {
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index] = "Qty must be positive";
          }
          if(item.quantity && item.quantity > 0 && (Number(item.quantity) !== parseInt(String(item.quantity)))) {
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index] = "Qty must not be fractional";
          }
          if(!item.partNumber && !item.itemName){
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-itemname-" + index] = "Item name or part number must be provided";
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-partnumber-" + index] = "Item name or part number must be provided";
          }
        }
      });
    }

    if(formWorkOrder?.labourItems){
      formWorkOrder.labourItems.forEach((item, index) => {
        if(!item.isDeleted){
          if(!item.serviceCode && !item.description){
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "labour-item-servicecode-" + index] = "Service code or description must be provided";
            newErrors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "labour-item-description-" + index] = "Service code or description must be provided";
          }
        }
      });
    }

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  const handleSaveWorkOrder = async () => {
    if (!validateForm("save")) {
      console.log("Form contains errors. Cannot submit.");
      return;
    }
    setIsLoading(true);

    try {
      const workOrder = { ...formWorkOrder };
      let result;
      if (workOrder?.id) {
        result = await WorkOrderService.update(workOrder!);
      } else {
        result = await WorkOrderService.create(workOrder!);
      }

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Workorder saved successfully!");
        setShowNotificationMessage(true);
        setFormChanged(false);
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result?.error??"");
        setShowNotificationMessage(true);
      }
    } catch (error) {
      console.error("Error updating or creating workorder:", error);
      alert("An error occurred while updating or creating the workorder");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteWorkOrder = async () => {
    try {
      const result = await WorkOrderService.deleteWO(formWorkOrder?.id ?? "");

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Workorder deleted successfully!");
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result?.error);
      }
      setShowDeleteWorkOrderWarning(false);
      setShowNotificationMessage(true);

    } catch (error) {
      if (error instanceof Error) {
      alert("An error occurred while deleting the workorder: " + error.message);
      }
      console.error("An error occurred while deleting the workorder:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const auth = useAuth();

  const canCreateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_CREATE,
    auth.user?.access_token ?? ""
  );

  const canUpdateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_UPDATE,
    auth.user?.access_token ?? ""
  );

  const canPartiallyUpdateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_UPDATE_MECHANIC,
    auth.user?.access_token ?? ""
  );

  return (
    <>
      {/* <PageLoader visible={loading} /> */}
      <div
        className={`workorder-parts-labour-tab-wrapper ${
          formWorkOrder.status === WORKORDER_STATUS_COMPLETED ? "disabled" : ""
        }`}
      >
        <PageLoader visible={loading} />
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Parts">
          <div
            className={`workorder-parts-container ${
              canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? "" : "disable"
            }`}
          >
            <div>
              <GoAFormItem label="Parts location">
                <Select
                  name="location"
                  options={partsLocationOptions}
                  placeholder="--Select--"
                  className="width100 select-input"
                  isDisabled={false}
                  isSearchable={true}
                  onInputChange={onLocationSearchChange}
                  onChange={(selection, event) => {
                    if (selection?.value !== selectedPartsLocation.value)
                      handleLocationChange(selection);
                  }}
                  value={partsLocationOptions?.find(
                    (i) =>
                      i.value.toUpperCase() ===
                      selectedPartsLocation.value.toUpperCase()
                  )}
                />
              </GoAFormItem>
            </div>
            <div
              style={{
                marginLeft: "-15px",
                marginTop: "30px",
                marginBottom: "-10px",
              }}
            >
              <GoAButton
                type="tertiary"
                disabled={formWorkOrder?.partItems?.length === 0 || formWorkOrder?.partItems?.filter(i => !i.isDeleted)?.length === 0}
                onClick={deletePartItem}
              >
                Delete item(s)
              </GoAButton>
            </div>
            <div>
              <div className="input-form-item">
                <GoAFormItem>
                  {/* error={errors["items"]}> */}
                  <GoATable width="100%">
                    <thead>
                      <tr>
                        <th className="check-item">
                          <GoACheckbox
                            name="check"
                            text=""
                            checked={checkAllParts}
                            onChange={(name, checked, value) => {
                              setCheckAllPartItems(checked);
                            }}
                          />
                        </th>
                        <th>Item name</th>
                        <th>Imis id</th>
                        <th>Qty</th>
                        <th>Part number</th>
                        <th>Alt part number</th>
                        <th>Used part</th>
                        <th>Unit cost</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formWorkOrder?.partItems?.map(
                        (record: WorkOrderPartItem, index: any) =>
                          !record.isDeleted && (
                            <tr key={record.id + index}>
                              <td className="check-item">
                                <GoACheckbox
                                  name="isChecked"
                                  text=""
                                  value={record.isChecked}
                                  checked={record.isChecked}
                                  onChange={(name, checked, value) => {
                                    setCheckPartItem(checked, index);
                                  }}
                                />
                              </td>
                              <td className="width40">
                                <GoAFormItem
                                  error={
                                    errors &&
                                    errors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-itemname-" + index]
                                  }
                                >
                                  <Autocomplete
                                    title={
                                      filteredPartItemOptions?.find(
                                        (x) => x.value === record.catalogItemId
                                      )?.label ??
                                      record.itemName ??
                                      ""
                                    }
                                    freeSolo
                                    options={filteredPartItemOptions}
                                    value={
                                      filteredPartItemOptions?.find(
                                        (x) => x.value === record.catalogItemId
                                      ) ??
                                      record.itemName ??
                                      ""
                                    }
                                    onChange={(event, value) =>
                                      handlePartItemChange(
                                        "itemName",
                                        value,
                                        index
                                      )
                                    }
                                    onInputChange={(event, value) => {
                                      if (event) {
                                        handleFieldChange(
                                          "itemName",
                                          value,
                                          index
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps, // Ensure existing inputProps are preserved
                                          maxLength: MAX_INPUT_LENGTH, // Set the maximum length
                                        }}
                                        variant="outlined"
                                        placeholder="Select or type item name, code or imisId"
                                      />
                                    )}
                                  />
                                </GoAFormItem>
                              </td>
                              <td>
                                <GoAFormItem>
                                  {!record.imisId ||
                                  (record.imisId && record.imisId === 0)
                                    ? ""
                                    : record.imisId}
                                </GoAFormItem>
                              </td>
                              <td>
                                <GoAFormItem
                                  error={
                                    errors &&
                                    errors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-quantity-" + index]
                                  }
                                >
                                  <TextField
                                    type="number"
                                    value={record.quantity??1}
                                    onChange={(e) => {
                                      if(!modalOpen){
                                        let val = !e.currentTarget.value ? 1 : parseInt(e.currentTarget.value);
                                        if (val < 1) {
                                          val = 1;
                                        }
                                        if (val > 10) {
                                          showWarning("qty", val, record.quantity, index);
                                        }
                                        handleFieldChange("qty", val, index);
                                      }
                                    }}
                                    variant="outlined"
                                  />
                                </GoAFormItem>
                              </td>
                              <td>
                                <GoAFormItem
                                  error={
                                    errors &&
                                    errors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-partnumber-" + index]
                                  }
                                >
                                  <GoAInput
                                    error={
                                      (errors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "part-item-partnumber-" + index]
                                        ?.length ?? 0) > 0
                                    }
                                    name="partNumber"
                                    width="200px"
                                    value={record?.partNumber ?? ""}
                                    onChange={(name, value) => {
                                      handleFieldChange(name, value, index);
                                    }}
                                  ></GoAInput>
                                </GoAFormItem>
                              </td>
                              <td>
                                <GoAFormItem>
                                  <Autocomplete
                                    title={
                                      filteredAltPartNumberOptions?.find(
                                        (x) => x.value === record.catalogItemId
                                      )?.label ??
                                      record.altPartNumber ??
                                      ""
                                    }
                                    freeSolo
                                    options={filteredAltPartNumberOptions}
                                    value={
                                      filteredAltPartNumberOptions?.find(
                                        (x) => x.value === record.catalogItemId
                                      ) ??
                                      record.altPartNumber ??
                                      ""
                                    }
                                    onChange={(event, value) =>
                                      handlePartItemChange(
                                        "altPartNumber",
                                        value,
                                        index
                                      )
                                    }
                                    onInputChange={(event, value) => {
                                      if (event) {
                                        handleFieldChange(
                                          "altPartNumber",
                                          value,
                                          index
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps, // Ensure existing inputProps are preserved
                                          maxLength: MAX_INPUT_LENGTH, // Set the maximum length
                                        }}
                                        variant="outlined"
                                        placeholder="Alt part number"
                                        style={{ width: "200px" }}
                                      />
                                    )}
                                  />
                                </GoAFormItem>
                              </td>
                              <td className="centered-cell">
                                <GoAFormItem>
                                  <GoACheckbox
                                    name="usedPart"
                                    text="Used part"
                                    checked={record.isUsedPart}
                                    onChange={(item, checked, value) =>
                                      handleFieldChange(
                                        "isUsedPart",
                                        checked,
                                        index
                                      )
                                    }
                                  />
                                </GoAFormItem>
                              </td>
                              <td>
                                <GoAInput
                                  leadingContent="$"
                                  width="150px"
                                  type="number"
                                  name="unitCost"
                                  value={record?.unitCost?.toString() ?? ""}
                                  onChange={(item, value) =>
                                    handleFieldChange("unitCost", value, index)
                                  }
                                ></GoAInput>
                              </td>
                              <td>${record?.partItemCost?.toFixed(2) ?? ""}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </GoATable>
                </GoAFormItem>
              </div>

              <div className="input-form-item">
                <div className="left">
                  <GoAButton
                    type="secondary"
                    onClick={handleAddPartItemClick}
                    disabled={selectedPartsLocation === undefined}
                  >
                    Add row
                  </GoAButton>
                </div>
                <div className="right">
                  <b>Parts cost: </b>${finalPartsCost?.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </GoAContainer>
        <GoAContainer accent="thick" padding="relaxed" title="Labour">
          <div
            className={`workorder-labour-container ${
              canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? "" : "disable"
            }`}
          >
            <div
              style={{
                marginLeft: "-15px",
                marginTop: "30px",
                marginBottom: "-10px",
              }}
            >
              <GoAButton
                type="tertiary"
                disabled={formWorkOrder?.labourItems?.length === 0}
                onClick={deleteLabourItem}
              >
                Delete item(s)
              </GoAButton>
            </div>
            <div>
              <div className="input-form-item">
                <GoAFormItem>
                  <GoATable width="100%">
                    <thead>
                      <tr>
                        <th className="check-item">
                          <GoACheckbox
                            name="check"
                            text=""
                            checked={checkAllLabours}
                            onChange={(name, checked, value) => {
                              setCheckAllLabourItems(checked);
                            }}
                          />
                        </th>
                        <th>Service code</th>
                        <th>Description</th>
                        <th>Hours</th>
                        <th>OT factor</th>
                        <th>Hourly rate</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formWorkOrder?.labourItems?.map(
                        (record: WorkOrderLabourItem, index: any) =>
                          !record.isDeleted && (
                            <tr key={index}>
                              <td
                                className="check-item"
                                style={{ width: "3%" }}
                              >
                                <GoACheckbox
                                  name="isChecked"
                                  text=""
                                  value={record.isChecked}
                                  checked={record.isChecked}
                                  onChange={(name, checked, value) => {
                                    setCheckLabourItem(checked, index);
                                  }}
                                />
                              </td>
                              <td className="width40">
                                <GoAFormItem
                                  error={
                                    errors &&
                                    errors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "labour-item-servicecode-" + index]
                                  }
                                >
                                  <Autocomplete
                                    title={
                                      labourItemOptionsFiltered?.find(
                                        (x) => x.value === record.labourItemId
                                      )?.label ??
                                      record.serviceCode ??
                                      ""
                                    }
                                    freeSolo
                                    options={labourItemOptionsFiltered}
                                    value={
                                      labourItemOptionsFiltered?.find(
                                        (x) => x.value === record.labourItemId
                                      ) ??
                                      record.serviceCode ??
                                      ""
                                    }
                                    onChange={(event, value) =>
                                      handleLabourItemChange(value, index)
                                    }
                                    onInputChange={(event, value) => {
                                      if (event) {
                                        handleFieldChangeLabour(
                                          "ServiceCode",
                                          value,
                                          index
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps, // Ensure existing inputProps are preserved
                                          maxLength: MAX_INPUT_LENGTH, // Set the maximum length
                                        }}
                                        variant="outlined"
                                        placeholder="Select or type item name, code or imisId"
                                      />
                                    )}
                                  />
                                </GoAFormItem>
                              </td>

                              <td className="width20">
                              <GoAFormItem
                                  error={
                                    errors &&
                                    errors[VALIDATION_WORKORDER_TAB_PARTSLABOURS + "labour-item-description-" + index]
                                  }
                                >
                              <GoAInput width="100%"
                                name="description"
                                value={record?.description ?? ""}
                                onChange={(item, value) =>
                                  handleFieldChangeLabour("description", value, index)
                                }
                              ></GoAInput>
                            </GoAFormItem>
                              </td>
                              <td className="width10">
                                <GoAFormItem>
                                  <input
                                    type="number"
                                    onChange={(e) => {
                                      handleFieldChangeLabour(
                                        "hours",
                                        e.currentTarget.value,
                                        index
                                      );
                                    }}
                                    value={record.hours?.toString() ?? "00"}
                                    className={"input-common"}
                                    name="hours"
                                    min={1}
                                  />
                                </GoAFormItem>
                              </td>
                              <td className="width10">
                                <GoADropdown
                                  name="otFactor"
                                  value={record.otFactor?.toString()}
                                  onChange={(name, value) =>
                                    handleFieldChangeLabour(
                                      "otFactor",
                                      value,
                                      index
                                    )
                                  }
                                >
                                  <GoADropdownItem
                                    value="1"
                                    label={mapOTFactorDesc(1)}
                                  ></GoADropdownItem>
                                  <GoADropdownItem
                                    value="1.5"
                                    label={mapOTFactorDesc(1.5)}
                                  ></GoADropdownItem>
                                  <GoADropdownItem
                                    value="2"
                                    label={mapOTFactorDesc(2)}
                                  ></GoADropdownItem>
                                </GoADropdown>
                              </td>
                              <td className="width10">
                                <input
                                  disabled={!canUpdateWorkOrder}
                                  type="number"
                                  onChange={(e) => {
                                    handleFieldChangeLabour(
                                      "hourlyRate",
                                      Number(e.currentTarget.value),
                                      index
                                    );
                                  }}
                                  value={record.hourlyRate?.toString() ?? "38"}
                                  className={"input-common"}
                                  width="131px"
                                  name="hourlyRate"
                                  min={1}
                                />
                              </td>
                              <td>${record.labourItemCost?.toFixed(2)}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </GoATable>
                </GoAFormItem>
              </div>

              <div className="input-form-item">
                <div className="left">
                  <GoAButton
                    type="secondary"
                    onClick={handleAddLabourItemClick}
                  >
                    Add row
                  </GoAButton>
                </div>
                <div className="right">
                  <b>Labour cost: </b>${finalLabourCost?.toFixed(2) ?? ""}
                </div>
              </div>
            </div>
          </div>
        </GoAContainer>
        <GoAModal
          calloutVariant="important"
          heading={warningMessage}
          open={modalOpen}
          onClose={() => {
            handleFieldChange(
              fieldToUpdateOnWarningConfirmation?.name ?? "",
              fieldToUpdateOnWarningConfirmation?.previousValue,
              fieldToUpdateOnWarningConfirmation?.index
            );
            setModalOpen(false);
          }}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton type="secondary"
               onClick={() => {
                handleFieldChange(
                  fieldToUpdateOnWarningConfirmation?.name ?? "",
                  fieldToUpdateOnWarningConfirmation?.previousValue,
                  fieldToUpdateOnWarningConfirmation?.index
                );
                setModalOpen(false);
              }}
                >
                No
              </GoAButton>
              <GoAButton
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Yes
              </GoAButton>
            </GoAButtonGroup>
          }
        ></GoAModal>
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <GoAButtonGroup alignment="start">
          <GoAButton type="primary" onClick={handleSaveWorkOrder}>
            Save work order
          </GoAButton>
          <GoAButton
            type="secondary"
            onClick={() => {
                if (formChanged && setShowCancelModal)
                  setShowCancelModal(true);
                else if (isHomePage)
                  navigate("/home");
                else
                navigate("/workOrderList");
            }}
          >
            Cancel
          </GoAButton>
          <div
            className={`delete-workorder-button ${!(formWorkOrder.workOrderNo && canCreateWorkOrder) ? "hide" : ""
              }`}
          >
            <GoAButton
              type="primary"
              variant="destructive"
              onClick={() => setShowDeleteWorkOrderWarning(true)}
            >
              Delete work order
            </GoAButton>
          </div>
        </GoAButtonGroup>
      </div>
      <GoAModal
        heading={modalHeading}
        calloutVariant={calloutVariant}
        open={showNotificationMessage}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton
              onClick={() => {
                setShowNotificationMessage(false);
                if (calloutVariant === CalloutVariant.SUCCESS) {
                  if (isHomePage)
                    navigate("/home");
                  else
                    navigate("/workOrderList");
                }
              }}
            >
              Okay
            </GoAButton>
          </GoAButtonGroup>
        }
      >        <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
      </GoAModal>
      <GoAModal heading="Are you sure you want to delete this workorder?" open={showDeleteWorkOrderWarning}>
        <GoAButtonGroup alignment="end">
          <GoAButton type="secondary" onClick={() => setShowDeleteWorkOrderWarning(false)}>
            Cancel
          </GoAButton>
          <GoAButton type="primary" onClick={handleDeleteWorkOrder}>
            Confirm
          </GoAButton>
        </GoAButtonGroup>
      </GoAModal>
    </>
  );
};
export default WorkOrderPartsAndLabourTab;
