import {
  GoAAppHeader,
  GoAAppHeaderMenu
} from "@abgov/react-components";
import * as React from "react";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { workOrderListSearchState } from "../states/WorkOrder.state";
import { permissions } from "../common/constants";
import { hasResourceRole, parseToken } from "../utils/tokens";
import { useAuth } from "react-oidc-context";

interface IAppHeaderProps { }

const AppHeader: React.FunctionComponent<IAppHeaderProps> = (props) => {
  const [selectedPage, setSelectedPage] = useState("Home");
  const [workOrderSearchValue, setWorkOrderSearchValue] = useRecoilState(
    workOrderListSearchState
  );
  //const [username, setUsername] = useState("");

  const url = "";
  const autInfo = useAuth();

  useEffect(() => {
    //setUsername(getUserName(autInfo.user?.access_token ?? ""));
    // Extract the pathname from the URL
    const pathname = window.location.pathname;
    // Update selectedPage based on the pathname
    if (pathname === "/catalogueList") {
      setSelectedPage("Catalogues");
    } else if (pathname === "/adjustmentList") {
      setSelectedPage("Consumable adjustments");
    } else if (pathname === "/locationSnapshot") {
      setSelectedPage("Location Snapshot");
    } else if (
      pathname === "/workOrderList" ||
      pathname === "/createWorkOrder"
    ) {
      setSelectedPage("Work orders");
      setWorkOrderSearchValue("");
      console.log(workOrderSearchValue);
    } else if (pathname === "/ordersList") {
      setSelectedPage("Orders");
    }
    else {
      setSelectedPage("Home");
      setWorkOrderSearchValue(process.env.REACT_APP_IMIS_WORKORDER_DEFAULTLOCATION ?? "");
      console.log(workOrderSearchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autInfo.user?.access_token, window.location.pathname]);

  const handleItemClick = (page: string) => {
    setSelectedPage(page);
  };

  const auth = useAuth();

  const canViewWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_VIEW,
    auth.user?.access_token ?? ""
  );
  const canViewCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_VIEW,
    auth.user?.access_token ?? ""
  );
  const canViewConsumableAdjustments = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_ADJUSTMENT_VIEW,
    auth.user?.access_token ?? ""
  );
  const canViewLocations = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_LOCATION_VIEW,
    auth.user?.access_token ?? ""
  );

  const logout = () => {
    if (!auth.isLoading && auth.isAuthenticated) {
      auth.signoutRedirect({ id_token_hint: auth.user?.id_token }).then();
    }
  };

  return (
    <>
      <GoAAppHeader
        url={url}
        heading="Warehouse Management"
      >
        <GoAAppHeaderMenu heading={selectedPage}>
          {canViewWorkOrder && (
            <a href="/home" onClick={() => handleItemClick("WorkOrders")}>
              Home
            </a>
          )}
          {canViewCatalog && (
            <a
              href="/catalogueList"
              onClick={() => handleItemClick("Catalogues")}
            >
              Catalogues
            </a>
          )}
          {canViewConsumableAdjustments && (
            <a
              href="/adjustmentList"
              onClick={() => handleItemClick("Consumable adjustments")}
            >
              Consumable adjustment
            </a>
          )}
          {canViewLocations && (
            <a
              href="/locationSnapshot"
              onClick={() => handleItemClick("Location Snapshot")}
            >
              Location snapshot
            </a>
          )}
          {/* <a
            href="/ordersList"
            onClick={() => handleItemClick("Orders")}
          >
            Orders
          </a> */}
          {canViewWorkOrder && (
            <a
              href="/workOrderList"
              onClick={() => handleItemClick("WorkOrders")}
            >
              Work orders
            </a>
          )}
        </GoAAppHeaderMenu>

        <div className="header-menu">
          {auth.isAuthenticated && (
            <>
              <GoAAppHeaderMenu
                heading={parseToken(auth.user?.id_token as string)?.email}
                leadingIcon="person-circle"
              >
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a onClick={logout}>Log out</a>
                }
              </GoAAppHeaderMenu>
            </>
          )}
        </div>
      </GoAAppHeader>
    </>
  );
};

export default AppHeader;
