import { httpAviationService, httpDomainService } from "../http-service";


const getCorporateRegion = async (objISearch: ISearch) => {
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      columnName: "",
      columnValue: ""
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpDomainService.post<IPaginationResult<ICorporateRegion>>(
    "/corporate_region/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
      },
    }
  );
  
  return data.data;
};


const getFireNumbers = async (search:string) => {
  const currentYear: number = new Date().getFullYear();
  const request: FireNumberSearchRequest = {
    search: search,
    year: currentYear.toString(),
    paginationInfo: {
      perPage: 200000,
      page: 1,
    }
  };
  const params = JSON.stringify(request);

  const response = await httpAviationService.post<ApiResponse>(
    `/fire-number/get`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const extractedFireNumbers = response.data?.data?.map((item: DataItem) => ({ fireNumber: item.fireNumber, year: item.year }));
  return extractedFireNumbers?.sort((a, b) => a.fireNumber.localeCompare(b.fireNumber));
};

interface PaginationInfo {
  perPage: number;
  page: number;
}

export interface FireNumberSearchRequest {
  search: string;
  year: string;
  paginationInfo: PaginationInfo;
}


interface DataItem {
  id: number;
  fireNumber: string;
  year: number;
  carryOver: string;
}

interface ApiResponse {
  status: string;
  errorCodeId: string;
  errorMessage: string;
  paginationInfo: PaginationInfo;
  data: DataItem[];
}


export const ExternalService = {
  getFireNumbers,
  getCorporateRegion
};

export interface IPagination {
  perPage: number,
  page:number,
  totalPages?:number,
  total?:number 
};

export interface IPaginationResult<T>
  {
    status?:string | undefined,
    errorCodeId:string,
    errorMessage:string,
    paginationInfo:IPagination,
    data: T[]
  };


  export interface ICorporateRegion{
    corporateRegionId:string,
    crt_id:string,
    name:string,
    effective_date:string | undefined,
    termination_date?:string | undefined,

    create_userid?:string | undefined,
    create_timestamp?:Date | undefined,
    update_timestamp?:Date | undefined,
    update_userid?: string | undefined,   
} 

export interface IForestArea {
    corporateRegionId:string[],
}
 
 

export interface IFilter{
    columnName: string,
    columnValue: string,
    reportDateFrom?:string | null,
    reportDateTo?:string | null,
    corporateRegions?:IForestArea,
    negotiated?:boolean
};

export interface ISearch {
    serviceName?:string | undefined,
    search?: string | undefined,
    sortBy?:string,
    sortOrder?:string,
    filterBy?:IFilter,
    pagination:IPagination, 
  };

