import { BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import { RecoilRoot } from 'recoil';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export function App() {
  const auth = useAuth();

  useEffect(() => {
    const isLoggedOutPath = window.location.pathname === "/logged-out";

    if (!isLoggedOutPath && !auth.isLoading && !auth.isAuthenticated) {
      if (window.location.search) {
        // Clear query parameters before login
        window.location.href = window.location.origin;
      } else {
        auth.clearStaleState();
        auth.signinRedirect(); // Login
      }
    }
    else if (auth.isAuthenticated) {
      localStorage.setItem("accessToken", auth.user?.access_token ?? "");

      // Check if the page has already been reloaded
      if (!sessionStorage.getItem("hasReloaded")) {
        sessionStorage.setItem("hasReloaded", "true"); // Mark reload as done
        window.location.replace(window.location.origin + window.location.pathname);
      }

    }
  }, [auth]);

  return (
    <RecoilRoot>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
