import {
  GoAButton,
  GoAButtonGroup,
  GoACheckbox,
  GoAModal,
  GoATable,
  GoATableSortHeader,
} from "@abgov/react-components";
import { useEffect, useState } from "react";
import { importWorkOrderErrorMessageState, importWorkOrderModalState, importWorkOrderNotificationState, importWorkOrderNotificationTypeState } from "../../states/WorkOrder.state";
import { useRecoilState } from "recoil";
import { WorkOrder, WorkOrderRequest, WorkOrderResponse } from "../../model/WorkOrder";
import { WorkOrderService } from "../../services/WorkOrderService";
import PageLoader from "../../components/PageLoader";

// interface IWorkOrderImportModalProps {
//     show : boolean
// }
const WorkOrderImportModal: React.FunctionComponent = (props) => {
  const [openModal, setOpenModal] = useRecoilState<boolean>(
    importWorkOrderModalState
  );

  const [imisWorkOrders, setImisWorkOrders] = useState<WorkOrderResponse>();
 //const [importWorkOrderRequest, setImportWorkOrderRequest] = useState<WorkOrderRequest>();
  const [notifyImportWorkOrder, setNotifyImportWorkOrder] = useRecoilState<boolean>(importWorkOrderNotificationState);
  const [notificationMessageType, setNotificationMessageType] = useRecoilState<number>(importWorkOrderNotificationTypeState); 
  const [errorMessage, setErrorMessage] = useRecoilState<string>(importWorkOrderErrorMessageState); 
  const [sortCol, setSortCol] = useState("WorkOrderNo");
  const [sortDir, setSortDir] = useState(0);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(false);

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  const handleImportClick = async () => {
    if (imisWorkOrders?.workOrders) {
     setIsLoading(true);
      try {
        const workOrders = imisWorkOrders?.workOrders?.filter((i : any) => i.isSelected);
        const workOrderRequest : WorkOrderRequest = {workOrders : workOrders};
        const result = await WorkOrderService.importAll(
          workOrderRequest
        );

        if (result && result.success) {
          setNotificationMessageType(1);
          setNotifyImportWorkOrder(true);

        } else {
          setNotificationMessageType(0);
          setErrorMessage(result?.error?.detail);
          console.log(errorMessage);
        }
        setOpenModal(false);
        setSelectAllWorkOrders(false);
        setNotifyImportWorkOrder(true);
        console.log(String(notifyImportWorkOrder) + notificationMessageType);
      } catch (error) {
        console.error("Error importing workorder:", error);
        alert("An error occurred while importing the workorder");
      }
      finally{
        setIsLoading(false);
      }
    }
  };

  const setWorkOrderSelected = (value : boolean, index: number) => {
    if(imisWorkOrders?.workOrders){
      imisWorkOrders.workOrders[index] = {...imisWorkOrders.workOrders[index], isSelected : value};
        setImisWorkOrders({...imisWorkOrders, workOrders : imisWorkOrders.workOrders })
      }
    };

    const setSelectAllWorkOrders = (value : boolean) => {
      setCheckAll(value);
      const updatedWorkOrders = imisWorkOrders?.workOrders ;
      if(updatedWorkOrders){
        updatedWorkOrders?.forEach(i => i.isSelected = value);
        setImisWorkOrders({...imisWorkOrders, workOrders : updatedWorkOrders });
      }
    }

  useEffect(() => {
    const getData = async () => {
      try {
      //  setIsLoading(true);
        const response = await WorkOrderService.getAllLegacy(1, 5000, sortCol,
          sortDir === 1 ? "ASC" : "DESC")
        if (response && response.data && response.data.workOrders) {
        //  setTotalCount(response?.data?.pageInfo?.total ?? 0);
        response.data.workOrders.forEach(i => i.isImported = true);
        setImisWorkOrders(response.data);
        //setTotalCount(response?.data?.pageInfo?.total ?? 0);
        }

      //  setIsLoading(false);

      } catch (error) {
        console.error("Error fetching legacy workorders:", error);
        //setIsLoading(false);
      }
    };
    getData();
  },[sortCol, sortDir, notifyImportWorkOrder]);

  return (
    <>
      <PageLoader visible={loading} />
      <div>
        
        <GoAModal
          heading="Old IMIS Work Orders" 
         // onClose={() => {setOpenModal(false); setSelectAllWorkOrders(false);}}
          open={openModal}
          maxWidth="1400px"
        >
           <div className="import-workorder-button-group">
          <GoAButtonGroup alignment="end">
               <GoAButton type="secondary" onClick={() => {setOpenModal(false); setSelectAllWorkOrders(false);}}>
                 Close
               </GoAButton>
               <GoAButton disabled={imisWorkOrders?.workOrders.findIndex(i => i.isSelected) === -1} type="primary" onClick={handleImportClick}>
                 Import
               </GoAButton>
             </GoAButtonGroup>
             </div>
          <div className="divTable">
            <GoATable width="100%" onSort={sortData}> 
              <thead>
                <tr className="import-workorder-header">
                  <th style={{width: '50px'}}> <GoACheckbox
                          name="checkAllWorkOrders"
                          checked={checkAll}
                          onChange={(name, checked, value) => setSelectAllWorkOrders(checked) }
                        ></GoACheckbox></th>
                  <th style={{width: '200px'}}><GoATableSortHeader name="workOrderNo">Old IMIS ID</GoATableSortHeader></th>
                  <th style={{width: '150px'}}><GoATableSortHeader name="unitNo">Unit #</GoATableSortHeader></th>
                  <th style={{width: '150px'}}><GoATableSortHeader name="itemName">Item description</GoATableSortHeader></th>
                  <th style={{width: '200px'}}><GoATableSortHeader name="description">Problem description</GoATableSortHeader></th>
                  <th style={{width: '200px'}}><GoATableSortHeader name="locationName">Location</GoATableSortHeader></th>
                  <th style={{width: '150px'}}><GoATableSortHeader name="createdBy">SignInName</GoATableSortHeader></th>
                </tr>
              </thead>
              <tbody>
                {imisWorkOrders &&
                  imisWorkOrders.workOrders?.map((record: WorkOrder, index: any) => (
                    <tr key={index}>
                      <td width={'50px'}>
                        <GoACheckbox
                          name="checkWorkOrder"
                          checked={record.isSelected??false}
                          onChange={(name, checked, value) => setWorkOrderSelected(checked, index) }
                        ></GoACheckbox>
                      </td>
                      <td width={'200px'}>{record.workOrderNo}</td>
                      <td width={'150px'}>{record.unitNo}</td>
                      <td width={'150px'}>{record.itemName}</td>
                      <td width={'200px'}>{record.description}</td>
                      <td width={'200px'}>{record.locationName}</td>
                      <td width={'150px'}>{record.createdByName}</td>
                      {/* <td width={'150px'}>{new Date(record.dateIn).toLocaleDateString("en", {
                          year: "numeric",
                          day: "2-digit",
                          month: "long",
                        })}</td> */}
                    </tr>
                  ))}
              </tbody>
            </GoATable>
          </div>

        
          {/* <div className={workOrdersTotalCount > 0 ? "visible pagination" : "not-visible pagination"}>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span style={{ whiteSpace: "nowrap" }}>Show</span>
              <div className="dropdown-list">
                <GoADropdown name="selPerPage" onChange={changePerPage} value="25" width="8ch">
                  <GoADropdownItem value="25"></GoADropdownItem>
                  <GoADropdownItem value="50"></GoADropdownItem>
                  <GoADropdownItem value="100"></GoADropdownItem>
                </GoADropdown>
              </div>
              <span style={{ whiteSpace: "nowrap" }}>of {workOrdersTotalCount} items</span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination
              itemCount={workOrdersTotalCount || 10}
              perPageCount={perPage}
              pageNumber={page}
              onChange={changePage}
            />
          </GoABlock>
          
        </div> */}
          
        </GoAModal>
      </div>
    </>
  );
};

export default WorkOrderImportModal;
