let _apimUrl = "";
let env = process.env.REACT_APP_ENV;

switch (env) {
  case "local":
    _apimUrl = "https://localhost:49499";
    break;
  case "dev":
    _apimUrl =
      "https://apim.wildfireapps-dev.alberta.ca/wildfire-admin-api-dev";
    break;
  case "uat":
     _apimUrl = "https://goa-wdp-apim-uat.azure-api.net/goa-wdp-admin-api";
    break;
  case "prod":
    _apimUrl = "https://goa-wdp-apim.azure-api.net/goa-wdp-admin-api";
    break;
}

export const permissions = {
  IMS_CATALOG_DELETE: 'IMS_Catalog_D',
  IMS_CATALOG_WRITE: 'IMS_Catalog_W',
  IMS_CATALOG_VIEW: 'IMS_Catalog_V', 
  IMS_CATALOG_ITEM_VIEW: 'IMS_Catalog_Item_V',
  IMS_CATALOG_ITEM_WRITE: 'IMS_Catalog_Item_W',
  IMS_CATALOG_DELETE_MECH: 'IMS_DCatMechanical',
  IMS_LOCATION_VIEW : 'IMS_Loc_V',
  IMS_LOCATION_DELETE_WSC1SEMS: 'IMS_DLocWSC1SEMS',
  IMS_LOCATION_WRITE: 'IMS_Loc_W',
  IMS_LOCATION_EXPORT: 'IMS_Loc_E',
  IMS_WORKORDER_CREATE: 'IMS_WO_I',
  IMS_WORKORDER_REACTIVATE: 'IMS_WO_E',
  IMS_WORKORDER_UPDATE : 'IMS_WO_U',
  IMS_WORKORDER_UPDATE_MECHANIC: 'IMS_WO_U_M',
  IMS_WORKORDER_VIEW : 'IMS_WO_V',
  IMS_ADJUSTMENT_VIEW:'IMS_Adjustment_V',
  IMS_ADJUSTMENT_WRITE:'IMS_Adjustment_W',
}
export const WORKORDER_STATUS_ACTIVE = 1;
export const WORKORDER_STATUS_COMPLETED = 8;
export const WORKORDER_LABOUR_OTFACTOR_REGULAR = {otFactorValue: 1, otFactorLabel: 'Regular'};
export const WORKORDER_LABOUR_OTFACTOR_TIMEHALF= {otFactorValue: 1.5, otFactorLabel: 'Time half'};;
export const WORKORDER_LABOUR_OTFACTOR_DOUBLETIME = {otFactorValue: 2, otFactorLabel: 'Double time'};

export const apimUrl = _apimUrl;
export const dateFormat = "yyyy-MM-dd";

export const VALIDATION_WORKORDER_TAB_MAIN= "workorder-tab-main-";
export const VALIDATION_WORKORDER_TAB_PARTSLABOURS = "workorder-tab-partslabours-";