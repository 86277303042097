import React, { useCallback, useEffect, useState } from 'react';
import { GoAButton, GoAButtonGroup, GoACheckbox, GoADropdown, GoADropdownItem, GoAFormItem, GoAModal, GoATable, GoATextArea } from '@abgov/react-components';
import PageLoader from '../../components/PageLoader';
import PageHeader from '../../components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';

import { CommonLookup } from '../../model/Common';
import { CalloutVariant, ForObjectType } from '../../model/enums/Constants';
import { Adjustment, AdjustmentItem } from '../../model/Adjustments';
import { InventoryService } from '../../services/InventoryService';
import { SelectOptions, UserComment } from '../../model/Base';
import { LocationService } from '../../services/LocationService';
import { ItemLocation } from '../../model/Location';
import Select, { InputActionMeta } from 'react-select';
import debounce from 'lodash/debounce';
import { ExternalService } from '../../services/ExternalService';
import CatalogItemDetailView from '../catalog/CatalogItemView';
import { getDataFromSessionStorage } from '../../utils/SessionStorageUtils';
import { loadCommonLookups } from '../../states/Common.state';
import { Autocomplete, TextField } from '@mui/material';

const AdjustmentDetail = () => {
  const [locations, setLocations] = useState<SelectOptions[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({}); // Define errors as Record<string, string>
  const [formChanged, setFormChanged] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] =
    React.useState(false);
  const [showCancelModal, setShowCancelModal] =
    React.useState(false);

  const [notificationMessage, setNotificationMessage] = useState("");
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [adjustmentReasonOptions, setAdjustmentReasonOptions] = useState<CommonLookup>();
  const [memoOptions, setMemoOptions] = useState<CommonLookup>();
  const [ownerOptions, setOwnerOptions] = useState<CommonLookup>();
  const { adjustmentNo } = useParams();
  const navigate = useNavigate();
  const [ajustmentStatusOptions, setAdjustmentStatusOptions] = useState<any>([]);
  const [formState, setFormState] = useState<Adjustment>(new Adjustment());
  const [itemsOptions, setItems] = useState<ItemLocation[]>([]);

  const [itemsOptionsFiltered, setItemsFiltered] = useState<any[]>([]);
  const [unUsedItemsOptionsFiltered, setUnusedItemsFiltered] = useState<any[]>([]);

  const [fireNoFiltered, setFireNoFiltered] = useState<SelectOptions[]>([]);

  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [qtyAdjustmentValue, setQtyAdjustmentValue] = useState<string[]>([]);
  const [qtyAdjustedValue, setQtyAdjustedValue] = useState<string[]>([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null); // State to track expanded row
  const MAX_INPUT_LENGTH = 300;
  const populateItems = (data: ItemLocation[]) => {
    const options: SelectOptions[] = data
      .map(item => {
        if (item.name !== null) {
          return {
            value: item.catalogItemId,
            label: `${item.name} (${item.imisId}-${item.code}  -  ${ajustmentStatusOptions?.find((s: any) => s.id === item?.stock.status)?.value ?? 'Unknown'})`
          };
        }
        return null;
      })
      .filter(option => option !== null) as SelectOptions[];

      const unUsedOptions: SelectOptions[] = data.reduce((acc: SelectOptions[], item) => {
        if (
          item.name !== null &&
          !formState.items.some(formItem => formItem.catalogItemId === item.catalogItemId) &&
          !acc.some(accItem => accItem.value === item.catalogItemId) // Check if already in the result list
        ) {
          acc.push({
            value: item.catalogItemId,
            label: `${item.name} (${item.imisId}-${item.code}  -  ${
              ajustmentStatusOptions?.find((s: any) => s.id === item?.stock.status)?.value ?? 'Unknown'
            })`,
          });
        }
        return acc;
      }, []);
      
    setItemsFiltered(options);
    setUnusedItemsFiltered(unUsedOptions);
  };


  const handleBeforeUnload = useCallback((event: any) => {
    if (formChanged) {
      event.preventDefault();
      event.returnValue = ''; // For modern browsers
      return ''; // For older browsers
    }
  }, [formChanged]);

  useEffect(() => {
    const beforeUnloadListener = (event: any) => {
      handleBeforeUnload(event);
    };

    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [handleBeforeUnload]);


  useEffect(() => {
    async function fetchData() {
      const responseAdj = await getDataFromSessionStorage(ForObjectType.ADJUSTMENTS);
      const responseInv = await getDataFromSessionStorage(ForObjectType.INVENTORY_ITEM);

      if (!responseAdj || !responseInv) {
        loadCommonLookups();
      }

      const adjustmentStatusOptions = responseInv.find((x: CommonLookup) => x.name === 'status');
      const adjustmentReason = responseAdj.find((x: CommonLookup) => x.name === 'AdjustmentReason');
      const memoOptions = responseAdj.find((x: CommonLookup) => x.name === 'memo');
      const ownerOptions = responseAdj.find((x: CommonLookup) => x.name === 'owner');

      if (adjustmentReason) {
        setAdjustmentReasonOptions(adjustmentReason);
      }

      if (memoOptions) {
        setMemoOptions(memoOptions);
      }

      if (ownerOptions) {
        setOwnerOptions(ownerOptions);
      }

      if (adjustmentStatusOptions) {
        setAdjustmentStatusOptions(adjustmentStatusOptions.elements);
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);


  const onLocationSearchChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action === 'input-change') {
      loadLocationOptions(inputValue, (result: any) => {
        setLocations(result);
      });
    }
  };

  const loadLocationOptions = debounce(async (inputValue: string, callback: (data: any[]) => void) => {
    if (!inputValue) return;
    const data = await LocationService.getList(inputValue);
    // Pass the transformed options to the callback function
    callback(data);
  }, 500);

  const loadFireOptions = debounce(async (inputValue: string, callback: (data: any[]) => void) => {
    if (!inputValue) return;
    const data = await ExternalService.getFireNumbers(inputValue);
    // Pass the transformed options to the callback function
    callback(data);
  }, 500);


  const onItemSearchChange = (inputValue: string, { action }: InputActionMeta) => {

    if (!selectedLocation)
      return;

    inputValue = inputValue.toLowerCase();
    if (inputValue.length > 1) {
      getItemsForLocation(selectedLocation.value, inputValue);
      const data = itemsOptions
        .filter(x =>
          (x.code && x.code.includes(inputValue)) ||
          (x.name && x.name.toLowerCase().includes(inputValue)) ||
          (x.imisId && x.imisId.toString().includes(inputValue))
        )
        .sort((a, b) => b.name.localeCompare(a.name));
      if (data) {
        populateItems(data);
      }
    }
  };

  const onFireNoSearchChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action === 'input-change') {
      inputValue = inputValue.toLowerCase();
      loadFireOptions(inputValue, (result: any) => {
        const options: SelectOptions[] = result?.map((r: any) => ({
          value: `${r.fireNumber}-${r.year}`,
          label: `${r.fireNumber}-${r.year}`
        }));
        setFireNoFiltered(options);
      });
    }
  };


  const handleLocationChange = (selectedLocation: any) => {
    setSelectedLocation(selectedLocation);
    handleFieldChange("locationId", selectedLocation.value)
  };

  const getItemsForLocation = async (locationId: string, search: string) => {
    const itemsData = await LocationService.getItemAdjustmentById(locationId, search);
    setItems(itemsData?.data.items);
    if (itemsData && itemsData.data) {
      populateItems(itemsData.data.items);
    }
  }

  const createUpdateClick = async () => {
    if (!validateForm('save')) {
      console.log("Form contains errors. Cannot submit.");
      return;
    }
    setIsLoading(true);
    try {
      let result;
      formState.locationName = selectedLocation?.label;
      result = await InventoryService.create(formState!);

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Adjustment saved successfully!");
        setShowNotificationMessage(true);
        setFormChanged(false);
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result.error);
        setShowNotificationMessage(true);
      }
    } catch (error) {
      alert("An error occurred while creating the adjustment");
    } finally {
      setIsLoading(false);
    }
  }

  function setValue(value: Partial<Adjustment>) {
    setFormState(new Adjustment({ ...formState, ...value }));
  }

  //Event handler for input change
  const handleFieldChange = (name: string, value: any) => {
    setValue({ [name]: value });
    validateForm(name, value);
    setFormChanged(true);
  };

  const handleItemChange = (item: any, index: number) => {
    const selectedItem = itemsOptions.find((l: ItemLocation) => l.catalogItemId === item.value);
    const updatedFormState = [...formState.items];
    updatedFormState[index] = {
      ...updatedFormState[index],
      catalogItemId: item.value,
      qtyCurrent: selectedItem?.stock?.quantity ?? 0,
      qtyAdjusted: selectedItem?.stock?.quantity ?? 0,
      status: selectedItem?.stock.status ?? 0,
      code: selectedItem?.code ?? '',
      owner: selectedItem?.stock?.owner ?? 0,
      imisId: selectedItem?.imisId ?? 0,
      name: selectedItem?.name ?? '',
      locationItemId: selectedItem?.id ?? '',
    };

    // Update the state with the modified array
    setFormState({
      ...formState,
      items: updatedFormState
    });

    // Validate the form
    validateForm(item.label, item.value);

    //setItemUsed(selectedItem, true);

  }


  // Assuming formState is already defined somewhere in your component



  function handleItemQtyChange(name: string, value: string, index: number): void {
    const updatedFormState = [...formState.items];
    const updatedQtyAdjustmentValue = [...qtyAdjustmentValue];
    const updatedQtyAdjustedValue = [...qtyAdjustedValue];

    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      return;
    }

    if (name === "qtyAdjustment") {
      updatedFormState[index].qtyAdjusted = updatedFormState[index].qtyCurrent + numericValue;
      updatedFormState[index].qtyAdjustment = numericValue;
      updatedQtyAdjustmentValue[index] = value;
      updatedQtyAdjustedValue[index] = updatedFormState[index].qtyAdjusted.toString();
    } else {
      updatedFormState[index].qtyAdjustment = numericValue - updatedFormState[index].qtyCurrent;
      updatedQtyAdjustmentValue[index] = updatedFormState[index].qtyAdjustment.toString();
      updatedQtyAdjustedValue[index] = value;
    }

    setQtyAdjustmentValue(updatedQtyAdjustmentValue);
    setQtyAdjustedValue(updatedQtyAdjustedValue);

    setFormState({
      ...formState,
      items: updatedFormState
    });

    // Validate the form
    validateForm(name, value);
  }

  function validateForm(name: string, value: string = '') {
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if ((name === 'save' && !formState?.locationId) || (name === "location" && !value)) {
      newErrors['location'] = 'Location is required';
    }

    if ((name === 'save' && !formState?.reason) || (name === "reason" && !value)) {
      newErrors['reason'] = 'Reason is required';
    }

    if ((name === 'save')) {
      formState?.items?.forEach((item: AdjustmentItem, index: number) => {
        if (!item.catalogItemId) {
          newErrors[`catalogItemId-${index}`] = `Item is required`;
        }

        if (item.qtyAdjustment === 0) {
          newErrors[`qtyAdjustment-${index}`] = `Increase/decrease can not be zero`;
        }
      });
    }

    if ((name === 'save')) {
      if (formState?.items.length === 0)
        newErrors[`items`] = `At least one item adjustment is required`;
    }

    // if ((name === 'fireNo') && value) {
    //   if (!fireNoList.includes(value))
    //     newErrors[`fireNo`] = `Invalid Fire #: ${value}`;
    // }

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  function setCheckAllItems(checked: boolean) {
    setCheckAll(true);
    const updatedItems = formState.items?.map((record: AdjustmentItem) => ({
      ...record,
      isChecked: checked
    }));

    // Update the state with the modified items array
    setFormState({
      ...formState,
      items: updatedItems
    });
  }

  function setCheckItem(checked: boolean, index: number): void {
    setFormChanged(true);

    if (formState) {
      // Create a copy of the current items array
      const updatedItems = [...formState.items];

      // Update the isChecked property of the item at the specified index
      if (index >= 0 && index < updatedItems.length) {
        updatedItems[index].isChecked = checked;
      }

      // Update the state with the modified items array
      setFormState({
        ...formState,
        items: updatedItems
      });
    }
  }


  function handleCommentChange(name: string, value: string | string[]): void {
    var updatedComments;
    if (formState) {
      const commentText = Array.isArray(value) ? value.join('') : value;
      const newComment = new UserComment(undefined, commentText, "Shahram", (new Date()).toISOString());
      // Split the text of the comment by the newline character "\n"
      const textArray: string[] = newComment.text.split("\n");

      // Create an array of comment objects
      const newCommentsArray: UserComment[] = textArray
        .filter(text => text.trim().length > 0) // Filter out empty strings
        .map((text: string) => ({
          id: undefined,
          postDate: newComment.postDate,
          text: text,
          username: newComment.username
        }));
      if (name === "memo")
        updatedComments = [...newCommentsArray, ...(formState.comments || [])];
      else
        updatedComments = [...newCommentsArray];


      setFormState({ ...formState, comments: updatedComments });
    }
  }


  function handleAddItemClick(): void {
    setUnusedItemsFiltered([]);

    if (formState) {
      const newItem = new AdjustmentItem();

      // Spread the existing items and add the new item
      const updatedItems = [...(formState.items || []), newItem];

      // Update the corresponding entries in qtyAdjustmentValue and qtyAdjustedValue
      const updatedQtyAdjustmentValue = [...(qtyAdjustmentValue || []), '0']; // Assuming default value as '0'
      const updatedQtyAdjustedValue = [...(qtyAdjustedValue || []), '0']; // Assuming default value as '0'

      // Update the form state with the new items array and corresponding arrays
      setFormState({
        ...formState,
        items: updatedItems,
      });

      setQtyAdjustmentValue(updatedQtyAdjustmentValue);
      setQtyAdjustedValue(updatedQtyAdjustedValue);

      validateForm('add-item');
    }
  }


  function deleteItem() {
    // Update form change state
    setFormChanged(true);
    setCheckAll(false);

    if (formState) {
      // Filter out the items that are checked
      const updatedItems = formState.items.filter(item => !item.isChecked && item.catalogItemId);

      // Find the indices of the items that are being removed
      const removedIndices = formState.items
        .map((item, index) => (item.isChecked || !item.catalogItemId) ? index : -1)
        .filter(index => index !== -1);

      // Filter out the corresponding entries in qtyAdjustmentValue and qtyAdjustedValue
      const updatedQtyAdjustmentValue = qtyAdjustmentValue.filter((_, index) => !removedIndices.includes(index));
      const updatedQtyAdjustedValue = qtyAdjustedValue.filter((_, index) => !removedIndices.includes(index));

      // Update the state with the filtered items and corresponding arrays
      setFormState({
        ...formState,
        items: updatedItems
      });

      setQtyAdjustmentValue(updatedQtyAdjustmentValue);
      setQtyAdjustedValue(updatedQtyAdjustedValue);
    }
  }


  const handleCancel = () => {
    if (formChanged)
      setShowCancelModal(true);
    else
      navigate(`/adjustmentList`)
  };


  const handleFocus = (event: any) => {
    event.target.select();
  };

  const handleChange = (event: any, index: number) => {
    handleItemQtyChange(event.target.name, event.target.value, index);
  };

  const toggleRowExpand = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <>
      <PageLoader visible={loading} />

      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => navigate(`/adjustmentList`)}>Consumable adjustments</GoAButton>
            <GoAButton type="tertiary" onClick={() => { }}><div className='view-nav'>Create consumable adjustment</div></GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title='Create consumable adjustment' />
          </div>
        </div>

        <div className="left width100 input-form-item">
          <div className="left width-auto form-row ">
            <label className='form-label'>{adjustmentNo}</label>
          </div>
        </div>

        <div className='form-detail-container'>
        <div className='input-form-item'>
              <GoAFormItem label='Location' error={errors['location']}>
                <Select
                  name="location"
                  options={locations}
                  placeholder="--Select--"
                  className="width100 select-input"
                  isDisabled={false}
                  isSearchable={true}
                  onInputChange={onLocationSearchChange}
                  onChange={handleLocationChange}
                />
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label="Fire #" error={errors['fireNo']} requirement="optional">
                <Select
                  name="fireNo"
                  options={fireNoFiltered}
                  placeholder="--Select--"
                  className="width100 select-input"
                  isDisabled={false}
                  isSearchable={true}
                  onInputChange={onFireNoSearchChange}
                  onChange={(item) => handleFieldChange('fireNo', item?.value)}
                />
              </GoAFormItem>
            </div>
          
          <div>
            <div className='input-form-item'>
              <GoAFormItem label='Reason for adjustments' error={errors['reason']}>
                <GoADropdown name="reason" width="100%" placeholder='Select' filterable={true}
                  value={formState?.reason}
                  onChange={handleFieldChange}
                >
                  {adjustmentReasonOptions?.elements?.map((item: any) => (
                    <GoADropdownItem label={item.value} value={item.id} key={item.id}></GoADropdownItem>
                  ))}
                </GoADropdown>
              </GoAFormItem>
            </div>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label="Comments" id="description" requirement="optional">
              <GoATextArea
                value={(formState?.comments ? formState.comments?.map(comment => comment.text).join('\n') : '') ?? ''}
                name="comment"
                rows={3}
                width="100%"
                countBy="character"
                maxCount={4000}
                onChange={(name, value) => handleCommentChange(name, value)}
              />

            </GoAFormItem>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label='Append memo' error={errors['memo']} requirement="optional">
              <GoADropdown
                name="memo"
                width="100%"
                placeholder='Select'
                filterable={true}
                value={''} // Currently, you're passing an empty string here
                onChange={(name, value) => handleCommentChange(name, value)} // Pass the selected memo's value to handleMemoChange
              >
                {memoOptions?.elements?.map((item: any) => (
                  <GoADropdownItem label={item.value} value={item.value} key={item.id}></GoADropdownItem>
                ))}
              </GoADropdown>
            </GoAFormItem>
          </div>
        </div>

        <div className='input-form-item'>
          <GoAFormItem label='Items' error={errors['items']}>
            <GoATable width="100%">
              <thead>
                <tr>
                  <th className="check-item">
                    <GoACheckbox
                      name="check"
                      text=""
                      checked={checkAll}
                      onChange={(name, checked, value) => {
                        setCheckAllItems(checked);
                      }}
                    />
                  </th>
                  <th>
                    IMIS ID
                  </th>
                  <th>
                    Part #
                  </th>
                  <th>
                    Item name
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Qty on hand
                  </th>
                  <th>
                    Increase/decrease
                  </th>
                  <th>
                    New qty
                  </th>
                  <th>
                    Owner
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {formState?.items?.map((record: AdjustmentItem, index: any) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="check-item">
                        <GoACheckbox
                          name="isChecked"
                          text=""
                          value={record.isChecked}
                          checked={record.isChecked}
                          onChange={(name, checked, value) => {
                            setCheckItem(checked, index);
                          }}
                        />
                      </td>
                      <td>{record.imisId}</td>
                      <td>{record.code}</td>
                      <td><GoAFormItem error={errors[`catalogItemId-${index}`]}>
                        <Autocomplete
                          freeSolo // Allows free text input
                          options={record.catalogItemId ? itemsOptionsFiltered : unUsedItemsOptionsFiltered}
                          value={itemsOptionsFiltered.find(x => x.value === record.catalogItemId)}

                          onChange={(event, value) => {
                            // If value is null, clear the itemName
                            if (value)
                              handleItemChange(value, index);
                          }}

                          inputValue={itemsOptionsFiltered.find(x => x.value === record.catalogItemId)} // Current input value
                          onInputChange={(event: any, newInputValue) =>
                            onItemSearchChange(newInputValue, event) // Pass the input to handleFieldChange
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps, // Ensure existing inputProps are preserved
                                maxLength: MAX_INPUT_LENGTH // Set the maximum length
                              }}
                              variant="outlined"
                              placeholder="Select or type item name, code or imisId"
                              style={{ width: "680px" }}
                            />
                          )}
                        />

                      </GoAFormItem>
                      </td>
                      <td>
                        {ajustmentStatusOptions?.find((s: any) => s.id === record.status)?.value}
                      </td>
                      <td> {record.qtyCurrent} </td>
                      <td>
                        <GoAFormItem error={errors[`qtyAdjustment-${index}`]}>
                          <input
                            type="number"
                            className={`input-common ${record.catalogItemId ? 'enabled' : 'disabled'}`}
                            onChange={(e) => handleChange(e, index)}
                            value={qtyAdjustmentValue[index]}
                            disabled={!record.catalogItemId}
                            name="qtyAdjustment"
                            onFocus={handleFocus}
                            step="any" // Ensure step allows for decimal and negative numbers
                          />
                        </GoAFormItem>
                      </td>
                      <td>
                        <input
                          type="number"
                          className={`input-common ${record.catalogItemId ? 'enabled' : 'disabled'}`}
                          onChange={(e) => handleChange(e, index)}
                          value={qtyAdjustedValue[index]}
                          disabled={!record.catalogItemId}
                          name="qtyAdjusted"
                          onFocus={handleFocus}
                          step="any" // Ensure step allows for decimal and negative numbers
                        />
                      </td>
                      <td>{ownerOptions?.elements.find(o => o.id === Number(record.owner))?.value}</td>
                      <td>
                        <GoAButton
                          size="compact"
                          type="tertiary"
                          disabled={!record.catalogItemId}
                          onClick={() => toggleRowExpand(index)}
                        >
                          <goa-icon type={(expandedRowIndex === index) ? "chevron-down" : "chevron-forward"}></goa-icon>
                        </GoAButton>

                      </td>
                    </tr>  {expandedRowIndex === index && (
                      <tr>
                        <td colSpan={14}><CatalogItemDetailView id={record.catalogItemId} editable={false} /></td>
                      </tr>
                    )}
                  </React.Fragment>)
                )}
              </tbody>
            </GoATable>
          </GoAFormItem>
        </div>
        <div className='form-buttons-container'>
          <div className='input-form-item left'>
            <GoAButton type="secondary" onClick={handleAddItemClick}>
              Add item
            </GoAButton>
            <div className='button-spacing'></div> {/* Add space between buttons */}
            <GoAButton type="tertiary" onClick={deleteItem} >
              Delete item(s)
            </GoAButton>
          </div>

          <div className='input-form-item right'>
            <GoAButton type="secondary" onClick={handleCancel} >
              Cancel
            </GoAButton>
            <div className='button-spacing'></div> {/* Add space between buttons */}
            <GoAButton type="primary" onClick={createUpdateClick} disabled={Object.keys(errors).length > 0 || !formChanged}>
              Create
            </GoAButton>
          </div>
        </div>
      </div>
      <GoAModal
        heading={""}
        calloutVariant={calloutVariant}
        open={showNotificationMessage}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton onClick={() => {
              setShowNotificationMessage(false);
              if (calloutVariant === CalloutVariant.SUCCESS) {
                navigate(`/adjustmentList`);
              }
            }}>

              Okay
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
      </GoAModal>

      <GoAModal heading="" open={showCancelModal} maxWidth="30vw"
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowCancelModal(false); }}>No, continue editing</GoAButton>
            <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={() => { navigate(`/adjustmentList`) }}>Yes, I am sure</GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to discard the changes?</p>
      </GoAModal>
      {/* 
        <GoAModal
          heading="You have unsaved changes"
          open={showModal}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => onCancel()}>Cancel</GoAButton>
              <GoAButton onClick={() => onDiscardChanges()}>
                Discard changes
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <p>Are you sure you want to discard changes?</p>
        </GoAModal> */}
    </>
  );
};

export default AdjustmentDetail;
