

export interface IWorkOrderLabourItem {
id: string |undefined;
isChecked: boolean;
labourItemId: string | undefined;
serviceCode: string;
description: string;
hours?: number | undefined;
otFactor?: number | undefined;
otFactorDesc : string;
hourlyRate?: number | undefined;
labourItemCost?: number | undefined;
isDeleted : boolean;
}
  
  export class WorkOrderLabourItem implements IWorkOrderLabourItem {
    
    constructor(
      public id: string = '00000000-0000-0000-0000-000000000000',
      public isChecked: boolean = false,
      public isNoneCatalogChecked=false,
      public labourItemId: string = '00000000-0000-0000-0000-000000000000',
      public serviceCode: string = '',
      public description: string = '',
      public hours: number | undefined = 0,
      public otFactor: number | undefined = 1,
      public otFactorDesc: string = '',
      public hourlyRate: number | undefined = 38,
      public labourItemCost: number | undefined = 0,
      public isDeleted: boolean = false
    ) {}
  }
  
  
