import {
  GoABadge,
  GoABlock,
  GoAButton,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAPagination,
  GoASpacer,
  GoATable,
  GoATableSortHeader,
} from "@abgov/react-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import moment from "moment";
import SearchInput from "../../components/searchInput";
import { Fragment, FunctionComponent, useCallback, useState } from "react";

import { useAuth } from "react-oidc-context";
import { hasResourceRole } from "../../utils/tokens";
import { permissions } from "../../common/constants";
import { Order } from "../../model/Order";
import { OrderService } from "../../services/OrderService";
import { Autocomplete, debounce, TextField } from "@mui/material";
import { CustomFmF } from "../../components/datePicker";
import { getDataFromSessionStorage } from "../../utils/SessionStorageUtils";
import { ForObjectType } from "../../model/enums/Constants";
import { loadCommonLookups } from "../../states/Common.state";
import { CommonLookup } from "../../model/Common";
import { ExternalService, IFilter, IPagination, ISearch } from "../../services/ExternalService";

interface ICatalogProps { }

const OrdersList: FunctionComponent<ICatalogProps> = (
  props
) => {
  // Navigation
  const navigate = useNavigate();
  //Loader
  const [loading, setIsLoading] = useState(true);
  //Search
  const [searchValue, setSearchValue] = useState("");
  const [orderData, setOrderData] = useState<Order[]>([]);

  // page number
  const [page, setPage] = useState(1);

  //count per page
  const [perPage, setPerPage] = useState(25);

  const [totalCount, setTotalCount] = useState(0);

  //Sorting
  const [sortCol, setSortCol] = useState("lastUpdatedDate");
  const [sortDir, setSortDir] = useState(0);

  //search parameters
  const [fireNumber, setFireNumber] = useState(null);
  const [selectedFireNumber, setSelectedFireNumber] = useState<{ label: string; value: string } | null>(null);

  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const [orderStatusOptions, setOrderStatusOptions] = useState<{ label: string; value: number }[]>([]);
  const [forestAreaOptions, setForestAreaOptions] = useState<{ label: string; value: number }[]>([]);
  const [fireNumberOptions, setFireNumberOptions] = useState<{ label: string; value: string }[]>([]);



  const transformToOptions = (inputArray: any[], useLookupFields = false) => {
    // Map the input array to the desired output format
    return inputArray.map((item, index) => ({
      label: useLookupFields ? item.value : item.name, // Use 'value' for label if lookup fields are specified, otherwise use 'name'
      value: useLookupFields ? item.id : (index + 1) * 10 // Use 'id' for value if lookup fields are specified, otherwise use incremented multiples of 10
    }));
  };

  const orderFromOptions = [
    { label: 'Incident', value: 10 },
    { label: 'Logistics', value: 20 },
    { label: 'Area Logistics', value: 30 },
    { label: 'Area Warehouse', value: 40 },
  ];

  const orderTypeOptions = [
    { label: 'Fireline Equipment', value: 10 }
  ];



  React.useState(false);
  const getData = useCallback(async () => {
    try {

      const response = await OrderService.getAll(
        searchValue,
        page,
        perPage,
        sortCol,
        sortDir === 1 ? "ASC" : "DESC"
      );
      ;
      if (response) {
        setTotalCount(response?.data?.pageInfo?.total ?? 0);
        setOrderData(response.data.items);
      }
      let orderLookups = getDataFromSessionStorage(ForObjectType.ORDER);

      if (!orderLookups || !orderLookups.elements || orderLookups.elements.length === 0) {
        loadCommonLookups();
      }
      var orderStatusLookups = orderLookups.find((x: CommonLookup) => x.name === 'status')?.elements;
      setOrderStatusOptions(transformToOptions(orderStatusLookups, true));

      let objIPagination: IPagination = {
        perPage: 20,
        page: 1,
      };

      let objIFilter: IFilter = {
        columnName: "CRT_ID",
        columnValue: "4",
      };

      let objISearch: ISearch = {
        search: "",
        filterBy: objIFilter,
        pagination: objIPagination,
      };

      ExternalService.getCorporateRegion(objISearch)
        .then((response: any) => {
          setForestAreaOptions(transformToOptions(response.data));
        })
        .catch((e: Error) => {
          console.log("Error", e);
        });


    } catch (error) {
      console.error('Error fetching orders:', error);
      setIsLoading(false);
    }
  }, [searchValue, page, perPage, sortCol, sortDir]);


  React.useEffect(() => {

    const getOrders = async () => {
      setIsLoading(true);
      getData();
      setIsLoading(false);
    };

    getOrders();
  }, [getData, searchValue, page, perPage, sortCol, sortDir, setOrderData, setTotalCount]);


  const onFireNoSearchChange = (inputValue: string) => {

    const lowerCasedInput = inputValue.toLowerCase();
    loadFireOptions(lowerCasedInput, (result) => {
      if (result) {
        const options = result?.map((r) => ({
          label: `${r.fireNumber}-${r.year}`, // Display text for the dropdown
          value: `${r.fireNumber}-${r.year}`  // Underlying value
        }));
        setFireNumberOptions(options);
      }
    });
  };


  const loadFireOptions = debounce(async (inputValue: string, callback: (data: any[]) => void) => {
    if (!inputValue) return;
    const data = await ExternalService.getFireNumbers(inputValue);
    // Pass the transformed options to the callback function
    callback(data);
  }, 500);



  function searchCataloges(searchParamValue: string) {
    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setPage(1);
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }


  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  function changePerPage(name: any, newPage: any) {
    if (newPage !== page) {
      setPerPage(newPage);
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    if (newPage) {
      setPerPage(perPage);
      setPage(newPage);
    }
  }

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleChange = (event: any) => {
    setFireNumber(event.target.value);
  };


  const handleStartDateChange = (value: any) => {
    setStartDate(value.target.value); // Update startDate state with the new value
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value.target.value); // Update startDate state with the new value
  };


  const auth = useAuth();
  const canCreateCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_WRITE,
    auth.user?.access_token ?? ""
  );


  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" onClick={() => navigate(``)}>Orders</GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title="Orders" />
          </div>
          {canCreateCatalog && <div className="right width50 paddingLeft20px input-search align-right">
            <GoAButton type="primary" trailingIcon="add" onClick={() => { navigate(`/catalogDetail/new`) }}>
              Create order
            </GoAButton>
          </div>}
        </div>

        <div className="order-serach-wapper">
          <div className="left width100">
            <GoAButton type="tertiary" onClick={() => { }}>
              Clear all
            </GoAButton>
          </div>


          <div className="left width100">
            <div className="left width100 order-search-container">
              <div>
                <SearchInput label="" search={searchCataloges} clearSearch={clearSearch} placeholder="Search by name, description"></SearchInput>
              </div>
            </div>
          </div>
          <div className="left width100">
            <div className="left width100 order-search-container">
              <div>
                <GoAFormItem label='Fire number'>
                  <Autocomplete
                    options={fireNumberOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedFireNumber}
                    onInputChange={(event, value) => {
                      if (event) {
                        onFireNoSearchChange(
                          value
                        );
                      }
                    }}
                    onChange={(event, newValue) => {
                      setSelectedFireNumber(newValue); // Update the selected fire number on change
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Fire Number" variant="outlined" />
                    )}
                    style={{ minWidth: 200 }}
                  />
                </GoAFormItem>
              </div>
              <div>
                <GoAFormItem label='Order from'>
                  <Autocomplete
                    options={orderFromOptions}
                    getOptionLabel={(option) => option.label}
                    value={fireNumber}
                    onChange={(event, newValue) => handleChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Order from" variant="outlined" />
                    )}
                    style={{ minWidth: 200 }}
                  />
                </GoAFormItem>
              </div>
              <div>
                <GoAFormItem label='Forest area'>
                  <Autocomplete
                    options={forestAreaOptions}
                    getOptionLabel={(option) => option.label}
                    value={fireNumber}
                    onChange={(event, newValue) => handleChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Forest area" variant="outlined" />
                    )}
                    style={{ minWidth: 200 }}
                  />
                </GoAFormItem>
              </div>
            </div>
          </div>

          <div className="left width100">
            <div className="left width100 order-search-container">
              <div>
                <GoAFormItem label='Order status'>
                  <Autocomplete
                    options={orderStatusOptions}
                    getOptionLabel={(option) => option.label}
                    value={fireNumber}
                    onChange={(event, newValue) => handleChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Order status" variant="outlined" />
                    )}
                    style={{ minWidth: 200 }}
                  />
                </GoAFormItem>
              </div>
              <div>
                <GoAFormItem label='Order type'>
                  <Autocomplete
                    options={orderTypeOptions}
                    getOptionLabel={(option) => option.label}
                    value={fireNumber}
                    onChange={(event, newValue) => handleChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Order type" variant="outlined" />
                    )}
                    style={{ minWidth: 200 }}
                  />
                </GoAFormItem>
              </div>
              <GoAFormItem label="Date range(start)">
                <CustomFmF icon={startDate ? 'close' : 'calendar'} format="yyyy-MM-dd" value={startDate} onChange={(value: Date | null) => handleStartDateChange(value)} onBlur={(val: any) => { }} />
              </GoAFormItem>
              <GoAFormItem label="Date range(end)">
                <CustomFmF icon={endDate ? 'close' : 'calendar'} format="yyyy-MM-dd" value={endDate} onChange={(value: Date | null) => handleEndDateChange(value)} onBlur={(val: any) => { }} />
              </GoAFormItem>
            </div>
          </div>
        </div>

        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="orderId">Order #</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="fireNumber">Fire #</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="orderedBy">Ordered by</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="status">Status</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="orderDate">Order date</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="requiredBy">Required by</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="orderType">Order type</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="orderedFrom">Ordered from</GoATableSortHeader>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderData?.map((order: Order, index: number) => (
                <Fragment key={order.orderId}>
                  <tr>
                    <td><GoAButton
                      size="compact"
                      type="tertiary"
                      onClick={() =>
                        navigate(`/orderDetail/${order.orderNumber}`)
                      }
                    >
                      {order.orderNumber}
                    </GoAButton></td>
                    <td>{order.fireNumber}</td>
                    <td>{order.auditTrail.createdBy}</td>
                    <td>
                      {order.status === 1 ? (
                        <GoABadge type="success" content="Completed" />
                      ) : order.status === 2 ? (
                        <GoABadge type="emergency" content="Pending" />
                      ) : (
                        <GoABadge type="important" content="Cancelled" />
                      )}
                    </td>
                    <td>{moment(order.auditTrail.createdDate).format("yyyy-MM-DD")}</td>
                    <td>{moment(order.requiredBy).format("yyyy-MM-DD")}</td>
                    <td>{order.orderType}</td>
                    <td>{order.orderFrom}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </GoATable>

          <div className={totalCount > 0 ? "visible pagination pagination-div" : "not-visible pagination"}>
            <GoABlock alignment="center">
              <GoABlock mb="m" alignment="center" gap="m">
                <span style={{ whiteSpace: "nowrap" }}>Show</span>
                <div className="dropdown-list">
                  <GoADropdown name="selPerPage" onChange={changePerPage} value="25" width="8ch">
                    <GoADropdownItem value="25"></GoADropdownItem>
                    <GoADropdownItem value="50"></GoADropdownItem>
                    <GoADropdownItem value="100"></GoADropdownItem>
                  </GoADropdown>
                </div>
                <span style={{ whiteSpace: "nowrap" }}>
                  of {totalCount} items
                </span>
              </GoABlock>
              <GoASpacer hSpacing="fill" />
              <GoAPagination
                itemCount={totalCount || 10}
                perPageCount={perPage}
                pageNumber={page}
                onChange={changePage}
              />
            </GoABlock>
            {/*   
              <GoAModal heading="Delete catalogue" open={showDeleteModal} maxWidth="25vw"
                actions={
                  <GoAButtonGroup alignment="end">
                    <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowDeleteModal(false); }}>Cancel</GoAButton>
                    <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={confirmDeleteCatalogs}>Delete</GoAButton>
                  </GoAButtonGroup>
                }
              >
                <div>
                  Are you sure you want to delete the {catalogs?.filter(x => x.isChecked).length} catalogue
                  {catalogs?.filter(x => x.isChecked).length > 1 && 's'} (
                  {catalogs?.filter(x => x.isChecked).map((x, index) => (
                    <strong key={index}>
                      {x.name}
                      {index < catalogs.filter(y => y.isChecked).length - 1 && ', '}
                    </strong>
                  ))})?
                </div>
  
                <div className='input-form-item'>
                  <GoAFormItem error={errors['deleteConfirm']}>
                    <GoACheckbox
                      name="deleteConfirm"
                      text={`Yes, I am sure.`}
                      checked={deleteConfirm}
                      onChange={(name, checked, value) => {
                        validateForm(name, value);
                        setDeleteConfirm(checked);
                      }}
                    />
                  </GoAFormItem>
                </div>
  
              </GoAModal> */}

          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersList;
