import { httpOrderService } from "../http-service";
import { Order, OrderResponse } from "../model/Order";

const getAll = async (
  search: string,
  pageNumber: number,
  pageSize: number,
  orderBy: string,
  sortDirection: string,
) => {
  const params = new URLSearchParams({
    search: search,
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });

  // if (startDate) {
  //   params.append('startDate', startDate.toDateString());
  // }

  // if (endDate) {
  //   params.append('endDate', endDate.toDateString());
  // }

  // if (status) {
  //   params.append('status', status.toString());
  // }

  // if (name) {
  //   params.append('name', name);
  // }

  const data = await httpOrderService.get<OrderResponse>(`/order?${params}`);

  return data;
};

 
const getById = async (
  id : string
) => {
  const data = await httpOrderService.get<Order>(`/order/${id}`);
  return data;
};

 
 
 
const create = async (newOrder: Order) => {
  try {
    const order = JSON.stringify(newOrder);
    console.log(order);

    const response = await httpOrderService.post<Order>(
      `/order`,
      newOrder,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to create a work order'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error creating work order:', error?.response?.data?.detail);
    return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
  }
};

const update = async (newOrder: Order) => {
  try {
    const order = JSON.stringify(newOrder);
    console.log(order);

    const response = await httpOrderService.put<Order>(
      `/order`,
      newOrder,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to create a work order'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error creating work order:', error?.response?.data?.detail);
    return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
  }
};

const deleteO = async (id: string) => {
  try {
    // const order = JSON.stringify(newOrder);
    // console.log(order);

    const response = await httpOrderService.delete<string>(`/order/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to delete the work order'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error deleting work order:', error?.response?.data?.detail);
    return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
  }
};



export const OrderService = {
  getAll,
  getById,
  create,
  update,
  deleteO,
};