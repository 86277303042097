
export function formatPDFDate(value: Date)
{
    const year = value.toLocaleString("en-CA", { year: "numeric" });
    const month = value.toLocaleString("en-CA", { month: "2-digit" });
    const day = value.toLocaleString("en-CA", { day: "2-digit" });
    const hours = value.toLocaleString("en-CA", {hour: "2-digit", hour12: false});
    let mins = value.toLocaleString("en-CA", {minute: "2-digit"});
    if(mins.length === 1 ) {
       mins = "0" + mins;
    }
    return year + "-" + month + "-" + day + ", " + hours + ":" + mins;
}

export function toDateString(value: Date, format?: string)
{
    const year = value.toLocaleString("en-CA", { year: "numeric" });
    const month = value.toLocaleString("en-CA", { month: "2-digit" });
    const day = value.toLocaleString("en-CA", { day: "2-digit" });
    let mins = value.toLocaleString("en-CA", {minute: "2-digit"});
    if(mins.length === 1 ) {
       mins = "0" + mins;
    }
    if(format === "MMddyyy"){
        return month + day + year;
    }
    if(format === "ddMMyyyy"){
        return day + month + year;
    }
    return year + month + day;
}