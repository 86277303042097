import {
  GoABlock,
  GoAButton,
  GoAButtonGroup,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAModal,
  GoAPagination,
  GoASpacer,
  GoATable,
  GoATableSortHeader
} from "@abgov/react-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import moment from "moment";
import SearchInput from "../../components/searchInput";
import { WorkOrderService } from "../../services/WorkOrderService";
import { WorkOrder, WorkOrderResponse } from "../../model/WorkOrder";
import PageHeader from "../../components/PageHeader";
import WorkOrderImportModal from "./WorkOrderImportModal";
import {
  importWorkOrderErrorMessageState,
  importWorkOrderModalState,
  importWorkOrderNotificationState,
  importWorkOrderNotificationTypeState,
  workOrderListSearchState,
} from "../../states/WorkOrder.state";
import { useRecoilState } from "recoil";
import { CustomFmF } from "../../components/datePicker";
import { useState } from "react";

import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getDataFromSessionStorage } from "../../utils/SessionStorageUtils";
import { ForObjectType } from "../../model/enums/Constants";
import { CommonLookup } from "../../model/Common";
import { loadCommonLookups } from "../../states/Common.state";
import { hasResourceRole } from "../../utils/tokens";
import { useAuth } from "react-oidc-context";
import { permissions } from "../../common/constants";

interface IWokrOrderListProps {
  isHomePage?: boolean
}

const WorkOrderList: React.FC<IWokrOrderListProps> = (props) => {
  // Navigation
  const navigate = useNavigate();
  const auth = useAuth();

  const canCreateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_CREATE,
    auth.user?.access_token ?? ""
  );

  const [openImportModal, setOpenImportModal] = useRecoilState<boolean>(importWorkOrderModalState);
  //Loader
  const [loading, setIsLoading] = useState(true);
  //Search
  const [searchValue, setSearchValue] = useRecoilState<string>(workOrderListSearchState);

  const [data, setData] = useState<WorkOrderResponse>();

  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  // page number
  const [page, setPage] = useState(1);

  //count per page
  const [perPage, setPerPage] = useState(25);

  const [workOrderTotalCount, setTotalCount] = useState(0);

  //Sorting
  const [sortCol, setSortCol] = useState("CreatedDate");
  const [sortDir, setSortDir] = useState(0);
  const [selectedStatusOption, setSelectedOption] = useState(-1);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(Number(event.target.value));
  };

  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [notifyImportWorkOrder, setNotifyImportWorkOrder] =
    useRecoilState<boolean>(importWorkOrderNotificationState);
  const [notificationMessageType] = useRecoilState<number>(
    importWorkOrderNotificationTypeState
  );
  const [errorMessage] = useRecoilState<string>(importWorkOrderErrorMessageState);
  React.useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  React.useEffect(() => {
    const body = document.body;
    if (openImportModal) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }, [openImportModal]);
  
  React.useEffect(() => {
    // Define an async function inside useEffect
    const fetchLookups = async () => {
      try {
        const workOrderLookups = getDataFromSessionStorage(ForObjectType.WORKORDER);

        if (!workOrderLookups) {
          await loadCommonLookups(); // Await the asynchronous function
          // Re-fetch the lookups after loading common lookups
          const updatedLookups = getDataFromSessionStorage(ForObjectType.WORKORDER);
          if (updatedLookups) {
            const options = updatedLookups.find((x: CommonLookup) => x.name === "status")?.elements || [];
            setStatusOptions([{ id: -1, value: "All" }, ...options]);
          }
        } else {
          const options = workOrderLookups.find((x: CommonLookup) => x.name === "status")?.elements || [];
          setStatusOptions([{ id: -1, value: "All" }, ...options]);
        }
      } catch (error) {
        console.error("Error fetching work order lookups:", error);
      }
    };

    fetchLookups(); // Call the async function

  }, []);

  React.useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const status = props.isHomePage
          ? statusOptions.find((item: any) => item.value === "Active")?.id
          : (statusOptions.length > 0
            ? (statusOptions.find((item: any) => item.id === selectedStatusOption)?.value === "All"
              ? undefined
              : selectedStatusOption)
            : undefined);

        const response = await WorkOrderService.getAll(
          searchValue,
          page,
          perPage,
          sortCol,
          sortDir === 1 ? "ASC" : "DESC",
          startDate ? startDate : moment('1900-01-01').toDate(),
          endDate ? endDate : moment('2900-01-01').toDate(),
          status
        );
        if (response) {
          setTotalCount(response?.data?.pageInfo?.total ?? 0);
          setData(response.data);
          setIsLoading(false);
        }

      } catch (error) {
        console.error("Error fetching workorders:", error);
        setIsLoading(false);
      }
    };

    getData();
  }, [searchValue, page, perPage, sortCol, sortDir, notifyImportWorkOrder, startDate, endDate, selectedStatusOption, statusOptions,props.isHomePage]);


  function search(searchParamValue: string) {
    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setPage(1);
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  function changePerPage(name: any, value: any) {
    if (value) {
      setPerPage(value);
      setPage(page);
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    if (newPage) {
      setPerPage(perPage);
      setPage(newPage);
    }
  }

  const clearSearch = () => {
    setSearchValue("");
  };

  const handleStartDateChange = (value: any) => {
    setStartDate(value.target.value); // Update startDate state with the new value
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value.target.value); // Update startDate state with the new value
  };

  function refreshWorkOrderList() {
    setNotifyImportWorkOrder(false);
  }

  return (
    <>
      <PageLoader visible={loading} />
      <div className={`content-padding ${props.isHomePage ? 'home-page-content-padding' : ''}`}>
        <div className="left width100">
          <div className="left width-auto">
            {!props.isHomePage && (<><GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => window.location.pathname = '/home'}
            >
              Home
            </GoAButton>
              <GoAButton type="tertiary" onClick={() => navigate('')}>
                Work orders
              </GoAButton></>)}
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title={props.isHomePage ? '' : 'Work orders'} />
          </div>
          <div className="right width-auto">
            {!props.isHomePage && canCreateWorkOrder && (<GoAButtonGroup alignment="end">
              <GoAButton
                type="secondary"
                onClick={() => { setOpenImportModal(true); console.log(openImportModal); }}
              >
                Import work order
              </GoAButton>
              <GoAButton
                type="primary"
                trailingIcon="add"
                onClick={() => navigate(`/createWorkOrder`)}
              >
                Create work order
              </GoAButton>
            </GoAButtonGroup>)}
          </div>
        </div>

        {!props.isHomePage && (<div className="command-bar">
          <div className="left width-auto header-button form-row">
            <div className="item-space">
              <GoAFormItem label="From">
                <CustomFmF icon={startDate ? 'close' : 'calendar'} format="yyyy-MM-dd" value={startDate} onChange={(value: Date | null) => handleStartDateChange(value)} onBlur={(val: any) => { }} />
              </GoAFormItem>
            </div>
            <div className="item-space">
              <GoAFormItem label="To">
                <CustomFmF icon={endDate ? 'close' : 'calendar'} format="yyyy-MM-dd" value={endDate} onChange={(value: Date | null) => handleEndDateChange(value)} onBlur={(val: any) => { }} />
              </GoAFormItem>
            </div>
            <div >
              <GoAFormItem label="Status" >
                <Select
                  className="width100"
                  labelId="dropdown-label"
                  value={selectedStatusOption.toString()}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {statusOptions?.map((option: { id: Number, value: string }) => (
                    <MenuItem key={option.id.toString()} value={option.id.toString()}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </GoAFormItem>
            </div>
          </div>
          <div className="right width50 input-search-workorders">
            <SearchInput label="Search" search={search} clearSearch={clearSearch} placeholder="Search by Transaction #,  Unit #,  Item description,  Location" />
          </div>
        </div>)}
        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="workOrderNo">Transaction #</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="imiWorkOrderId">Old IMIS ID</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="unitNo">Unit #</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="itemName">Item name</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="description">Problem</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="status">Status</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="locationName">Location</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="dateIn">Date in</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="dateOut">Date out</GoATableSortHeader>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.workOrders.map((record: WorkOrder, index: any) => (
                <tr key={record.id}>

                  <td><GoAButton type="tertiary" onClick={() => navigate(`/workOrderView/${record.id}/${props.isHomePage}`)}> {record.workOrderNo}</GoAButton></td>
                  <td>{record.imiWorkOrderId}</td>
                  <td>{record.unitNo}</td>
                  <td>{record.itemName}</td>
                  <td>{record.description}</td>
                  <td>{statusOptions.find((s: any) => s.id === record.status)?.value}</td>
                  <td>{record.locationName}</td>
                  <td>{moment(record.dateIn).format("yyyy-MM-DD")}</td>
                  <td>{record.dateOut ? moment(record.dateOut).format("yyyy-MM-DD") : ""}</td>
                </tr>
              ))}
            </tbody>
          </GoATable>
          <div className={workOrderTotalCount > 0 ? "visible pagination pagination-div" : "not-visible pagination"}>
            <GoABlock alignment="center">
              <GoABlock mb="m" alignment="center" gap="m">
                <span style={{ whiteSpace: "nowrap" }}>Show</span>
                <div className="dropdown-list">
                  <GoADropdown name="selPerPage" onChange={changePerPage} value="25" width="8ch">
                    <GoADropdownItem value="25"></GoADropdownItem>
                    <GoADropdownItem value="50"></GoADropdownItem>
                    <GoADropdownItem value="100"></GoADropdownItem>
                  </GoADropdown>
                </div>
                <span style={{ whiteSpace: "nowrap" }}>of {workOrderTotalCount} items</span>
              </GoABlock>
              <GoASpacer hSpacing="fill" />
              <GoAPagination
                itemCount={workOrderTotalCount || 25}
                perPageCount={perPage}
                pageNumber={page}
                onChange={changePage}
              />
            </GoABlock>
          </div>
        </div>

        <GoAModal
          heading={
            notificationMessageType === 0
              ? `Error importing workorders: ${errorMessage}`
              : "Workorders imported successfully."
          }
          open={notifyImportWorkOrder}
          onClose={refreshWorkOrderList}
          calloutVariant={
            notificationMessageType === 0 ? "emergency" : "success"
          }
          actions={
            <GoAButtonGroup alignment="end" mt="l">
              <GoAButton type="primary" onClick={refreshWorkOrderList}>
                Ok
              </GoAButton>
            </GoAButtonGroup>
          }
        ></GoAModal>
      </div>
      <div>
        <WorkOrderImportModal />
      </div>
    </>
  );
};

export default WorkOrderList;
