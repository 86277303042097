import { atom } from "recoil";
import { SelectOptions } from "../model/Base";
import { WorkOrder, WorkOrderResponse } from "../model/WorkOrder";
import { WorkOrderPdf } from "../model/WorkOrderPdf";
import { ItemLocation } from "../model/Location";
 
export const workOrderState = atom<WorkOrder>({
	key: "workOrderState",
	default: new WorkOrder()
});

export const workOrderPdfState = atom<WorkOrderPdf>({
	key: "workOrderPdfState",
	default: new WorkOrderPdf()
});

export const imiWorkOrdersState = atom<WorkOrderResponse>({
	key: "imiWorkOrdersState",
	default: undefined
});

export const locationState = atom<SelectOptions[]>({
	key: "locationState",
	default : undefined
});

export const partsLocationState = atom<SelectOptions[]>({
	key: "partsLocationState",
	default : undefined
});

export const partsLocationSelectedState = atom<SelectOptions>({
	key: "partsLocationSelectedState",
	default : undefined
});

export const partItemsFilteredState = atom<any[]>({
	key: "partItemsFilteredState",
	default : undefined
});

export const partItemsUnusedState = atom<any[]>({
	key: "partItemsUnusedState",
	default : undefined
});

export const labourItemsUnusedState = atom<any[]>({
	key: "labourItemsUnusedState",
	default : undefined
});

export const labourItemsFilteredState = atom<any[]>({
	key: "labourItemsFilteredState",
	default : undefined
});

export const importWorkOrderModalState = atom<boolean>({
  key : "importWorkOrderModalState",
  default : false
});

export const importWorkOrderNotificationState = atom<boolean>({
	key : "importWorkOrderNotificationState",
	default : false
});

export const importWorkOrderNotificationTypeState = atom<number>({
	key : "importWorkOrderNotificationTypeState",
	default : 1
});

export const importWorkOrderErrorMessageState = atom<string>({
	key : "importWorkOrderErrorMessageState",
	default : ""
})

export const workOrderListSearchState = atom<string>({
  key : "workOrderListSearchState",
  default : "parts"
});

export const workOrderValidationState =  atom<Record<string, string>>({
	key: "workOrderValidationState",
	default : {}
}); 

export const workOrderPartsLocationItemsState = atom<ItemLocation[]>({
	key: "workOrderPartsLocationItemsState",
	default: undefined
});

export const altPartNumbersState = atom<ItemLocation[]>({
	key: "altPartNumbersState",
	default: undefined
});


